import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimInput]'
})
export class TrimInputDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    const trimmedValue = value.startsWith(' ') ? value.trimStart() : value;
    this.ngControl?.control?.setValue(trimmedValue);
  }
}