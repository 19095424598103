import { ComponentFactoryResolver, ComponentRef, ViewContainerRef, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { PasswordComponent } from 'src/app/beta/component/password/password.component';
import { SpinnerService } from 'src/app/service/shared/spinner.service';

export class PasswordAuthenticatorService implements com.ts.mobile.sdk.UIAuthenticatorSession<com.ts.mobile.sdk.PasswordInput> {

  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  passwordComponentRef: ComponentRef<PasswordComponent>;
  clientContext: any;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  isLocked = false;

  passwordRef: ComponentRef<PasswordComponent>;
  private loginComp: any;

  currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  username: string;

  constructor(title: string, username: string, private route: Router, private spinnerService: SpinnerService) {
    this.username = username;
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
  }

  startSession(description: com.ts.mobile.sdk.AuthenticatorDescription, mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
               actionContext: com.ts.mobile.sdk.PolicyAction | null, clientContext: any | null): void {
    this.clientContext = clientContext;
    this.mode = mode;

    this.viewContainerRef = clientContext.viewContainerRef;
    this.loginComp = clientContext.loginComp;

    this.spinnerService.clearText();

    // remove the login screen
    this.loginComp.setComponentDisplay(false);
    this.renderPasswordComponent();
    this.isLocked = false;

  }

  renderPasswordComponent() {
    // clear any previous components added via componentFactory
    this.viewContainerRef.clear();
    const componentFactory = this.resolver.resolveComponentFactory(PasswordComponent);
    this.passwordComponentRef = this.viewContainerRef.createComponent(componentFactory);

    this.passwordComponentRef.instance.onSubmitPassword = this.onSubmitPassword;
    this.passwordComponentRef.instance.onCancel = this.onCancel;
  }

  public onSubmitPassword = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
    if (this.isLocked) {
     this.passwordComponentRef.instance.showPasswordLockedErrorMessage();
    }
    this.spinnerService.setText('Verifying login information...');
    this.currentHandler(response);
  }

  public onCancel = (appName: string) => {
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.AbortAuthentication);
    this.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest));
    this.route.navigateByUrl('/', { replaceUrl: true }).then(() =>
      this.route.navigate([`cancel/${appName}`]));
  }

  public promiseInput = (): Promise<com.ts.mobile.sdk.InputOrControlResponse<any>> => {
    return new Promise((resolve, reject) => {
      this.currentHandler = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
        resolve(response);
      };
    });
  }

  promiseRecoveryForError( error, validRecoveries, defaultRecovery): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    if (this.getNested(error, '_data', 'additional_data', 'locked') == true && this.getNested(error, '_data', 'additional_data', 'type') == 'user-ldap') {
      this.isLocked = true;
    }
    return new Promise((resolve, reject) => {
      if (defaultRecovery === com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator) {
        this.passwordComponentRef.instance.showErrorMessage();
        resolve(defaultRecovery);
      } else {
        resolve(defaultRecovery);
      }
    });
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  promiseCancelAction(validOptions, session, clientContext) {
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest);
    return Promise.resolve(response);
  }

  changeSessionModeToRegistrationAfterExpiration(){
  }

  endSession(){
  }

}
