import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private subject = new Subject<any>();

  constructor() {
  }

  hide(){
    this.subject.next({ text: undefined });
  }

  success(message: string){
    this.subject.next({ type: 'success', text: message });
  }

  showMessage(message: string){
    this.subject.next({ type: 'token no longer needed for accessOPTIMA login', text: message });
  }

  error(message: string){
    this.subject.next({ type: 'error', text: message });
  }

  info(message: string){
    this.subject.next({ type: 'info', text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
