import { XmSdk } from 'src/transmit2/xm/js-es6/xmsdk-es6.js';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { Injectable } from '@angular/core';
import { PasswordAuthenticatorService } from './password-authenticator.service';
import { Router } from '@angular/router';
import { OTPAuthenticatorService } from './otp-authenticator-service';
import ForgotPasswordFormSession from './forgotpasswordformsession';
import TemporaryPasswordFormSession from './temporarypasswordformsession';
import { AlertService} from 'src/app/service/shared/alert.service';
import { ProcessjsonService } from 'src/app/service/beta/tli-nootp-processjson.service';

@Injectable()
export class TransmitUiHandler {

  transmitSDK = XmSdk();

  constructor(private spinnerService: SpinnerService,
              private alertService: AlertService,
              private route: Router, private processjsonService: ProcessjsonService) {
   // super(spinnerService, route);
  }

  createPasswordAuthSession(title, username) {
    return new PasswordAuthenticatorService(title, username, this.route, this.spinnerService);
  }

  createOtpAuthSession(title, username, possibleTargets, autoExecedTarget) {
    return new OTPAuthenticatorService(title, username, possibleTargets, autoExecedTarget, this.spinnerService,
      this.alertService, this.route);
  }

  createFormSession(formId: string, payload: object): com.ts.mobile.sdk.UIFormSession {
    switch (formId) {
      case 'pwdResetForm':
        return new ForgotPasswordFormSession(formId, payload, this.spinnerService, this.alertService, this.route);
      case 'pwdChangeForm':
        return new TemporaryPasswordFormSession(formId, payload, this.spinnerService, this.route);
      default:
        return null;
    }
  }

  processJsonData(jsonData, actionContext, clientContext) {
    return this.processjsonService.customProcessJsonData(jsonData, actionContext, clientContext);
  }

  startActivityIndicator(actionContext, clientContext) {
    this.spinnerService.showSpinner();
  }

  endActivityIndicator(actionContext, clientContext) {
    this.spinnerService.hideSpinner();
  }

}
