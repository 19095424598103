import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { APP_BASE_HREF } from "@angular/common";
import { PageNotFoundComponent } from './page-not-found.component';
import { TokenComponent } from './token/token.component';
import { AuthGuard } from 'src/app/universal/auth.guard';
import { ButlerComponent } from './butler/component/butler/butler.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { LogoutRedirectComponent } from './logout-redirect/logout-redirect.component';

export const routes: Routes = [
  { path: '', redirectTo: "uportal/login", pathMatch: "full" },
  { path: 'beta', loadChildren: () => import(`./beta/beta.module`).then(module => module.BetaModule) },
  { path: 'token', component: TokenComponent },
  { path: 'logout-redirect', component: LogoutRedirectComponent },
  {
    path: 'uportal', loadChildren: () => import(`./universal/universal.module`)
      .then(module => module.UniversalModule)
  },
  {
    path: 'butler', loadChildren: () => import(`./butler/butler.module`)
      .then(module => module.ButlerModule), canActivate: [AuthGuard]
  },
  {
    path: 'dashboard', loadChildren: () => import(`./dashboard/dashboard.module`)
      .then(module => module.DashboardModule), canActivate: [AuthGuard]
  },
  {
    path: 'credentials', loadChildren: () => import(`./credentials/credentials.module`)
      .then(module => module.CredentialsModule), canActivate: [AuthGuard]
  },
  {
    path: 'profile', loadChildren: () => import(`./profile/profile.module`)
      .then(module => module.ProfileModule), canActivate: [AuthGuard]
  },
  { path: '**', component: EmptyRouteComponent },
  {
    path: 'butler', loadChildren: () => import(`./butler/butler.module`)
      .then(module => module.ButlerModule), canActivate: [AuthGuard]
  },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollOffset: [0, 0],
    scrollPositionRestoration: 'top'
  }
  )],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/sso/"
    }
  ]
})
export class AppRoutingModule { }