import { StepService } from 'src/app/service/shared/step.service';
import { Observable } from 'rxjs';
import { Component, HostListener, Input, OnInit } from '@angular/core';

export interface StepModel {
  stepIndex: number;
  isComplete: boolean;
  stepName: string;
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit  {
  @Input() isAddCredential: boolean
  steps: Observable<StepModel[]>;
  currentStep: Observable<StepModel>;
  isMobile = false;

  constructor(private stepsService: StepService) {
    this.onResize();
   }

  ngOnInit(): void {
    this.steps = this.stepsService.getSteps();
    this.currentStep = this.stepsService.getCurrentStep();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    let width = window.innerWidth;
    if(width <= 991){
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  onStepClick(step: StepModel) {
    this.stepsService.setCurrentStep(step);
  }

}
