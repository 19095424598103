import { ComponentFactoryResolver, ComponentRef, Renderer2, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { AlertService } from 'src/app/service/shared/alert.service';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { ConfirmationAccountLockedComponent } from 'src/app/universal/component/confirmation-account-locked/confirmation-account-locked.component';

class UniversalResetPasswordLoginSession implements com.ts.mobile.sdk.UIFormSession {
  // @ViewChild('forgotPasswordForm', { static: false }) forgotPasswordForm;

  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  clientContext: object;
  actionContext: com.ts.mobile.sdk.PolicyAction;
  uiContext: com.ts.mobile.sdk.UIContext;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  confAccLockCompRef: ComponentRef<ConfirmationAccountLockedComponent>;
  router: Router;
  formId: string;
  payload: string;

  // mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  currentHandler: (response: com.ts.mobile.sdk.FormInput) => void;
  private renderer: Renderer2;
  // data: any = {};
  private loginComp: any;

  constructor(
    formId: string, payload: object,
    private route: Router,
              private spinnerService: SpinnerService,
              private alertService: AlertService,
              
              private sessionService: UniversalSessionService) {
    this.router = route;
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
    // this.payload = payload;
  }

  startSession = (clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void => {

    console.log("Start session")

    this.clientContext = clientContext;
    this.actionContext = actionContext;
    //  this.mode = mode;

    this.viewContainerRef = (clientContext as any).viewContainerRef;
    this.renderer = (clientContext as any).renderer;

    this.loginComp = clientContext['loginComp'];
    this.loginComp.setComponentDisplay(false);
    this.renderConfirmationComponent();
  }

  renderConfirmationComponent() {
    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(ConfirmationAccountLockedComponent);
    this.confAccLockCompRef = this.viewContainerRef.createComponent(componentFactory);

    this.confAccLockCompRef.instance.onLoginClick = this.onLoginReset;
  }

  promiseRecoveryForError(error: any, validRecoveries: any, recovery: any) {
    return new Promise((resolve, reject) => {
      resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail);
    });
  }

  promiseFormInput = (): Promise<com.ts.mobile.sdk.FormInput> => {
    return new Promise((resolve, reject) => {
      this.currentHandler = (response: com.ts.mobile.sdk.FormInput) => {
        resolve(response);
      };
    });
  }

  changeSessionModeToRegistrationAfterExpiration = (): void => {
  }

  endSession = () => {
  }

  private onLoginReset = () => {
    this.spinnerService.setText('');
    const par = {"ok": "ok"}
    const response = com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(par);
    this.currentHandler(response);
  }

  onContinue = (payload) => {
    this.confAccLockCompRef.instance.showTransmitErrorMessage(payload);
  }

  onError = (payload) => {
    console.error('Temporary Form Login onError=', payload);
    this.confAccLockCompRef.instance.showTransmitErrorMessage(payload);

  }
}

export default UniversalResetPasswordLoginSession;
