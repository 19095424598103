import { ComponentFactoryResolver } from '@angular/core';
import { ComponentRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { PasswordRulesComponent } from 'src/app/beta/component/password-rules/password-rules.component';

class ForgotPasswordFormSession implements com.ts.mobile.sdk.UIFormSession {
    // @ViewChild('forgotPasswordForm', { static: false }) forgotPasswordForm;

    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    forgotPasswordFormRef: ComponentRef<PasswordRulesComponent>;
    clientContext: object;
    actionContext: com.ts.mobile.sdk.PolicyAction;
    uiContext: com.ts.mobile.sdk.UIContext;
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
    private renderer: Renderer2;
    private loginComp: any;
    pwRulesRef: ComponentRef<PasswordRulesComponent>;
    router: Router;

    // mode: com.ts.mobile.sdk.AuthenticatorSessionMode;

    formId: string;
    payload: string;
    // data: any = {};

    currentHandler: (response: com.ts.mobile.sdk.FormInput) => void;

    constructor(formId: string, payload: object,
                private spinnerService: SpinnerService,
                private alertService: AlertService,
                private route: Router) {
        this.router = route;
        this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
        this.formId = formId;
        // this.payload = payload;
    }

    startSession = (clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void => {

        this.clientContext = clientContext;
        this.actionContext = actionContext;
        //  this.mode = mode;

        this.viewContainerRef = (clientContext as any).viewContainerRef;
        this.renderer = (clientContext as any).renderer;

        this.loginComp = clientContext['loginComp'];
        this.loginComp.setComponentDisplay(false);
        this.renderPasswordRulesComponent();
    }

    renderPasswordRulesComponent() {
        this.viewContainerRef.clear();

        const componentFactory = this.resolver.resolveComponentFactory(PasswordRulesComponent);
        this.pwRulesRef = this.viewContainerRef.createComponent(componentFactory);

        this.pwRulesRef.instance.onSubmitPassword = this.onResetPwd;
        this.pwRulesRef.instance.onCancel = this.onCancel;
    }

    onCancel(appName: string) {
        if (this.currentHandler) {
            const controlRequest = com.ts.mobile.sdk.FormInput.createFormCancellationRequest();
            this.currentHandler(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(controlRequest));
        }
        this.router.navigate([`cancel/${appName}`]);
    }

    promiseFormInput = (): Promise<com.ts.mobile.sdk.FormInput> => {
        return new Promise((resolve, reject) => {
            this.currentHandler = (response: com.ts.mobile.sdk.FormInput) => {
                resolve(response);
            };
        });
    }

    changeSessionModeToRegistrationAfterExpiration = (): void => {
    }

    onContinue = (payload) => {
        console.log('ForgotPasswordForm onContinue=');
        if (payload && payload.code) {
            this.pwRulesRef.instance.showTransmitErrorMessage(payload.code);
        }
    }

    onError = (payload) => {
        console.log('ForgotPasswordForm onError=');
        if (payload && payload.code) {
            this.pwRulesRef.instance.showTransmitErrorMessage(payload.code);
        }
    }

    endSession = () => {
    }

    private onResetPwd = (newPwd: string) => {
        this.spinnerService.setText('');
        const params = {
            password_input: newPwd,
            password_confirm_input: newPwd
        };
        const response = com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(params);
        this.currentHandler(response);
    }
}

export default ForgotPasswordFormSession;
