import { NgModule, Optional, SkipSelf } from '@angular/core';
import { WindowComponent} from './component/window/window.component';
import { PortalModule } from '@angular/cdk/portal';
import { ApplicationService } from './service/application.service';
import { TabService } from './service/tab.service';
import { UniversalAnalyticsService } from './service/universalAnalytics.service';
import { UniversalSessionService } from './service/universalSession.service';

import { throwIfAlreadyLoaded } from './module-import-guard';


@NgModule({
  declarations: [ WindowComponent ],
  imports: [ PortalModule],
  exports: [ WindowComponent ],
  providers: []
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule?: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot() {
    return {
      ngModule: CoreModule,
      providers: [
        ApplicationService,
        TabService,
        UniversalAnalyticsService,
        UniversalSessionService
      ]
    };
  }
}

