import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAnalyticButton]'
})
export class AnalyticButtonDirective implements OnInit{

  @Input() prefix: string;
  @Input() appAnalyticButton = '';
  constructor(private el: ElementRef, private renderer2: Renderer2) {
    this.renderer2.addClass(this.el.nativeElement, 'cbensightenevent');
    this.renderer2.setAttribute(this.el.nativeElement, 'cbdata-type', 'button');
  }

  ngOnInit() {
    this.renderer2.setAttribute(this.el.nativeElement, 'cbdata-reason', `${ this.prefix }-${this.appAnalyticButton}`);
  }
}
