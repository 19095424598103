import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/Util/custom-validators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { errorCodes } from 'src/error-codes.config';
import { AlertService } from 'src/app/service/shared/alert.service';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';
import { AnalyticsService } from 'src/app/service/beta/analytics.service';
import { assetURL, environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';

@Component({
  selector: 'app-change-temp-password',
  templateUrl: './change-temp-password.component.html'
})
export class ChangeTempPasswordComponent implements OnInit, AfterViewInit {
  resetPasscodeForm: UntypedFormGroup;
  submitted = false;
  appName = environment.application1;
  loading = false;
  application: string;
  companyID: string;
  userId: string;
  // tmxSessionId: string;
  pageName: string;
  showTransmitError = false;
  transmitError: string;
  passwordsDoNotMatch = false;
  passwordPolicyNotMet = false;
  private analyticsSessionId = '';
  @ViewChild('passtext') passwordField: ElementRef;
  public onSubmitPassword: (oldPassword: string, newPassword: string) => void;
  public onCancelTmpPw: (appName: string) => void;

  get assetURL() {
    return assetURL;
  }

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private router: Router,
              private alertService: AlertService,
              private utils: SsoUtils,
              private analyticsService: AnalyticsService,
              private sessionService: SessionManagementService,
              private transmitUtils: TransmitUtils) {
    this.resetPasscodeForm = this.createForm();
  }

  ngOnInit(): void {
    const data = this.sessionService.getSessionData();
    this.appName = data.appName;
    this.analyticsSessionId = data.analyticsSessionId;
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.passwordField.nativeElement.focus();
    }
    const analyticsJson = {
      cbpgType: 'temppass',
      cbaction: 'start',
      cbpgFunction: 'resetpassword',
      cbpgSubFunction: '',
      cbpgName: 'start'
    };
    this.analyticsService.updateAnalytics(analyticsJson);
    if (this.appName === 'accessoptima') {
      window['hover']('set_password');
    }
  }

  createForm(): UntypedFormGroup {
    this.resetPasscodeForm = this.fb.group({
      temporary: ['', [Validators.required]],
      passtext: ['', [Validators.required, CustomValidators.patternValidator(/\d/, {hasNumber: true}),
        CustomValidators.patternValidator(/[a-z].*/i, {hasOneAlpha: true}),
        CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
        CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {hasSpecialCharacters: true}),
        Validators.minLength(8), Validators.maxLength(24)]
      ],
      confirmPassword: ['', [Validators.required]]
    });

    return this.resetPasscodeForm;
  }

  get f() {
    return this.resetPasscodeForm.controls;
  }

  onSubmit(formData) {
    this.submitted = true;
    this.hideTransmitMessage();
    this.alertService.hide();
    const controls = this.resetPasscodeForm.controls;
    if (this.resetPasscodeForm.invalid) {
      if (controls.passtext.value !== '' && !CustomValidators.strongPassword(controls.passtext).strong) {
        this.passwordPolicyNotMet = true;
      }
      return;
    } else if (formData.passtext !== formData.confirmPassword) {
      this.passwordsDoNotMatch = true;
      return;
    }
    this.onSubmitPassword(this.f.temporary.value, this.f.passtext.value);
  }

  showTransmitMessage() {
    this.showTransmitError = true;
  }

  hideTransmitMessage() {
    this.showTransmitError = false;
  }

  hidePasswordPolicyErrors() {
    this.passwordPolicyNotMet = false;
    this.passwordsDoNotMatch = false;
  }

  back(evt) {
    this.sessionService.cancelSessions();
    evt.preventDefault();
    this.onCancelTmpPw(this.appName);
  }

  showTransmitErrorMessage(error: any) {
    if (error && (error.code == 'SSO3212')) {
      this.alertService.error(this.transmitUtils.getPasswordLockedErrorMessage());
    } else if (error && (error.code == 'SSO2012')) {
      this.showTransmitMessage();
    } else if (error && (error.code == 'SSO1002')) {
      const transmitErrorMessage = errorCodes[error.code];
      this.alertService.error(transmitErrorMessage);
    } else {
      this.sessionService.setErrorDescription({ description: 'Transmit is unavailable', code: error.code });
      this.router.navigate(['unavailable']);
    }
  }

}
