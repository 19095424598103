import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabService implements OnDestroy {
  private appTabMap = new Map();   // open tab reference and name
  private appTabComponentMap = new Map();   // open tab reference and name

  constructor() { }

  init(){
    this.appTabMap = new Map();
    this.appTabComponentMap = new Map();
  }

  isAppTabOpen(appName): boolean {
    if (this.appTabMap.has(appName)){
      const appTab = this.appTabMap.get(appName);
      if (appTab && !appTab.closed){
        return true;
      } else {
        this.closeAppTab(appName);
      }
    }
    return false;
  }

  /**
   * Save the reference to the browser tab where an application is open
   * One tab per application if there is already a tab open nothing is done.
   * @param tabRef  reference name to the window tab
   * @param appName  application name will be re-used
   */
  saveAppTab(appName, tabRef) {
    const tab = this.isAppTabOpen(appName);
    if (!tab) {
      // save app tab ref as there is none currently open
      this.appTabMap.set(appName, tabRef);
    }
  }

  /**
   * Save the reference to the WindowsComponent where an application is open
   * One tab per application if there is already a tab open nothing is done.
   * @param tabRef  reference name to the window tab
   * @param componentRef  windowComonent Reference
   */
  saveAppComponentTab(appName, componentRef) {
    this.appTabComponentMap.set(appName, componentRef);
  }

  closeAppTab(appName) {
    this.appTabMap.delete(appName);
  }

  /**
   * destroys all the WindowComponents
   * @param appName application name
   */
  destroyAllWindows() {
    this.appTabComponentMap = new Map();
  }

  ngOnDestroy() {
    this.destroyAllWindows();
  }

}
