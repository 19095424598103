import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StepModel } from '../../shared/component/stepper/stepper.component';

export const STEPS_UL = [
  { stepIndex: 1, isComplete: false, stepName: 'Verify Your Identity' },
  { stepIndex: 2, isComplete: false, stepName: 'Create Your Universal Login' },
  { stepIndex: 3, isComplete: false, stepName: 'Confirmation' }
];

export const STEPS_AC: StepModel[] = [
  { stepIndex: 1, isComplete: false, stepName: 'Select Your Application' },
  { stepIndex: 2, isComplete: false, stepName: 'Verify Your Identity' },
  { stepIndex: 3, isComplete: false, stepName: 'Confirmation' }
];


@Injectable({
  providedIn: 'root'
})
export class StepService {

  steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>([]);
  currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);
  applicationName = '';
  universalId = '';
  onBackButton = false;
  oudAppName = '';
  preProdValue = '';

  constructor() {
    this.currentStep$.next({stepIndex: 0, isComplete: false, stepName: 'Initial Step'});
  }

  setBaseSteps(steps: StepModel[]): void {
    this.steps$.next(steps);
  }

  setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }

  getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;
    if (index < this.steps$.value.length) {
      this.currentStep$.next(this.steps$.value[index]);
      this.steps$.value[index-1].isComplete = true;
    }
  }

  setFirstStep() {
    this.currentStep$.next({stepIndex: 1, isComplete: false, stepName: 'Select Your Application'});
  }

  moveToPreviousStep() {
    this.currentStep$.next({stepIndex: 1, isComplete: false, stepName: 'Verify Your Identity'});
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }

}
