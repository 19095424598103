import { assetURL, environment } from '../../../../environments/environment';
import { AfterViewInit, Component, OnDestroy, OnInit, ComponentFactoryResolver, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../../../service/dashboard/dashboard.service';
import { UniversalSessionService } from '../../../core/service/universalSession.service';
import { UniversalAnalyticsService } from '../../../core/service/universalAnalytics.service';
import { User } from '../../../models/user';
import { SpinnerService } from '../../../service/shared/spinner.service';
import jwt_decode from 'jwt-decode';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';
import { AlertService } from '../../../service/shared/alert.service';
import { TransmitUniversalService } from '../../../service/universal/transmit-universal.service';
import { Subscription } from 'rxjs';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';

@Component({
  selector: 'app-butler',
  templateUrl: './butler.component.html',
  styleUrls: ['./butler.component.scss']
})
export class ButlerComponent implements OnInit, AfterViewInit, OnDestroy {

  public data$: Subscription;
  public credentialsData$: Subscription;
  public userIdMapsData$: Subscription;
  public collectionSize: number;
  public users: any;
  public header: any = environment.header;
  public user: User;
  public fullName: string;
  public isMobileApp = false;
  public id = 'dashboard-header';
  public title = 'Please select the company for the request.';
  public userCompanyNames = new Set();
  public userCompanyNamesMap = new Map();

  public sfTargetPath: string;
  private pageName = 'butler';
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private previousPageName = '';
  private isAuthenticated = 'n';
  private universalId: string;

  @ViewChild('viewContainerRef', { static: false, read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  get assetURL() {
    return assetURL;
  }

  constructor(private https: HttpClient,
    private resolver: ComponentFactoryResolver,
    private dashboardService: DashboardService,
    private sessionService: UniversalSessionService,
    private utils: SsoUtils,
    private analyticsService: UniversalAnalyticsService,
    private router: Router,
    private transmitUtils: UniversalTransmitUtils,
    private alertService: AlertService,
    private transmitService: TransmitUniversalService,
    private spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    this.credentialsData$ = this.dashboardService.credentialsData$.subscribe(
      data$ => {
        this.user = data$.userInfo;
        if (!!this.user && this.user.userDisplayName) {
          this.fullName = this.user.userDisplayName;
          this.analyticsUserId = this.user.userProfileId;
          this.universalId = this.user.universalId;
        }
      }
    );
    const data = this.sessionService.getSessionData(this.pageName);
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    const mobileApp = data.mobileApp;
    console.warn('>>> mobileApp=', mobileApp);
    this.isAuthenticated = 'y';
    this.previousPageName = this.sessionService.getPreviousPageName();
    this.isMobileApp = this.sessionService.isMobileApp();
    if (this.isMobileApp) {
      this.id = 'mobile-dashboard-header';
    }
    const userIds = this.sessionService.getUserIdMaps();
    userIds.userIdMaps?.forEach(x => {
      this.spinnerService.setText('Please wait...');
      if ((x.companyName !== null) && (x.prsistentId !== null) && (x.prsistentId !== '') && (x.companyName !== '')) {
        if (this.userCompanyNames.add(x.companyName)) {
          this.userCompanyNamesMap.set(x.companyName, x.prsistentId);
        }
      }
    });
    this.dashboardService.getCredentials();
  }

  ngAfterViewInit(): void {
    this.transmitService.initializeTransmit(environment.transmitUrl, undefined, this,
      environment.univ_portal_transmitJourney);
    this.resetAnalytics();
  }

  resetAnalytics() {
    let analyticsJson = {};
    this.analyticsService.setAccType('');
    console.warn('>> previousPageName =', this.previousPageName);
    if (this.previousPageName === 'login' || this.previousPageName === '') {
      analyticsJson = {
        cbpgType: 'login',
        cbaction: 'complete',
        cbpgFunction: 'dashboard',
        cbpgSubFunction: '',
        cbpgName: 'complete',
        cbCisKey: this.analyticsUserId,
        cbsessionId: this.analyticsSessionId,
        cbauthenticated: this.isAuthenticated
      };
    } else {
      // this is when the dashboard is shown again after it returns to the page from a "Back" button
      analyticsJson = {
        cbpgType: 'dashboard',
        cbaction: 'view',
        cbpgFunction: 'summary',
        cbpgSubFunction: '',
        cbpgName: 'view',
        cbCisKey: this.analyticsUserId,
        cbsessionId: this.analyticsSessionId,
        cbauthenticated: 'y'
      };
    }
    const data = {
      cbotpused: '',
      cbotpoption: ''
    };
    analyticsJson = Object.assign({}, data, analyticsJson);
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.initAuthenticated(this.analyticsSessionId, this.analyticsUserId, '');
    } else {
      this.analyticsService.updateAnalytics(analyticsJson);
    }
  }

  logout() {
    const logoutURL = environment.univ_helpCenter.butlerLogoutUrl;
    this.sessionService.logout();
    this.utils.redirect(logoutURL);
  }

  profile() {
    this.router.navigate(['profile/view']);
  }

  manageCredentials() {
    this.router.navigate(['credentials/manage']);
  }

  getShortName(name) {
    return name.length > 20 ? name.slice(0, 20) + "..." : name;
  }

  login(noOfLinkedCrds, compNames) {
    this.spinnerService.setText('Please wait...');
    setTimeout(() => { this.spinnerService.showSpinner();});
    let companyName = '';
    if (noOfLinkedCrds == 'Many') {
      companyName = compNames['key'];
    } else {
      for (let item of compNames) {
        companyName = item;
      }
    }
    this.sfTargetPath = environment.univ_helpCenter.butlerUrl;
    const persistentId = this.userCompanyNamesMap.get(companyName);
    this.transmitService.authenticate(companyName, undefined, persistentId, this.universalId,
      undefined, undefined, 'sfdc_ping_dropoff', '', '', this.sfTargetPath, '', '')
      .then((res) => {
        const tokenInfo = this.getDecodedAccessToken(res._token);
        if (tokenInfo === undefined || tokenInfo === null || tokenInfo === '') {
          this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
          return;
        }

        const ping_login_url = tokenInfo['ping_sso_url'];
        if (ping_login_url === undefined || ping_login_url === null || ping_login_url === '') {
          this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
          return;
        }
        if (ping_login_url) {
          (window.location.href = ping_login_url) || window.open(ping_login_url, '_blank');
        }        
      })
      .catch((error) => {
        console.error('Ping dropoff error -- Dashboard', error);
      });
    this.spinnerService.hideSpinner();
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  ngOnDestroy() {
  }

}
