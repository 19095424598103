import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAnalyticLink]'
})
export class AnalyticLinkDirective implements OnInit{

  @Input() prefix: string;
  @Input() appAnalyticLink = '';
  constructor(private el: ElementRef, private renderer2: Renderer2) {
    this.renderer2.addClass(this.el.nativeElement, 'cbensightenevent');
    this.renderer2.setAttribute(this.el.nativeElement, 'cbdata-type', 'link');
  }

  ngOnInit() {
    this.renderer2.setAttribute(this.el.nativeElement, 'cbdata-reason', `${ this.prefix }-${this.appAnalyticLink}`);
  }
}
