<div class="parent-container d-sm-flex">
  <div class="container">
    <div class="headSection">
      <span>Change Temporary Password</span>
    </div>
    <form [formGroup]="resetPasscodeForm" [ngClass]="{ 'was-validated': submitted }" (keyup.enter)="onSubmit(resetPasscodeForm.value)"
          (ngSubmit)="onSubmit(resetPasscodeForm.value)" novalidate >
      <div class="form">
        <div class="textExplanation col-10">Thanks for verifying your identity. Now, let's reset your password.
          <span class="split-text">Note: Your Password cannot be your User ID or one of your 12 most recent passwords.</span>
        </div>
        <div class="form-row password last-row" [ngClass]="{ 'has-error': f.submitted  && f.temporary.errors }">
          <div class="col-5">
            <label for="temporary" [ngClass]="(resetPasscodeForm.controls['temporary'].invalid && resetPasscodeForm.controls['temporary'].touched) ? 'text' : ''">Temporary Password</label>
            <div class="input-group reset-password">
              <input id="temporary" formControlName="temporary" type="password" class="form-control passcode-text" appPasswordtoggle
                     autocomplete="off" maxlength="24" #temporary autofocus required (keyup)="hideTransmitMessage()">
              <div class="invalid-feedback" *ngIf="!showTransmitError">
                <div class="error-message">Temporary Password is required</div>
              </div>
            </div>
            <div *ngIf="showTransmitError" >
              <div class="error-message" >
                The Password you entered does not match our records.
                <span>Please check it and try again.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row password" [ngClass]="{ 'has-error': f.submitted  && f.passtext.errors }">
          <div class="col-5">
            <label for="passtext" [ngClass]="(resetPasscodeForm.controls['passtext'].invalid && resetPasscodeForm.controls['passtext'].touched) ? 'text' : ''">Password</label>
            <div class="input-group reset-password">
              <input id="passtext" formControlName="passtext" type="password" class="form-control passcode-text"
                     autocomplete="off" maxlength="24" appPasswordtoggle #passtext required (keyup)="hidePasswordPolicyErrors()">
              <div class="invalid-feedback">
                <div class="error-message">Password is required</div>
              </div>
            </div>
            <div *ngIf="passwordPolicyNotMet">
              <div class="error-message">The Password you have selected doesn't meet the security policy guidelines, please select a new password to continue.</div>
            </div>
          </div>
        </div>

        <div class="form-row rules">
          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('minlength')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('minlength'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must be at least 8 characters</label>

          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('maxlength')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('maxlength'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must not exceed 24 characters</label>

          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasNumber')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasNumber'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must contain at least 1 number</label>

          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasOneAlpha')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasOneAlpha'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must contain at least 1 letter</label>

          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasCapitalCase')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasCapitalCase'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must contain at least 1 uppercase letter</label>
          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasSmallCase')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasSmallCase'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must contain at least 1 lowercase letter</label>
          <label class="col-6 text-rules">
            <img class="sso-alert_blank" *ngIf="resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasSpecialCharacters')" src="{{assetURL}}assets/icons/gray-circle.svg"/>
            <svg class="sso-alert__checkmark" focusable="false">
              <use *ngIf="!(resetPasscodeForm.controls['passtext'].hasError('required') || resetPasscodeForm.controls['passtext'].hasError('hasSpecialCharacters'))" [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
              </use>
            </svg>Must contain at least 1 special character</label>
          <label class="col-6 text-rules special">such as # ^ & _</label>
        </div>

        <div class="form-row last-row" [ngClass]="{ 'has-error': f.submitted  && f.confirmPassword.errors }">
          <div class="col-5">
            <label for="confirmPassword" [ngClass]="(resetPasscodeForm.controls['confirmPassword'].invalid && resetPasscodeForm.controls['confirmPassword'].touched) ? 'text' : ''">Confirm
              Password</label>
            <div class="input-group reset-password">
              <input id="confirmPassword" formControlName="confirmPassword" type="password" class="form-control passcode-text"
                     autocomplete="off" maxlength="24" appPasswordtoggle [ngClass]="(resetPasscodeForm.controls['confirmPassword'].invalid && resetPasscodeForm.controls['confirmPassword'].touched) ? 'is-invalid' : ''" #confirmPassword required (keyup)="hidePasswordPolicyErrors()">
              <div class="invalid-feedback">
                <div class="error-message">Confirm Password is required</div>
              </div>
            </div>
            <div *ngIf="passwordsDoNotMatch">
              <div class="error-message" id="confirm-password">
                Confirm Password does not match Password
              </div>
            </div>
          </div>
        </div>

        <div class="buttonArea resetPasswordButton">
          <button class="btn btn-primary cbensightenevent" name="btnLogin" id="btnLogin" #btnLogin type="submit" tabindex=""
                  cbdata-type="button" cbdata-reason="temppass-submit" [disabled]="loading" value="Submit"
                  aria-label="Submit">Submit</button>
          <button tabindex="3" class="btn btn-secondary cbensightenevent" cbdata-type="button" cbdata-reason="temppass-back"
                  name="btnBack" id="btnBack" (click)="back($event)" value="Back" aria-label="Back">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>

</div>

