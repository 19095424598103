import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionManagementService } from './session-management.service';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { SpinnerService } from '../shared/spinner.service';

declare var clientLib: any;

@Injectable({
  providedIn: 'root'
})
export class ThreatMetrixService {

  private apiUrl = environment.apiUrl;
  private scriptLoaded = false;
  private threatMetrixUrl: string = environment.threatMetrixUrl;
  private threatMetrixProfileDomain: string = environment.threatMetrixProfileDomain;
  private orgId: string = environment.orgId;
  private tmxSessionId: string;
  private application: string;
  private samlFormActionURL: string;
  private samlassertionValue: string;
  private relayState: string;


  constructor(private https: HttpClient,
              private sessionMgmtService: SessionManagementService,
              private spinnerService: SpinnerService) {

  }

  initThreatMetrixService(appName, pageName, tmxSessionId) {
    // this.addScriptTag(pageName, tmxSessionId);
    this.addEnhancedProfilingScriptTag(tmxSessionId, pageName);
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const params = new HttpParams()
      .set('application', appName)
      .set('pageName', pageName);

    return this.https.post<any>(this.apiUrl, params, {headers})
      .pipe(take(1));
  }

  // Do not remove this addScriptTag() metod used for TMX basic profiling
  // Currently commented but might need in future
  /* addScriptTag(pageName, tmxSessionId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.tmxSessionId = tmxSessionId;
      // If multiple Script tag present at the same page remove the old script tag for TMX
      // This occures in TLI flow only.
      if (document.getElementById('threatMetrix') !== null) {
        const elem = document.getElementById('threatMetrix');
        elem.parentElement.removeChild(elem);
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${this.threatMetrixUrl}?org_id=${this.orgId}&session_id=${this.tmxSessionId}`;
      script.id = 'threatMetrix';
      document.head.appendChild(script);
      // For ad-blocker functionality

      const scriptFrTookit = document.createElement('script');
      scriptFrTookit.type = 'text/javascript';
      scriptFrTookit.src = `../threat-metrix/js/fp-clientlib-v3`;
      scriptFrTookit.id = 'threatMetrix-addBlocker';
      document.head.appendChild(scriptFrTookit);
      console.log('check url=',this.threatMetrixProfileDomain);
      // threatmetrix.profile( this.threatMetrixProfileDomain,this.orgId,this.tmxSessionId);
      script.onload = () => {
        this.scriptLoaded = true;
        resolve({loaded: true});
      };
      script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
    });
  } */

  getThmSessionID() {
    return this.tmxSessionId;
  }

  // SAML Posting call
  /* postSamlAsstion(samlFormActionURL, samlPostData) {
    this.spinnerService.showSpinner();

    this.openWindowWithPost(samlFormActionURL, samlPostData);
  }

  openWindowWithPost(url, data) {
    const form = document.createElement('form');
    form.target = '_self';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
    // document.body.removeChild(form);
  } */

  addEnhancedProfilingScriptTag(tmxSessionId, pageName): Promise<any> {
    return new Promise((resolve, reject) => {
      this.tmxSessionId = tmxSessionId;
      if (document.getElementById('threatMetrixEnP') != null) {
        const elem = document.getElementById('threatMetrixEnP');
        elem.parentElement.removeChild(elem);
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'threatMetrixEnP';
      const inlineCode = document.createTextNode(`clientLib.profile("${this.threatMetrixProfileDomain}","${this.orgId}","${this.tmxSessionId}");`);
      script.appendChild(inlineCode);
      document.body.appendChild(script);

      script.onload = () => {
        this.scriptLoaded = true;
        resolve({loaded: true});
      };
      script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
    });
  }

}
