import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// config
import { UniversalApiCallsService } from 'src/app/service/universal/universal-api-calls.service';

export interface Application {
  name: string;
  displayName:	string;
  oudAppName?: string;
  isRegistered?: boolean;
  isTradeMarked?: boolean;
  order?: number;
  homeURL?: string;
  learnMoreURL?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  appLoginJson?: AppLoginJson;
  linkText?: string;
  description?: string;
  src?: string;
}

export interface AppLoginJson {
  transmitJourney?: string;
  samlActionUrl?: string;
  relayState?: string;
  logoutUrl?: string;
  mobileLogoutUrl?: string;
}

export interface SamlJson {
  samlActionUrl?: string;
  relayState?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  // TODO: move application information into a database table
  applications: Application[]  = environment.univ_applications;
  public gateway = environment.univ_treasuryLogin;
  appInfo = new Map();   // used gateway
  apps = new Map();      // used applications and linked credentials
  isCurrentRoute = false;

  // config
  configApplications: Application[];
  configApps: any;
  nonOUDAppsList: any;
  oudAppsList: any;
  shortNameMap: { [displayName: string]: string } = {};

  constructor(private universalApiCallsService: UniversalApiCallsService) {
    this.shortNameMap['ACHieve Access'] = 'Achieve';
    this.shortNameMap['accessOptima'] = 'AccessOptima';
    this.shortNameMap['SF'] = 'SF';
    this.shortNameMap['Citizens File Gateway'] = 'Seeburger';
    this.shortNameMap['Cash Flow Essentials'] = 'CASHFLOW';
  }

  /**
   * Currently init is getting data from the environment variables
   */
  init(){
    if (this.applications !== undefined) {
      this.applications.forEach(data => {
        this.apps.set(data.name, data);
      });

      // config
      this.universalApiCallsService.getAppsData().subscribe(data => {
        this.configApps = data;
        this.nonOUDAppsList = data.filter(app => !app.isOUDApp).map(data => data.applicationName);
        this.oudAppsList = data.filter(app => app.isOUDApp).map(data => data.applicationName); // config oud
        console.log(this.nonOUDAppsList, 'non OUD');
        console.log(this.oudAppsList, 'OUD');
        data.forEach(val => {
          this.apps.set(val.name, val);
          this.shortNameMap[val.displayName] = val.applicationName
        });
      })
    }
  }

  initDashboard() {
    const isSMBUser = sessionStorage.getItem("isSMBUser") === "true";
    if (isSMBUser) {
      console.log(isSMBUser,"Inside initDashboard")
      this.gateway = environment.univ_smbUserLogin;
    }

    if (this.gateway !== undefined ) {
      const apps: Application[] = this.gateway;
      this.setGatewayData(apps);
    }
  }

  getNonOUDAppsList() {
    return this.nonOUDAppsList;
  }

  getOUDAppsList() {
    return this.oudAppsList;
  }

  getConfigAppsData(selectedAppName: any) {
    return this.configApps.find((val: any) => val.name === selectedAppName);
  }

  getShortAppName(selectedAppName: any) {
    return this.shortNameMap[selectedAppName] || '';
  }

  isApp(appName){
    return this.apps.has(appName);
  }

  getHomeUrl(appName): string {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.homeURL;
    }
    return undefined;
  }

  getLearnMoreUrl(appName): string {
    if (this.appInfo.has(appName)) {
      const app: Application = this.appInfo.get(appName);
      return app.learnMoreURL;
    }
    return undefined;
  }

  getGatewayData(): object {
    return this.appInfo;
  }

  setGatewayData(apps: Application[]) {
    console.log("chckSMbUser appService",apps)
    apps.forEach(data => {
      this.appInfo.set(data.name, {
        name: data.name,
        displayName: data.displayName,
        oudAppName: data.oudAppName,
        isRegistered: data.isRegistered,
        order: data.order,
        homeURL: data.homeURL,
        learnMoreURL: data.learnMoreURL,
        isDisabled: data.isDisabled,
        linkText: data.linkText,
        description: data.description,
        imgSrc: data.src
      });
    });
  }

  getTransmitJourney(appName): string {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return app.appLoginJson.transmitJourney;
    }
    return undefined;
  }

  getDisplayName(appName): string {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return app.displayName;
    }
    return undefined;
  }

  getNonOUDApps(appName): boolean {
    this.universalApiCallsService.getAppsData().subscribe(data => {
      data.forEach(val => {
        if(val.name === appName)
        return true;
      })
    })
    return false;
  }

  getOudAppName(appName): string {
      if (this.isApp(appName)) {
        const app = this.apps.get(appName);
        return app.oudAppName;
      }
      return undefined;
    }

  getIsRegistered(appName): boolean {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return app.isRegistered;
    }
    return undefined;
  }

  getIsTradeMarked(appName): boolean {
    if(this.isApp(appName)){
      const app = this.apps.get(appName)
      return app.isTradeMarked;
    }
    return undefined;
  }

  getSamlInfo(appName): object {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return { samlActionUrl: app.appLoginJson.samlActionUrl,
        relayState: app.appLoginJson.relayState };
    }
    return undefined;
  }

  getLogoutInfo(appName): object {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return { logoutUrl: app.appLoginJson.logoutUrl,
        mobileLogoutUrl: app.appLoginJson.mobileLogoutUrl };
    }
    return undefined;
  }

  getAppInfo(appName): object {
    if (this.isApp(appName)) {
      const app = this.apps.get(appName);
      return {
        displayName: app.displayName,
        oudAppName: app.oudAppName,
        isRegistered: app.isRegistered,
        isActive: app.isActive
      };
    }
    return undefined;
  }

  textEllipis(value){
    if (value.length > 15) {
      return value.substring(0, 12) + '...';
    }
    return value;
  }
}
