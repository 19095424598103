<div class="toolbar" id="header" role="banner">
  <div class="container">
    <span *ngFor="let item of header | keyvalue" [ngSwitch]="item.value.type" class="toolbarItem">
      <img *ngSwitchCase="'image'" alt="{{ item.value.alt }}" src="{{ item.value.src }}" style="{{ item.value.style }}"
           width="{{ item.value.width }}"/>
      <a *ngSwitchCase="'link'" href="{{ item.value.href }}" style="{{ item.value.style }}"
         target="_blank">{{ item.value.text }}</a>
      <span *ngSwitchCase="'text'" style="{{ item.value.style }}">{{ item.value.text }}</span>
    </span>
  </div>
</div>

<div id="transmitContainer"></div>
<div class="container" id="appContent">

  <div class="parent-container d-sm-flex">
    <div class="container">
      <div class="headSection">
        <span></span>
      </div>
      <div class="simpleTextExplanation col10">We’re sorry, the page you requested was not found. Please log in with the
        <button (click)="Back($event)" class="btn btn-link" tabindex="1" type="button">Citizens Gateway.</button>
      </div>
    </div>
  </div>

</div>

<footer class="footer">
  <div class="container footer-content">
    <span *ngFor="let item of footer | keyvalue" [ngSwitch]="item.value.type" class="footerItem">
<a *ngSwitchCase="'link'" href="{{ item.value.href }}" style="{{ item.value.style }}"
   target="_blank">{{ item.value.text }}</a>
<img *ngSwitchCase="'image'" alt="{{ item.value.alt }}" src="{{ item.value.src }}" style="{{ item.value.style }}"
     width="{{ item.value.width }}"/>
<span *ngSwitchCase="'text'" style="{{ item.value.style }}">{{ item.value.text }}</span>
</span>
  </div>
  <div class="container" id="copyright">&copy;&nbsp;{{ copyright }}</div>
</footer>
