import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textMask'
})
export class TextMaskPipe implements PipeTransform {

  transform(phrase: string) {

    if (!phrase) {
      return '';
    }
    const toBeMasked = phrase.split('@');
    const part1 = toBeMasked[0];
    const part2 = toBeMasked[1];
    const first = part1.slice(0, 1);
    const last = part1.slice(-1);
    const mask = part1.substring(1, part1.length - 1);
    const final = mask.replace(mask, '...');
    phrase = first + final + last + '@' + part2;
    return phrase;
  }

}

