import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

declare var CBDL: any;
declare function getAnalyticsObjJS(CBDL): any;
declare function getAnalyticsObjJSChange(CBDL): any;
declare function addPageBottom(): any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsDataJson: any;
  cbdl: any;
  initialized = false;
  private cbotpused = '';
  private cbotpoption = '';
  private renderer2: Renderer2;
  private adobeUrl = environment.analyticsScriptSrc;
  private scriptLoaded = false;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  init(tmxSessionId: string, appName: string, customerId: string, analyticsJson?: any) {
    if (!this.initialized) {
      this.addAdobeScriptTag().then(data => {
        if (data.loaded) {
          addPageBottom();
          this.analyticsDataJson = this.getAnalyticsData(tmxSessionId, appName, customerId);
          if (!!analyticsJson) {
            this.analyticsDataJson = Object.assign({}, this.analyticsDataJson, analyticsJson);
          }
          getAnalyticsObjJS(this.analyticsDataJson);
          this.setAnalyticsObject();
          this.initialized = true;
        } else {
          // console.log('Adobe Analytics script did not load.');
        }
      });
    }
  }

  isInitialized(): boolean {
    return this.initialized;
  }

  /**
   *
   * @param sessionId  analyticsSessionId
   * @param customerId  customerId name will be used for MVP
   * @param appName    application name will be used for MVP
   */
  getAnalyticsData(sessionId: string, appName: string, customerId: string) {
    return {
      cbpgType: 'login',     // set the default page name
      cbcountry: 'us',
      cblanguage: 'eng',
      cbwebidentifier: 'servicing-commercial',
      cbdevice: '',
      cberrorId: '',
      cbaction: 'start',
      cbpgFunction: 'login',
      cbpgSubFunction: 'userid',
      cbpgName: 'start',
      cbproduct: 'commercial',
      cbtype: 'commercial',
      cbgeoLocation: '',
      cbbrand: 'cb',
      cbproductType: 'sso',
      cbCisKey: '',
      cberrorDescription: '',
      cbsessionId: sessionId,
      cbworkflow: '',
      cbotpused: this.cbotpused,
      cbotpoption: this.cbotpoption,
      cbregAccType: '',
      cbauthenticated: 'n',
      cbaccType: appName
    };
  }

  setAnalyticsObject() {
    const data = Object.assign({}, this.analyticsDataJson, CBDL.pgInfo);
    this.cbdl = getAnalyticsObjJSChange(data);
  }

  updateAnalytics(updateJson) {
    updateJson.cbotpused = this.cbotpused;
    updateJson.cbotpoption = this.cbotpoption;
    this.cbdl = getAnalyticsObjJSChange(updateJson);
  }

  setOtpUsed(val) {
    this.cbotpused = val;
  }

  setOptOption(val) {
    this.cbotpoption = val;
  }

  /**
   *  dynamically add the Adobe Analytics script tag in the head
   *  using promises so that afterwards we can do something
   */
  addAdobeScriptTag(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.src = this.adobeUrl;

      // IE11 now uses onload like a modern browser
      script.onload = () => {
        this.scriptLoaded = true;
        resolve({ loaded: true });
      };
      script.onerror = (error: any) => resolve({ loaded: false });
      this.renderer2.appendChild(this.document.head, script);
    });
  }

}
