<div class="container" >
  <div class="headSection">
    <span>Help us Verify Your Identity</span>
  </div>
  <form name="form" id="securityForm" [formGroup]="securityForm" [ngClass]="{ 'was-validated': submitted }" (ngSubmit)="onSubmit()" novalidate >
    <div class="form">
      <div [ngClass]="{ 'has-error': f.submitted  && f.errors }">
        <div class="textExplanation col10">For your security, we need to send you a One-Time Passcode (OTP) to verify your identity.
          <span class="split-text">Please select your preferred delivery method from the contact information we have on file.</span>
        </div>
      </div>
      <div *ngIf="contactEmail" class="radiobtn form-row form-check" >
        <div class="radio radio-inline col-12">
          <input class="cbensightenevent" type="radio" #email name="contactType" id="email" formControlName="contactType"
                 cbdata-type="radio" cbdata-reason="verification-radio-email" value="email" >
          <label for="email">Email: {{ contactEmail }} </label>
        </div>
      </div>
      <div *ngIf="contactMobileText" class="form-row form-check form-info">
        <div class="radio radio-inline col-12">
          <input class="cbensightenevent" type="radio" #text name="contactType" id="text" formControlName="contactType"
                 cbdata-type="radio" cbdata-reason="verification-radio-text" value="text">
          <label for="text">Text: {{ contactMobileText }}</label>
          <div class="input-info">Message and data rates may apply.</div>
        </div>
      </div>
      <div *ngIf="contactMobileVoice" class="form-row form-check">
        <div class="radio radio-inline col-12">
          <input class="cbensightenevent" type="radio" #mobileVoice name="contactType" formControlName="contactType" id="mobileVoice"
                 cbdata-type="radio" cbdata-reason="verification-radio-mobilevoice" value="mobilevoice">
          <label for="mobileVoice">Voice: {{ contactMobileVoice }}</label>
        </div>
      </div>
      <div *ngIf="contactLandVoice" class="form-row form-check last-row">
        <div class="radio radio-inline col-12">
          <input class="cbensightenevent" type="radio" #voice name="contactType" id="voice" formControlName="contactType"
                 cbdata-type="radio" cbdata-reason="verification-radio-landVoice" value="landVoice">
          <label for="voice">Voice: {{ contactLandVoice }}</label>
        </div>
      </div>
      <div class="error-message" *ngIf="showError">
        <span>Send OTP code failed. Please try again.</span>
      </div>
      <div class="buttonArea">
        <button class="btn btn-primary cbensightenevent" name="btnContinue" id="btnContinue" #btnContinue type="submit" tabindex="5"
                cbdata-type="button" cbdata-reason="verification-continue" [disabled]="loading" value="Continue" aria-label="Continue">Continue</button>
        <button tabindex="6" class="btn btn-secondary cbensightenevent"  name="btnBack" id="btnBack" (click)="back($event)"
                cbdata-type="button" cbdata-reason="verification-cancel" value="Cancel" aria-label="Cancel">Cancel</button>
      </div>
    </div>
  <div class="need-assistance">
    Need Assistance?
  </div>
    <!--    Desktop devices  -- Does not contain telephone anchors -->
    <div class="contactArea d-none d-lg-block" >If you don’t have access to the email or phone number listed above,
      please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
      at {{ contactMessage.phone1 }} &nbsp;{{ contactMessage.phone2 }} or email us
      at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-nonmobile-email" target="_blank"
            tabindex="7" href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
    </div>
    <!--    Does contain telephone anchors for ios mobile devices -->
    <div class="contactArea d-lg-none" *ngIf="!isAndroid" >If you don’t have access to the email or phone number listed
      above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
      at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-phone1" tabindex="7"
            target="_blank" href="tel:{{ phoneNumber1 }}">{{ contactMessage.phone1 }}</a> &nbsp;<a class="cbensightenevent"
            cbdata-type="link" cbdata-reason="verification-contact-mobile-phone2" tabindex="7" target="_blank"
           href="tel:{{ phoneNumber2 }}">{{ contactMessage.phone2 }}</a> or email us
          at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-email" target="_blank"
          tabindex="8"  href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
    </div>
    <!--   Plain text for Android mobile devices -->
    <div class="contactArea d-lg-none" *ngIf="isAndroid" >If you don’t have access to the email or phone number listed above,
      please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
      at {{ contactMessage.phone1 }} &nbsp;{{ contactMessage.phone2 }} or email us
      at {{ contactMessage.email }}, {{ contactMessage.hours }}.
    </div>
  </form>
</div>
