import { Component, Input } from '@angular/core';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-beta-header',
  templateUrl: './beta-header.component.html',
  styleUrls: ['./beta-header.component.scss']
})
export class BetaHeaderComponent {
  public header: any;
  public isMobile: boolean = false;

  @Input() isDashboard = false;
  // @Input() isMobile = false;

  constructor(private sessionMgmtService: SessionManagementService) {
  }

  ngAfterContentInit(): void {
    // the only mobileHeader is an accessOptima image
    if (this.sessionMgmtService.isMobile()) {
      this.header = environment.mobileHeader;
      this.isMobile = this.sessionMgmtService.isMobile();
    } else {
      this.header = environment.header;
    }
  }

}
