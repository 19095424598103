import { Injector, Injectable } from '@angular/core';

@Injectable()
export class AppInjectorService {

  private static anInjector: Injector;

  static set injector(injector: Injector) {
    this.anInjector = injector;
  }

  static get injector(): Injector {
    return this.anInjector;
  }
}
