<div class="modal" id="spinnerModal" [style.display]="loading ? 'block' : 'none'" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-dialog-centered spinModal" role="document">
      <div class="modal-content spinnerContent">
        <!-- Modal body -->
        <div class="loader"></div>
        <p class="text-content"> {{modalText}} </p>
      </div>
    </div>
  </div>

