import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';
import { errorCodes } from 'src/error-codes.config';
import { UniversalAnalyticsService } from '../../../core/service/universalAnalytics.service';
import { UniversalSessionService } from '../../../core/service/universalSession.service';

@Component({
  selector: 'app-confirmation-account-locked',
  templateUrl: './confirmation-account-locked.component.html',
  styleUrls: ['./confirmation-account-locked.component.scss']
})
export class ConfirmationAccountLockedComponent implements OnInit, AfterViewInit {
 
  loading = false;
  private pageName = 'unlockConfirm';
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';
  public onLoginClick: () => void;

  constructor(
              private analyticsService: UniversalAnalyticsService,
              private sessionService: UniversalSessionService,
              private alertService: AlertService,
              private transmitUtils: TransmitUtils,
              private router: Router) {
                const data = this.sessionService.getSessionData(this.pageName);
                this.pageName = data.pageName;
                this.analyticsSessionId = data.analyticsSessionId;
                this.analyticsUserId = data.analyticsUserId;
                this.isAuthenticated = data.isAuthenticated;
              }

  

  ngOnInit(): void {
    this.sessionService.setFlowComplete(true);
  }

  ngAfterViewInit(): void {
    this.resetAnalytics();
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'resetpass',
      cbaction: 'complete',
      cbpgFunction: 'resetpassword',
      cbpgSubFunction: 'complete',
      cbpgName: 'complete',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };

    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, analyticsJson);
    } else {
      this.analyticsService.setAccType('');
      this.analyticsService.setOtpUsed('');
      this.analyticsService.setOptOption('');
      this.analyticsService.updateAnalytics(analyticsJson);
    }
  }

  onContinue() {
    this.onLoginClick();
  }


  showTransmitErrorMessage(error: any){
    if (error && (error.code == 'SSO3212')) {
      this.alertService.error(this.transmitUtils.getPasswordLockedErrorMessage());
    } else if (error && (error.code == 'SSO1002')) {
      const transmitErrorMessage = errorCodes[error.code];
      this.alertService.error(transmitErrorMessage);
    } else {
      this.sessionService.setErrorDescription({ description: 'Transmit is unavailable', code: error.code });
      this.router.navigate(['unavailable']);
    }
  }


}
