import { Component, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';
import { AnalyticsService } from 'src/app/service/beta/analytics.service';
import { AlertService } from 'src/app/service/shared/alert.service';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';

@Component({
  selector: 'app-first-login',
  templateUrl: './security.component.html'
})
export class SecurityComponent implements AfterViewInit {

  public submitted = false;
  public loading = false;
  public securityForm: UntypedFormGroup;
  resetEnabled: boolean;
  showError = false;
  public hide = true;
  public contactMessage: any = environment.contactMessage;
  public phoneNumber1: string;
  public phoneNumber2: string;
  public isAndroid = false;

  public appName: string;
  private companyId: string;
  private userId: string;
  private pageName: string;
  private analyticsSessionId = '';

  public contactEmail;
  public contactMobileVoice;
  public contactMobileText;
  public contactLandVoice;

  public otpTargets: any[];
  public otpTargetsMap: Map<string, com.ts.mobile.sdk.AuthenticatorTarget> = new Map();
  onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget) => void;
  public onCancel: (appName: string) => void;

  constructor(private route: ActivatedRoute, private router: Router,
              private readonly formBuilder: UntypedFormBuilder,
              private utils: SsoUtils,
              private sessionMgmtService: SessionManagementService,
              private analyticsService: AnalyticsService,
              private alertService: AlertService,
              private transmitUtils: TransmitUtils) {
    this.createForm();
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngAfterViewInit(): void {
    const data = this.sessionMgmtService.getSessionData();
    this.appName = data.appName;
    this.companyId = data.companyId;
    this.userId = data.userId;
    this.pageName = 'choosedeliverymethod';  // analytics page name
    this.analyticsSessionId = data.analyticsSessionId;

    const analyticsJson = { cbpgType: 'otp',
                            cbaction: 'view',
                            cbpgFunction: 'verification',
                            cbpgSubFunction: this.pageName,
                            cbpgName: 'view' };
    this.analyticsService.updateAnalytics(analyticsJson);
    if (this.appName === 'accessoptima') {
      window['hover']('otp_delivery');
    }
  }

  /**
   * Create the form and set the default value to 'email'
   */
  createForm() {
    this.securityForm = this.formBuilder.group({
      contactType: new UntypedFormControl('email', Validators.required)
    });
  }

  get f() {
    return this.securityForm.controls;
  }

  public setTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>) => {
    this.otpTargets = targets;
    this.otpTargets.forEach((target) => {
      if (target._channel === 1) {
        this.otpTargetsMap.set('text', target);
        this.contactMobileText = target._description;
      }
      if (target._channel === 4 && target._targetIdentifier == "1") {
        this.otpTargetsMap.set('mobilevoice', target);
        this.contactMobileVoice = target._description;
      }
      if (target._channel === 4 && target._targetIdentifier == "2") {
        this.otpTargetsMap.set('landVoice', target);
        this.contactLandVoice = target._description;
      }
      if (target._channel === 2) {
        this.otpTargetsMap.set('email', target);
        this.contactEmail = target._description;
      }
    });
  }

  onSubmit() {
    const contactType = this.f.contactType.value;
    this.submitted = true;
    this.clearAlertMessage();
    this.hideTargetSelectionErrorMessage();
    this.onTargetSelected(this.otpTargetsMap.get(contactType));
    this.analyticsService.setOptOption(`opt-type-${contactType}`);
    this.analyticsService.setOtpUsed('');
    if (this.securityForm.invalid) {
      return;
    }
  }

  showTargetSelectionErrorMessage() {
    this.showError = true;
  }

  hideTargetSelectionErrorMessage() {
    this.showError = false;
  }

  back(evt) {
    this.sessionMgmtService.cancelSessions();
    evt.preventDefault();
    this.onCancel(this.appName);
  }

  showOTPLimitExceeded() {
    this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
  }

  clearAlertMessage() {
    this.alertService.hide();
  }
}
