<div class="container" >
    <div class="headSection">
      <span>Help us Verify Your Identity</span>
    </div>
    <form name="form" id="otpSelectionForm" [formGroup]="otpSelectionForm" [ngClass]="{ 'was-validated': submitted }" (ngSubmit)="onSubmit()" novalidate >
      <div class="form">
        <div [ngClass]="{ 'has-error': f.submitted  && f.errors }">
          <div class="textExplanation col10">For your security, we need to send you a One-Time Passcode (OTP) to verify your identity.
            <span class="split-text">Please select your preferred delivery method from the contact information we have on file.</span>
          </div>
        </div>
        <div *ngIf="contactEmail" class="form-row form-check" >
          <div class="radio radio-inline col-12">
            <input type="radio" class="cbensightenevent" #email name="contactType" id="email"
                   formControlName="contactType"  value="email"
                   cbdata-type="radio" cbdata-reason="verification-radio-email">
            <label for="email">Email: {{ contactEmail | textMask }} </label>
          </div>
        </div>
        <div *ngIf="contactMobileText" class="form-row form-check form-info">
          <div class="radio radio-inline col-12">
            <input type="radio" class="cbensightenevent" #text name="contactType" id="text"
                   formControlName="contactType"  value="text"
                   cbdata-type="radio" cbdata-reason="verification-radio-text">
            <label for="text">Text: {{ contactMobileText }}</label>
            <div class="input-info">Message and data rates may apply.</div>
          </div>
        </div>
        <div *ngIf="contactMobileVoice" class="form-row form-check">
          <div class="radio radio-inline col-12">
            <input type="radio" class="cbensightenevent" #mobileVoice name="contactType"
                   formControlName="contactType" id="mobileVoice" value="mobileVoice"
                   cbdata-type="radio" cbdata-reason="verification-radio-mobilevoice">
            <label for="mobileVoice">Voice: {{ contactMobileVoice }}</label>
          </div>
        </div>
        <div *ngIf="contactLandVoice" class="form-row form-check">
          <div class="radio radio-inline col-12">
            <input type="radio" class="cbensightenevent" #landVoice name="contactType" formControlName="contactType"
                   id="landVoice" value="landVoice" cbdata-type="radio" cbdata-reason="verification-radio-landvoice">
            <label for="landVoice">Voice: {{ contactLandVoice }}</label>
          </div>
        </div>
        <div class="error-message" *ngIf="showError">
          <span>Send OTP code failed. please try again.</span>
        </div>
        <div class="buttonArea">
          <button class="btn btn-primary cbensightenevent" name="btnContinue" id="btnContinue" #btnContinue type="submit" tabindex="2"
                  cbdata-type="button" cbdata-reason="verification-continue" [disabled]="loading" value="Continue" aria-label="Continue">Continue</button>
          <button tabindex="3" class="btn btn-secondary cbensightenevent"  name="btnBack" id="btnBack" (click)="back($event)"
                  cbdata-type="button" cbdata-reason="verification-cancel" value="Cancel" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    <!--    Does not contain telephone anchors -->
    <div class="need-assistance">
      Need Assistance?
    </div>
      <div class="contactArea d-none d-lg-block" *ngIf="checkTLIComponent">Email OTP is no longer allowed for reset password.
        If you don’t have phone number in your Universal profile or you don't have access to the phone numbers listed
        above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }}&#32;{{contactMessage.phone2}}
        or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-nonmobile-email"
                          href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <div class="contactArea d-none d-lg-block" *ngIf="!checkTLIComponent" >If you don’t have access to the email or phone number listed
        above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }}&#32;{{contactMessage.phone2}}
        or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-nonmobile-email"
                          href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--    Does contain telephone anchors for ios mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="!isAndroid && checkTLIComponent">Email OTP is no longer allowed for reset password.
        If you don’t have phone number in your Universal profile or you don't have access to the phone numbers listed above,
        please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-phone1"
              href="tel:{{ phoneNumber1 }}" rel="nofollow">{{ contactMessage.phone1 }}</a>&nbsp;
        <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-phone2"
           href="tel:{{ phoneNumber2 }}" rel="nofollow">{{ contactMessage.phone2 }}</a> or email us at
        <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-email"
           href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <div class="contactArea d-lg-none" *ngIf="!isAndroid && !checkTLIComponent">If you don’t have access to the email or phone number listed above,
        please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-phone1"
              href="tel:{{ phoneNumber1 }}" rel="nofollow">{{ contactMessage.phone1 }}</a>&nbsp;
        <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-phone2"
           href="tel:{{ phoneNumber2 }}" rel="nofollow">{{ contactMessage.phone2 }}</a> or email us at
        <a class="cbensightenevent" cbdata-type="link" cbdata-reason="verification-contact-mobile-email"
           href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--   Plain text for Android mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="isAndroid && checkTLIComponent" >Email OTP is no longer allowed for reset password.
        If you don’t have phone number in your Universal profile or you don't have access to the phone numbers listed
        above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }} &#32; {{contactMessage.phone2}}
        or email us at {{ contactMessage.email }}, {{ contactMessage.hours }}.
      </div>
      <div class="contactArea d-lg-none" *ngIf="isAndroid && !checkTLIComponent" >If you don’t have access to the email or phone number listed
        above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }} &#32; {{contactMessage.phone2}}
        or email us at {{ contactMessage.email }}, {{ contactMessage.hours }}.
      </div>
    </form>
  </div>
