import { Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorIntercept } from './error-intercept';
import { CoreModule} from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjectorService } from './service/shared/app-injector.service';
import { PageNotFoundComponent } from './page-not-found.component';
import { SsoUtils } from './shared/Util/sso-utils';
import { TransmitUiHandler } from './transmit/beta/transmit-ui-handler';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TokenComponent } from './token/token.component';
import { TransmitUniversalService } from './service/universal/transmit-universal.service';
import { UniversalTransmitUiHandler } from './transmit/universal/universal-transmit-ui-handler';
import { ButlerComponent } from './butler/component/butler/butler.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { LogoutRedirectComponent } from './logout-redirect/logout-redirect.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    TokenComponent,
    ButlerComponent,
    EmptyRouteComponent,
    LogoutRedirectComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    CommonModule,
    NoopAnimationsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    },
    SsoUtils,
    TransmitUiHandler,
    AppInjectorService,
    TransmitUniversalService,
    UniversalTransmitUiHandler
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjectorService.injector = injector;
  }
}
