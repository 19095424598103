<div class="parent-container d-sm-flex">
    <div class="container">
      <div class="headSection">Didn't receive One-Time <span class="split-text-oppo">Passcode (OTP) ?</span>
      </div>
      <div class="col-12 no-otp">
        <div class="textExplanation">Our records indicate that your contact information is the following:
          <span *ngIf="email">Email: {{email}}</span>
          <span *ngIf="mobile">Text: {{mobile}}</span>
          <span *ngIf="voice">Voice: {{voice}}</span>
          <span *ngIf="noInfoAvailable" class="label-bold">No information on file.</span>
        </div>
      </div>
      <div class="col-12 assistance-box">
        <div class="assistance-needed">
          <span>
            <img class="phone-icon" alt="image of a phone" src="{{assetURL}}assets/icons/phone_in_talk-24px.svg"/>
          </span>
          <div class="assistance-text">Need Assistance?</div>
          <!--    Desktop devices  -- Does not contain telephone anchors -->
          <span class="contactArea assistance-message d-none d-lg-block" >If you don’t have access to the email
            or phone number listed above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
            at {{ contactMessage.phone1 }}&#32;{{ contactMessage.phone2 }}
            or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="nootp-contact-nonmobile-email"
          target="_blank" href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
          </span>
          <!--    Does contain telephone anchors for ios mobile devices -->
          <span class="contactArea assistance-message d-lg-none" *ngIf="!isAndroid">If you don’t have access to the email or
            phone number listed above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
            at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="nootp-contact-mobile-phone1" target="_blank"
                  href="tel:{{ phoneNumber1 }}">{{ contactMessage.phone1 }}</a> &nbsp;
            <a class="cbensightenevent" cbdata-type="link" cbdata-reason="nootp-contact-mobile-phone2" target="_blank"
               href="tel:{{ phoneNumber2 }}">{{ contactMessage.phone2 }}</a>,
            or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="nootp-contact-mobile-email"
          target="_blank" href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
          </span>
          <!--   Plain text for Android mobile devices -->
          <span class="contactArea assistance-message d-lg-none" *ngIf="isAndroid">If you don’t have access to the email
            or phone number listed above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
            at {{ contactMessage.phone1 }}&#32;{{ contactMessage.phone2 }}
            or email us at {{ contactMessage.email }}, {{ contactMessage.hours }}.
          </span>
        </div>
      </div>
    </div>
</div>
