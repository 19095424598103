import { ComponentFactoryResolver, ComponentRef, Renderer2, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { AlertService } from 'src/app/service/shared/alert.service';
import { IdentityRegistrationSelectionComponent } from 'src/app/universal/component/identity-registration-selection/identity-registration-selection.component';
import { IdentityRegistrationOtpComponent } from 'src/app/universal/component/identity-registration-otp/identity-registration-otp.component';
import { OtpSelectionComponent } from 'src/app/universal/component/otp-selection/otp-selection.component';
import { EnterOtpComponent } from 'src/app/universal/component/enter-otp/enter-otp.component';
import { AcSelectOtpComponent } from 'src/app/credentials/component/ac-select-otp/ac-select-otp.component';
import { AcEnterOtpComponent } from 'src/app/credentials/component/ac-enter-otp/ac-enter-otp.component';
import { environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { UniversalSessionService } from '../../core/service/universalSession.service';
import { ApplicationService } from '../../core/service/application.service';

export class UniversalOTPAuthenticatorService implements com.ts.mobile.sdk.UIAuthenticatorSessionOtp {

  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  clientContext: any;
  actionContext: any;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  otpErrorDisplay = false;
  resendOTPFailed = false;
  targetSelectionErrorDisplay = false;
  OTPLimitExceeded = false;
  currentComponent: string;
  currentOTPStatus = '';
  isOTPLocked = false;
  serviceUnavailable = false;
  endOTPSession = false;

  // loginElementRef: ElementRef;
  securityRef: ComponentRef<IdentityRegistrationSelectionComponent>;
  passcodeRef: ComponentRef<IdentityRegistrationOtpComponent>;

  tliSecurityRef: ComponentRef<OtpSelectionComponent>;
  tliPasscodeRef: ComponentRef<EnterOtpComponent>;

  addCredsSecurityRef: ComponentRef<AcSelectOtpComponent>;
  addCredsPasscodeRef: ComponentRef<AcEnterOtpComponent>;
  targets: Array<com.ts.mobile.sdk.AuthenticatorTarget> = [];
  targetSelected: com.ts.mobile.sdk.AuthenticatorTarget;
  currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  username: string;
  currentJourney: string;
  possibleTargets: any[];
  private renderer: Renderer2;
  private loginComp: any;

  constructor(title: string, username: string, possibleTargets: Array<com.ts.mobile.sdk.OtpTarget>,
    autoExecedTarget: com.ts.mobile.sdk.OtpTarget | null,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private route: Router,
    private utils: SsoUtils,
    private sessionService: UniversalSessionService,
    private appService: ApplicationService
  ) {
    this.username = username;
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
    this.possibleTargets = possibleTargets;
  }

  startSession(description: com.ts.mobile.sdk.AuthenticatorDescription,
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
    actionContext: com.ts.mobile.sdk.PolicyAction,
    clientContext: object): void {
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.mode = mode;
    this.isOTPLocked = false;
    this.endOTPSession = false;

    this.viewContainerRef = (clientContext as any).viewContainerRef;
    this.renderer = (clientContext as any).renderer;

    this.loginComp = clientContext['loginComp'];

    this.spinnerService.clearText();

    this.loginComp.setComponentDisplay(false);
    this.sessionService.setCancelSelection(false);
    this.sessionService.setUpdateProfileOTPLockRedirect(false);

    switch (this.loginComp.viewContainerRef._hostLView[0].localName) {
      case 'app-identity-registration': {
        this.currentJourney = 'enrollment';
        break;
      }
      case 'app-forgot-password-universal': {
        this.currentJourney = 'tli';
        break;
      }
      case 'app-universal-login-mobile':
      case 'app-universal-login': {
        this.currentJourney = 'login';
        break;
      }
      case 'app-ac-enter-credentials': {
        this.currentJourney = 'addCredentials';
        break;
      }
      case 'app-view-profile': {
        this.currentJourney = 'updateProfile';
        break;
      }
      default:
        this.currentJourney = '';
    }
  }

  renderEnrollmentSecurityComponent() {
    this.currentComponent = 'securityComponent';
    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(IdentityRegistrationSelectionComponent);
    this.securityRef = this.viewContainerRef.createComponent(componentFactory);

    this.securityRef.instance.setTargets(this.possibleTargets);
    this.securityRef.instance.onTargetSelected = this.onTargetSelected;
    this.securityRef.instance.onCancel = this.onCancelSelectionPage;

    if (this.OTPLimitExceeded) {
      this.hideAllErrors();
      this.securityRef.instance.showOTPLimitExceeded();
    }

    if (this.targetSelectionErrorDisplay) {
      this.securityRef.instance.showTargetSelectionErrorMessage();
    }

    this.targetSelectionErrorDisplay = false;
    this.OTPLimitExceeded = false;
  }

  renderTLISecurityComponent() {
    this.currentComponent = 'securityComponent';
    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(OtpSelectionComponent);
    this.tliSecurityRef = this.viewContainerRef.createComponent(componentFactory);

    this.tliSecurityRef.instance.setTargets(this.possibleTargets);
    this.tliSecurityRef.instance.onTargetSelected = this.onTargetSelected;
    this.tliSecurityRef.instance.onCancel = this.onCancelSelectionPage;

    if (this.OTPLimitExceeded) {
      this.hideAllErrors();
      this.tliSecurityRef.instance.showOTPLimitExceeded();
    }

    if (this.targetSelectionErrorDisplay) {
      this.tliSecurityRef.instance.showTargetSelectionErrorMessage();
    }

    this.targetSelectionErrorDisplay = false;
    this.OTPLimitExceeded = false;
  }

  renderAddCredsSecurityComponent() {
    this.currentComponent = 'securityComponent';
    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(AcSelectOtpComponent);
    this.addCredsSecurityRef = this.viewContainerRef.createComponent(componentFactory);

    this.addCredsSecurityRef.instance.setTargets(this.possibleTargets);
    this.addCredsSecurityRef.instance.onTargetSelected = this.onTargetSelected;
    this.addCredsSecurityRef.instance.onCancel = this.onCancelSelectionPage;

    if (this.OTPLimitExceeded) {
      this.hideAllErrors();
      this.addCredsSecurityRef.instance.showOTPLimitExceeded();
    }

    if (this.targetSelectionErrorDisplay) {
      this.addCredsSecurityRef.instance.showTargetSelectionErrorMessage();
    }

    this.targetSelectionErrorDisplay = false;
    this.OTPLimitExceeded = false;
  }

  hideAllErrors() {
    this.otpErrorDisplay = false;
    this.resendOTPFailed = false;
    this.targetSelectionErrorDisplay = false;
    this.serviceUnavailable = false;
  }

  setGeneratedOtp(format: com.ts.mobile.sdk.OtpFormat, target: com.ts.mobile.sdk.OtpTarget): void {
    if (format && target) {
      // otp page
      switch (this.currentJourney) {
        case 'enrollment': {
          this.renderEnrollmentWaitingForInput(format, target);
          break;
        }
        case 'login':
        case 'updateProfile':
        case 'tli': {
          this.renderTLIWaitingForInput(format, target);
          break;
        }
        case 'addCredentials': {
          this.renderAddCredsWaitingForInput(format, target);
          break;
        }
      }
    } else {
      // delivery selection page
      switch (this.currentJourney) {
        case 'enrollment': {
          this.renderEnrollmentSecurityComponent();
          break;
        }
        case 'tli':
        case 'login':
        case 'updateProfile': {
          this.renderTLISecurityComponent();
          break;
        }
        case 'addCredentials': {
          this.renderAddCredsSecurityComponent();
          break;
        }
      }
    }
  }

  setAvailableTargets(targets: com.ts.mobile.sdk.OtpTarget[]): void {
    this.targets = targets || [];
  }

  public onCancelSelectionPage = () => {
    this.sessionService.setCancelSelection(true);
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest);
    this.currentHandler(response);
  }

  public onCancelOTPPage = (applicationName?) => {
    // const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.AbortAuthentication);
    // this.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest));

    // if (this.endOTPSession && applicationName && environment.nonOUDApps.includes(applicationName)) {
    
    // config
    if (this.endOTPSession && applicationName && this.appService.getNonOUDAppsList().includes(applicationName)) {
      this.reRouteToLoginPage();
    }

    if (this.currentJourney == 'updateProfile' && this.isOTPLocked) {
      this.sessionService.setUpdateProfileOTPLockRedirect(true);
    } else if (this.isOTPLocked) {
      this.reRouteToLoginPage();
    } else {
      // this.changeOTPDeliveryMethod();
      this.sessionService.setCancelSelection(true);
    }
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest);
    this.currentHandler(response);
  }

  changeSessionModeToRegistrationAfterExpiration(): void {
  }

  promiseRecoveryForError(error, validRecoveries, defaultRecovery): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    console.log('promiseRecoveryForError', error);

    if (this.sessionService.getCancelSelection() || this.sessionService.getUpdateProfileOTPLockRedirect()) {
      return new Promise((resolve, reject) => {
        resolve(com.ts.mobile.sdk.AuthenticationErrorRecovery.Fail);
      });
    }

    if (this.getNested(error, '_data', 'additional_data', 'locked') == true && this.getNested(error, '_data', 'additional_data', 'type') !== 'user-ldap') {
      this.isOTPLocked = true;
    }

    const errormessage = error['_message'];
    if (errormessage && errormessage.includes('exceeded the maximum allowable per time period')) {
      this.OTPLimitExceeded = true;
    }

    return new Promise((resolve, reject) => {
      if (defaultRecovery === com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator) {
        resolve(defaultRecovery);
        if (this.currentComponent == 'passcodeComponent') {
          if (this.currentOTPStatus == 'OTPSubmitted') {
            if (this.getNested(error, '_message') == 'Authentication resulted in failure') {
              this.otpErrorDisplay = true;
            } else {
              this.serviceUnavailable = true;
            }

          } else if (this.currentOTPStatus == 'resendOTP') {
            this.resendOTPFailed = true;
          }
        } else if (this.currentComponent == 'securityComponent') {
          this.targetSelectionErrorDisplay = true;
        }
      } else {
        resolve(defaultRecovery);
      }
    });
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  promiseInput(): Promise<com.ts.mobile.sdk.InputOrControlResponse<com.ts.mobile.sdk.TargetBasedAuthenticatorInput<com.ts.mobile.sdk.OtpInput, com.ts.mobile.sdk.OtpTarget>>> {
    return new Promise((resolve, reject) => {
      this.currentHandler = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
        resolve(response);
        if (this.sessionService.getUpdateProfileOTPLockRedirect()) {
          this.sessionService.setUpdateProfileOTPLockRedirect(false);
          const URL = this.sessionService.getCancelUrl();
          this.utils.redirect(URL);
        }
      };
    });
  }

  endSession(): void {
    console.log('endSession');
    this.endOTPSession = true;
  }

  private onTargetSelected = (target: com.ts.mobile.sdk.AuthenticatorTarget): void => {
    this.targetSelected = target;
    const input = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(target);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input);
    if (this.targetSelected['_channel'] == 4) {
      this.spinnerService.setText("We're calling your phone...");
    } else {
      this.spinnerService.setText('Sending One-Time Passcode (OTP)...');
    }
    this.currentHandler(response);
  }

  private renderEnrollmentWaitingForInput = (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
    this.currentComponent = 'passcodeComponent';
    this.viewContainerRef.clear();
    const componentFactory = this.resolver.resolveComponentFactory(IdentityRegistrationOtpComponent);
    this.passcodeRef = this.viewContainerRef.createComponent(componentFactory);
    this.passcodeRef.instance.getTarget(target);

    this.passcodeRef.instance.onSubmitCode = this.onSubmitCode;
    this.passcodeRef.instance.resendCode = this.resendCode;
    this.passcodeRef.instance.changeMode = this.changeOTPDeliveryMethod;
    this.passcodeRef.instance.onCancel = this.onCancelOTPPage;
    if (this.otpErrorDisplay) {
      this.passcodeRef.instance.showOTPErrorMessage();
    }
    if (this.resendOTPFailed) {
      this.passcodeRef.instance.showOTPResendFailedErrorMessage();
    }
    if (this.serviceUnavailable) {
      this.passcodeRef.instance.showServiceUnavailableError();

    }
    this.otpErrorDisplay = false;
    this.resendOTPFailed = false;
    this.currentOTPStatus = '';
    this.serviceUnavailable = false;
  }

  private renderTLIWaitingForInput = (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
    this.currentComponent = 'passcodeComponent';
    this.viewContainerRef.clear();
    const componentFactory = this.resolver.resolveComponentFactory(EnterOtpComponent);
    this.tliPasscodeRef = this.viewContainerRef.createComponent(componentFactory);
    this.tliPasscodeRef.instance.getTarget(target);

    this.tliPasscodeRef.instance.onSubmitCode = this.onSubmitCode;
    this.tliPasscodeRef.instance.resendCode = this.resendCode;
    this.tliPasscodeRef.instance.changeMode = this.changeOTPDeliveryMethod;
    this.tliPasscodeRef.instance.onCancel = this.onCancelOTPPage;
    if (this.otpErrorDisplay) {
      this.tliPasscodeRef.instance.showOTPErrorMessage();
    }
    if (this.resendOTPFailed) {
      this.tliPasscodeRef.instance.showOTPResendFailedErrorMessage();
    }
    if (this.serviceUnavailable) {
      this.tliPasscodeRef.instance.showServiceUnavailableError();
    }
    this.otpErrorDisplay = false;
    this.resendOTPFailed = false;
    this.currentOTPStatus = '';
    this.serviceUnavailable = false;
  }

  private renderAddCredsWaitingForInput = (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
    this.currentComponent = 'passcodeComponent';
    this.viewContainerRef.clear();
    const componentFactory = this.resolver.resolveComponentFactory(AcEnterOtpComponent);
    this.addCredsPasscodeRef = this.viewContainerRef.createComponent(componentFactory);
    this.addCredsPasscodeRef.instance.getTarget(target);

    this.addCredsPasscodeRef.instance.onSubmitCode = this.onSubmitCode;
    this.addCredsPasscodeRef.instance.resendCode = this.resendCode;
    this.addCredsPasscodeRef.instance.changeMode = this.changeOTPDeliveryMethod;
    this.addCredsPasscodeRef.instance.onCancel = this.onCancelOTPPage;
    if (this.otpErrorDisplay) {
      this.addCredsPasscodeRef.instance.showOTPErrorMessage();
    }
    if (this.resendOTPFailed) {
      this.addCredsPasscodeRef.instance.showOTPResendFailedErrorMessage();
    }
    if (this.serviceUnavailable) {
      this.addCredsPasscodeRef.instance.showServiceUnavailableError();
    }
    this.otpErrorDisplay = false;
    this.resendOTPFailed = false;
    this.currentOTPStatus = '';
    this.serviceUnavailable = false;
  }

  private changeOTPDeliveryMethod = () => {
    this.spinnerService.clearText();

    if (this.currentJourney == 'updateProfile' && this.isOTPLocked) {
      const URL = this.sessionService.getCancelUrl();
      this.utils.redirect(URL);
    } else {
      if (this.viewContainerRef.length < 1) return;
      this.viewContainerRef.remove(0);
      switch (this.currentJourney) {
        case 'enrollment': {
          this.renderEnrollmentSecurityComponent();
          break;
        }
        case 'tli':
        case 'login':
        case 'updateProfile': {
          this.renderTLISecurityComponent();
          break;
        }
        case 'addCredentials': {
          this.renderAddCredsSecurityComponent();
          break;
        }
      }
    }
  }

  private resendCode = () => {
    if (this.currentJourney == 'updateProfile' && this.isOTPLocked) {
      const URL = this.sessionService.getCancelUrl();
      this.utils.redirect(URL);
    } else {
      this.currentOTPStatus = 'resendOTP';
      if (this.targetSelected['_channel'] == 4) {
        this.spinnerService.setText("We're calling your phone...");
      } else {
        this.spinnerService.setText('Sending One-Time Passcode (OTP)...');
      }
      const resend = com.ts.mobile.sdk.OtpInputRequestResend.createOtpResendRequest();
      const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(resend);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);
      this.currentHandler(response);
    }
  }

  private onSubmitCode = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
    this.sessionService.setPreviousPageName(''); // clear OTP previous page
    if (this.isOTPLocked) {
      switch (this.currentJourney) {
        case 'enrollment': {
          this.passcodeRef.instance.showOTPLockedErrorMessage();
          break;
        }
        case 'tli':
        case 'login': {
          this.tliPasscodeRef.instance.showOTPLockedErrorMessage();
          break;
        }
        case 'updateProfile': {
          this.tliPasscodeRef.instance.showOTPLockedErrorMessage();
          break;
        }
        case 'addCredentials': {
          this.addCredsPasscodeRef.instance.showOTPLockedErrorMessage();
          break;
        }
      }
    }
    this.spinnerService.setText('Verifying One-Time Passcode (OTP)...');
    this.currentOTPStatus = 'OTPSubmitted';
    this.currentHandler(response);
  }

  reRouteToLoginPage() {
    switch (this.currentJourney) {
      case 'enrollment': {
        this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
          this.route.navigate(['uportal/started']));
        break;
      }
      case 'tli': {
        this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
        this.sessionService.isMobileApp() ? this.route.navigate(['uportal/mobile-login']) : this.route.navigate(['uportal/login']));
        break;
      }
      case 'login': {
        this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
        this.sessionService.isMobileApp() ? this.route.navigate(['uportal/mobile-login']) : this.route.navigate(['uportal/login']));
        break;
      }
      case 'addCredentials': {
        this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
          this.route.navigate(['credentials/manage']));
        break;
      }
      case 'updateProfile': {
        this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
          this.route.navigate(['profile/view']));
        break;
      }
    }
  }
}
