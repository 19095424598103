import { ViewContainerRef } from '@angular/core';
import { ComponentRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { Injectable } from '@angular/core';
import { OtpNotRecievedComponent } from '../../beta/component/otp-not-recieved/otp-not-recieved.component';
import { AppInjectorService } from '../shared/app-injector.service';

@Injectable({
  providedIn: 'root'
})
export class ProcessjsonService {

  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  clientContext: any;
  noOtpRef: ComponentRef<OtpNotRecievedComponent>;
  private renderer: Renderer2;

  private loginComp: any;
  jsonData: object;
  constructor() { }

  customProcessJsonData(jsonData, actionContext, clientContext): any {
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
    this.jsonData = jsonData;
    this.clientContext = clientContext;
    this.viewContainerRef = (clientContext as any).viewContainerRef;
    this.renderer = (clientContext as any).renderer;

    this.loginComp = clientContext["loginComp"];
    this.loginComp.setComponentDisplay(false);

    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(OtpNotRecievedComponent);
    this.noOtpRef = this.viewContainerRef.createComponent(componentFactory);

    this.noOtpRef.instance.setContactInfo(jsonData);
    return new Promise((resolve, reject) => {
        var jsonFromTransmit = JSON.stringify(jsonData);
        resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true));
     });
}
}
