import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';
import { AnalyticsService } from 'src/app/service/beta/analytics.service';
import { errorCodes } from 'src/error-codes.config';
import { AlertService } from 'src/app/service/shared/alert.service';
import { environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html'
})
export class PasswordComponent implements AfterViewInit {
  public submitted = false;
  public loading = false;
  public loginForm: UntypedFormGroup;
  returnUrl: string;
  resetEnabled: boolean;
  public hiddenType: boolean;
  private appName = environment.application1;
  private companyId: string;
  private userId: string;
  private pageName: string;
  private tmxSessionId: string;
  private ppAOSessionId: string;
  private analyticsSessionId = '';
  public displayErrorMessage = false;
  public onSubmitPassword: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public onCancel: (appName: string) => void;

  @ViewChild('input_password', {read: ElementRef}) firstField: ElementRef;

  constructor(private route: ActivatedRoute, private router: Router,
              private readonly formBuilder: UntypedFormBuilder,
              private utils: SsoUtils,
              private sessionMgmtService: SessionManagementService,
              private analyticsService: AnalyticsService,
              private alertService: AlertService,
              private transmitUtils: TransmitUtils) {
    this.createForm();
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.firstField.nativeElement.focus();
    }
    const data = this.sessionMgmtService.getSessionData();
    this.appName = data.appName;
    this.companyId = data.companyId;
    this.userId = data.userId;
    this.pageName = 'userpassword';    // analytics page name
    this.tmxSessionId = data.tmxSessionId;
    this.analyticsSessionId = data.analyticsSessionId;
    this.ppAOSessionId = data.ppAOSessionId;

    const analyticsJson = { cbpgType: 'login',
          cbaction: 'view',
          cbpgFunction: 'login',
          cbpgSubFunction: this.pageName,
          cbpgName: 'view',
          cbsessionId: this.analyticsSessionId,
          cbCisKey: '' // TODO: custumerid to be added on MVP
        };
    this.analyticsService.updateAnalytics(analyticsJson);
    if (this.appName === 'accessoptima') {
      window['hover']('password');
    }
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      input_password: ['', Validators.required]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(formData) {
    this.submitted = true;
    this.displayErrorMessage = false;
    this.alertService.hide();

    if (this.loginForm.invalid) {
      return;
    }
    const password = formData.input_password;

    const inputResponse = com.ts.mobile.sdk.PasswordInput.create(password);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);

    // actually calling method in password-authenticator-service
    if (this.onSubmitPassword) {
      this.onSubmitPassword(response);
    }
  }

  public onClickCancel(evt) {
    this.sessionMgmtService.cancelSessions();
    evt.preventDefault();
    // actually calling method in password-authenticator-service
    if (this.onCancel) {
      this.onCancel(this.appName);
    }
  }

  showErrorMessage() {
    // this.displayErrorMessage = true;
    const errorMessage = errorCodes.WrongCredentials;
    this.alertService.error(errorMessage);
  }

  showPasswordLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getPasswordLockedErrorMessage());
  }

  // TODO use transmit
  trouble() {
    this.router.navigate(['trouble']);
  }

}
