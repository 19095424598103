import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlSanitize'
})
export class HtmlSanitizePipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(html: string): unknown {
    if (html === null || html === undefined || html === '') {
      return '';
    }
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
