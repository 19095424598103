<div class="toolbar" id="header" role="banner">
  <div class="container" [ngClass]="{ 'fixedMargin': isDashboard }" >
    <span class="toolbarItem" *ngFor="let item of header | keyvalue" [ngSwitch]="item.value.type">
      <img *ngSwitchCase="'image'" width="{{ item.value.width }}" alt="{{ item.value.alt }}"
           src="{{ item.value.src }}" style="{{ item.value.style }}" />
      <a *ngSwitchCase="'link'" target="_blank" href="{{ item.value.href }}" style="{{ item.value.style }}">{{ item.value.text }}</a>
      <span *ngSwitchCase="'text'" style="{{ item.value.style }}">{{ item.value.text }}</span>
    </span>
  </div>
</div>
