import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { AlertService } from 'src/app/service/shared/alert.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';
import { assetURL, environment } from 'src/environments/environment';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';
import { datadogLogs } from '@datadog/browser-logs';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit, AfterViewInit {

  public submitted = false;
  public loading = false;
  public updateForm: UntypedFormGroup;
  public passcodeForm: UntypedFormGroup;
  public authError = false;
  public hide = true;
  countries;
  isEdit = false;
  alertmessage = '';

  public displayErrorMessage = false;
  displayStyle = 'none';
  verified = false;
  originalContactInfo: any;
  newContactInfo: any;
  emailVerified = false;
  mobileVerified = false;
  landlineVerified = false;

  hasEmail = false;
  hasMobile = false;
  hasLandline = false;
  showPopupAlertMessage = false;

  updatedContactInfo = {};
  verifiedContactInfo = [];

  universalId;
  public fullName;
  public firstName;
  public lastName;
  channel_type;
  channel_value;
  otpTarget: string;

  showMobileOTPError = false;
  showEmailOTPError = false;
  showLandlineOTPError = false;
  verifyOTPError = false;
  resendOTPError = false;
  otpRequired = false;
  updateProfileCheck: boolean = false;

  public onFormSubmit: (contactInfo: any) => void;
  public onCancelForm: () => void;
  public selectedItem: any;
  public selectedLandline: any;

  @ViewChild('viewContainerRef', {static: false, read: ViewContainerRef}) viewContainerRef: ViewContainerRef;
  @ViewChild('country') country: ElementRef;
  @ViewChild('selectField') selectField: ElementRef;
  @ViewChild('selectedField') selectedField: ElementRef;
  @ViewChild('passcode') passcode: ElementRef;
  displayOTPErrorMessage = false;

  private pageName = 'updateProfile';
  private customerId: string;
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';

  get assetURL() {
    return assetURL;
  }

  constructor(private readonly formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private spinnerService: SpinnerService,
    private sessionService: UniversalSessionService,
    private ssoUtils: SsoUtils, private https: HttpClient,
    private transmitUtils: UniversalTransmitUtils,
    private dashboardService: DashboardService,
    private analyticsService: UniversalAnalyticsService,
    private route: Router) {
    this.createForm();
    this.createOtpForm();
  }

  get fPasscode() {
    return this.passcodeForm.controls;
  }

  get f() {
    return this.updateForm.controls;
  }

  ngOnInit() {
    this.dashboardService.getAllCredentials().subscribe((data) => {
      this.firstName = data['firstName']
      this.lastName = data['lastName']
      this.fullName = data['userDisplayName'];
      this.universalId = data['universalId'] ? data['universalId'] : sessionStorage.getItem('universalId').toLowerCase();
      if(data['updateProfileCheck']){
        this.updateProfileCheck = data['updateProfileCheck']
      }
    });

    this.alertService.hide();
    this.spinnerService.clearText();

    this.countries = environment.univCountryCodes;
    this.setCountry('us', 'countryCode', true);
    this.setCountry('us', 'countryCode', false);

    this.originalContactInfo = this.sessionService.getUniversalProfileContactInfo();

    this.getVerifiedValuesFromSSODB();

    this.updateForm.controls['email'].setValue(this.originalContactInfo?.email);
    this.updateForm.controls['mobile'].setValue((this.originalContactInfo?.mobile !== "") ?
      this.ssoUtils.removeSpecialCharacters(this.originalContactInfo?.mobile) : undefined);
    this.updateForm.controls['landline'].setValue((this.originalContactInfo?.landline_phoneNumber !== "") ?
      this.ssoUtils.removeSpecialCharacters(this.originalContactInfo?.landline_phoneNumber) : undefined);

    const mobileCountryCode = this.originalContactInfo?.mobile_countryCode !== "" ?
      this.originalContactInfo?.mobile_countryCode.toLowerCase() : 'us';
    const landLineCountryCode = this.originalContactInfo?.landline_countryCode !== "" ?
      this.originalContactInfo?.landline_countryCode.toLowerCase() : 'us';

    this.updateForm.controls['mobilecountry'].setValue(mobileCountryCode);
    this.updateForm.controls['landlinecountry'].setValue(landLineCountryCode);

    this.setCountry(mobileCountryCode, 'countryCode', true);
    this.setCountry(landLineCountryCode, 'countryCode', false);

    this.spinnerService.hideSpinner();

    // Initialize analytics
    const data = this.sessionService.getSessionData(this.pageName, false);
    this.customerId = data?.customerId;
    this.analyticsSessionId = data?.analyticsSessionId;
    this.analyticsUserId = data?.analyticsUserId;
    this.isAuthenticated = data?.isAuthenticated;
    this.sessionService.setPreviousPageName(this.pageName);
  }

  ngAfterViewInit(): void {
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, '');
    } else {
      this.resetAnalytics();
    }
  }

  public setCountry(code: string, property: string, isMobile: boolean): void {
    if (isMobile) {
      this.selectedItem = this.countries.find(x => x[property] === code);
    } else {
      this.selectedLandline = this.countries.find(x => x[property] === code);
    }
  }

  getVerifiedValuesFromSSODB() {
    this.getContactDetailsFromSSO().subscribe(data => {

      const landlineNumber = this.ssoUtils.removeSpecialCharacters(data.landline);
      const viewProfileLandlineNumber = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['landline_code'] + this.originalContactInfo['landline_phoneNumber']);
      const viewProfileLandlineNumberNoCode = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['landline_phoneNumber']);

      if (landlineNumber == viewProfileLandlineNumber || landlineNumber == viewProfileLandlineNumberNoCode) {
        this.landlineVerified = data.landLineVerified;
        this.originalContactInfo['landline_verified'] = data.landLineVerified;
      }

      const mobileNumber = this.ssoUtils.removeSpecialCharacters(data.mobile);
      const viewProfileMobileNumber = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['mobile_code'] + this.originalContactInfo['mobile']);
      const viewProfileMobileNumberNoCode = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['mobile']);

      if (mobileNumber == viewProfileMobileNumber || mobileNumber == viewProfileMobileNumberNoCode) {
        this.mobileVerified = data.mobileVerified;
        this.originalContactInfo['mobile_verified'] = data.mobileVerified;
      }

      const emailAddress = data.email;
      const viewProfileEmailAddress = this.originalContactInfo['email'];

      if ((emailAddress) && (emailAddress?.toString().toLowerCase() == viewProfileEmailAddress?.toString().toLowerCase())) {
        this.emailVerified = data.mailIdVerified;
        this.originalContactInfo['email_verified'] = data.mailIdVerified;
      }

      if (this.emailVerified) {
        this.verifiedContactInfo.push('email' + this.originalContactInfo.email.toLowerCase());
      }

      if (this.mobileVerified) {
        this.verifiedContactInfo.push('mobile' + this.originalContactInfo.mobile_countryCode + this.originalContactInfo.mobile);
      }

      if (this.landlineVerified) {
        this.verifiedContactInfo.push('landline' + this.originalContactInfo.landline_countryCode + this.originalContactInfo.landline_phoneNumber);
      }
    }, error => {

    });
  }

  createForm() {
    this.updateForm = this.formBuilder.group({
      email: ['', [Validators.required, this.customEmailValidator]],
      mobile: [''],
      landline: [''],
      mobilecountry: ['us'],
      landlinecountry: ['us']
    });
  }

  customEmailValidator(control: AbstractControl): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const valid = emailRegex.test(control.value);
    return valid ? null : { 'invalidEmail': true };
  }

  createOtpForm() {
    this.passcodeForm = this.formBuilder.group({
      passcode: [null, Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    this.selectedItem = this.selectedItem || {};
    this.selectedLandline = this.selectedLandline || {};
    if (this.updateForm.invalid) {
      return;
    } else {
      this.newContactInfo = {
        'email': this.updateForm.controls.email.value,
        'mobile': this.ssoUtils.removeSpecialCharacters(this.updateForm.controls.mobile.value),
        'mobile_countrycode': this.selectedItem.code,
        'mobile_code': this.ssoUtils.getPhoneCodeForCountryCode(this.countries, (this.updateForm.controls.mobilecountry && this.updateForm.controls.mobilecountry.value)),
        'landline': this.ssoUtils.removeSpecialCharacters(this.updateForm.controls.landline.value),
        'landline_countrycode': this.selectedLandline.code,
        'landline_code': this.ssoUtils.getPhoneCodeForCountryCode(this.countries, (this.updateForm.controls.landlinecountry && this.updateForm.controls.landlinecountry.value))
      };

      this.emailUpdatedInfo();
      this.mobileUpdatedInfo();
      this.landlineUpdatedInfo();

      this.newContactInfo['formattedMobileNumberWithCountryCode'] = this.updatedContactInfo['univ_mobile_phone'];
      this.newContactInfo['formattedLandlineNumberWithCountryCode'] = this.updatedContactInfo['univ_home_phone'];
      this.newContactInfo['email_verified'] = this.emailVerified;
      this.newContactInfo['mobile_verified'] = this.mobileVerified;
      this.newContactInfo['landline_verified'] = this.landlineVerified;
      this.sessionService.setUniversalProfileContactInfo(this.newContactInfo);

      this.onFormSubmit(this.updatedContactInfo);
    }
  }

  emailUpdatedInfo() {
    this.updatedContactInfo['email_updated'] = !(this.originalContactInfo.email == this.newContactInfo.email);
    this.updatedContactInfo['univ_emailaddress'] = this.newContactInfo.email;
  }

  mobileUpdatedInfo() {
    if (this.originalContactInfo.mobile == "" && this.newContactInfo.mobile == "") {
      this.updatedContactInfo['mobilephone_updated'] = false;
    } else {
      this.updatedContactInfo['mobilephone_updated'] = !((this.originalContactInfo.mobile_countryCode == this.newContactInfo.mobile_countrycode) && (this.originalContactInfo.mobile == this.newContactInfo.mobile));
    }

    if (this.newContactInfo.mobile.length !== 0) {
      this.updatedContactInfo['univ_mobile_phone'] = this.ssoUtils.formatPhoneNumberWithCountryCode(this.newContactInfo.mobile_code, this.newContactInfo.mobile);
    } else {
      this.updatedContactInfo['univ_mobile_phone'] = "";
    }
  }

  landlineUpdatedInfo() {
    if (this.originalContactInfo.landline_phoneNumber == "" && this.newContactInfo.landline == "") {
      this.updatedContactInfo['homephone_updated'] = false;
    } else {
      this.updatedContactInfo['homephone_updated'] = !((this.originalContactInfo.landline_countryCode == this.newContactInfo.landline_countrycode) && (this.originalContactInfo.landline_phoneNumber == this.newContactInfo.landine));
    }

    if (this.newContactInfo.landline.length !== 0) {
      this.updatedContactInfo['univ_home_phone'] = this.ssoUtils.formatPhoneNumberWithCountryCode(this.newContactInfo.landline_code, this.newContactInfo.landline);
    } else {
      this.updatedContactInfo['univ_home_phone'] = "";
    }
  }

  emailUpdated() {
    if (this.verifiedContactInfo.indexOf('email' + this.updateForm.controls.email.value.toLowerCase()) !== -1) {
      this.emailVerified = true;
    } else if (this.originalContactInfo.email.toLowerCase() !== this.updateForm.controls.email.value.toLowerCase()) {
      this.emailVerified = false;
    } else {
      this.emailVerified = this.originalContactInfo.email_verified;
    }
  }

  landlineUpdated() {

    if (this.updateForm.controls.landlinecountry.value == 'US' && this.updateForm.controls.landline.value.length > 10) {
      let landlineNum = this.updateForm.controls.landline.value;
      this.updateForm.controls['landline'].setValue(landlineNum.slice(0, 10));
    }

    if (this.verifiedContactInfo.indexOf('landline' + this.updateForm.controls.landlinecountry.value + this.ssoUtils.removeSpecialCharacters(this.updateForm.controls.landline.value)) !== -1) {
      this.landlineVerified = true;
    } else if ((this.originalContactInfo.landline_phoneNumber !== this.updateForm.controls.landline.value) || (this.originalContactInfo.landline_countryCode !== this.updateForm.controls.landlinecountry.value)) {
      this.landlineVerified = false;
    } else {
      this.landlineVerified = this.originalContactInfo.landline_verified;
    }
  }

  mobileUpdated() {
    if (this.updateForm.controls.mobilecountry.value == 'us' && this.updateForm.controls.mobile.value.length > 10) {
      let mobileNum = this.updateForm.controls.mobile.value;
      this.updateForm.controls['mobile'].setValue(mobileNum.slice(0, 10));
    }

    if (this.verifiedContactInfo.indexOf('mobile' + this.updateForm.controls.mobilecountry.value + this.ssoUtils.removeSpecialCharacters(this.updateForm.controls.mobile.value)) !== -1) {
      this.mobileVerified = true
    } else if ((this.originalContactInfo.mobile !== this.updateForm.controls.mobile.value) || (this.originalContactInfo.mobile_countryCode !== this.updateForm.controls.mobilecountry.value)) {
      this.mobileVerified = false;
    } else {
      this.mobileVerified = this.originalContactInfo.mobile_verified;
    }
  }

  hasValue(val): boolean {
    return (val.value.length !== 0);
  }

  openTest(channel_type) {
    this.generateOTP('OTP', channel_type);
    this.modalResetAnalytics();
    this.spinnerService.showSpinner();
    this.passcodeForm.reset();
    this.submitted = false;
  }

  selectedCountry(country: any, isMobile: boolean) : void {
    if (isMobile) {
      this.updateForm.controls['mobilecountry'].setValue(country.countryCode);
      this.selectedItem = country;
    } else {
      this.updateForm.controls['landlinecountry'].setValue(country.countryCode);
      this.selectedLandline = country;
    }
  }

  generateOTP(otpCallType, channel_type) {
    this.spinnerService.showSpinner();
    if (channel_type == 'VOICE') {
      this.spinnerService.setText("We're calling your phone...");
    } else {
      this.spinnerService.setText('Sending One-Time Passcode (OTP)...');
    }
    this.generateOTPApiCall(channel_type).subscribe(data => {
      if (this.getNested(data, 'data', 'data', 'json_data', 'status') == 'Success') {
        this.displayStyle = 'block';
        setTimeout(() => {
          this.passcode.nativeElement.focus();
        });
      } else {
        // send OTP failed
        const error = this.getNested(data, 'data', 'data', 'json_data', 'result');
        if (error && error.includes('exceeded the maximum allowable per time period')) {
          if (otpCallType == 'OTP') {
            datadogLogs.logger.error(`update profile -- send otp failed -- universalId ${this.universalId}`, { error: error });
            this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
          } else if (otpCallType == 'resendOTP') {
            this.showPopupAlertMessage = true;
            this.alertmessage = this.transmitUtils.getOTPLimitExceededErrorMessage();
          }
        } else if (otpCallType == 'resendOTP') {
          this.resendOTPError = true;
        } else if (this.channel_type == 'EMAIL') {
          this.showEmailOTPError = true;
        } else if (this.channel_type == 'SMS') {
          this.showMobileOTPError = true;
        } else if (this.channel_type == 'VOICE') {
          this.showLandlineOTPError = true;
        }
      }
      this.spinnerService.hideSpinner();
    }, error => {
      console.error('Error in generating OTP -- Update profile', error);
      this.spinnerService.hideSpinner();
      const error_message = this.getNested(error, 'data', 'data', 'json_data', 'result');
      if (error_message && error_message.includes('exceeded the maximum allowable per time period')) { // alert message if OTP limit exceeded
        if (otpCallType == 'OTP') {
          datadogLogs.logger.error(`update profile -- otp limit exceeded -- universalId ${this.universalId}`, { error: error });
          this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
        } else if (otpCallType == 'resendOTP') {
          this.showPopupAlertMessage = true;
          datadogLogs.logger.error(`update profile -- resend otp limit exceeded -- universalId ${this.universalId}`, { error: error });
          this.alertmessage = this.transmitUtils.getOTPLimitExceededErrorMessage();
        }
      } else {
        datadogLogs.logger.error(`update profile -- service unavailable -- universalId ${this.universalId}`, { error: error });
        this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
      }
    });
  }

  generateOTPApiCall(channel_type): Observable<any> {
    this.passcodeForm.controls.passcode.setValue('');
    this.hideOTPErrors();
    this.channel_type = channel_type;

    if (channel_type == 'SMS') {
      this.otpTarget = this.ssoUtils.formatOTPTarget('PHONE', this.updateForm.controls.mobile.value.replace(/[^\w ]/g, ''));
      this.channel_value = this.ssoUtils.formatPhoneNumberForTransmitCall(this.countries,
        (this.updateForm.controls.mobilecountry && this.updateForm.controls.mobilecountry.value), this.updateForm.controls.mobile.value); // environment.univCountryCodesMap[this.updateForm.controls.mobilecountry.value].code, this.updateForm.controls.mobile.value);
    } else if (channel_type == 'VOICE') {
      this.otpTarget = this.ssoUtils.formatOTPTarget('VOICE', this.updateForm.controls.landline.value.replace(/[^\w ]/g, ''));
      this.channel_value = this.ssoUtils.formatPhoneNumberForTransmitCall(this.countries,
        (this.updateForm.controls.landlinecountry && this.updateForm.controls.landlinecountry.value), this.updateForm.controls.landline.value);
    } else {
      this.otpTarget = this.ssoUtils.formatOTPTarget('EMAIL', this.updateForm.controls.email.value);
      this.channel_value = this.updateForm.controls.email.value;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        observe: 'response',
        Authorization: environment.transmitToken
      })
    };
    const body = {
      'policy_request_id': 'validate_otp_channel',
      'params': {
        'channel_type': channel_type, // EMAIL, SMS or VOICE
        'channel_value': this.channel_value,
        'user_id': this.universalId
      }
    };

    return this.https.post(environment.transmitOTPUrl, body, httpOptions);
  }

  verifyOTP() {
    this.hideOTPErrors();
    if (this.passcodeForm.controls.passcode.value == null || this.passcodeForm.controls.passcode.value == '') {
      this.otpRequired = true;
      return;
    }
    this.spinnerService.showSpinner();
    this.spinnerService.setText('Verifying One-Time Passcode (OTP)...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: environment.transmitToken
      })
    };
    const body = {
      'policy_request_id': 'validate_otp_channel',
      'params': {
        "channel_type": "VALIDATE", // email, sms or voice
        "channel_value": this.passcodeForm.controls.passcode.value,
        "user_id": this.universalId
      }
    };

    return this.https.post(environment.transmitOTPUrl, body, httpOptions).subscribe(x => {
      if (this.getNested(x, 'data', 'data', 'json_data', 'status') === 'Success') {
        this.displayStyle = 'none';

        if (this.channel_type == 'EMAIL') {
          this.emailVerified = true;
          this.verifiedContactInfo.push('email' + this.updateForm.controls.email.value.toLowerCase());
        } else if (this.channel_type == 'SMS') {
          this.mobileVerified = true;
          this.verifiedContactInfo.push('mobile' + this.updateForm.controls.mobilecountry.value +
            this.ssoUtils.removeSpecialCharacters(this.updateForm.controls.mobile.value));
        } else if (this.channel_type == 'VOICE') {
          this.landlineVerified = true;
          this.verifiedContactInfo.push('landline' + this.updateForm.controls.landlinecountry.value +
            this.ssoUtils.removeSpecialCharacters(this.updateForm.controls.landline.value));
        }
      } else {
        this.displayOTPErrorMessage = true;
      }
      this.spinnerService.hideSpinner();
    }, error => {
      console.error('Error in verify otp -- Update profile', error);
      // verify OTP failed
      datadogLogs.logger.error(`update profile -- verify otp failed -- universalId ${this.universalId}`, { error: error });
      this.spinnerService.hideSpinner();
      this.verifyOTPError = true;
    });
  }

  hideOTPErrors() {
    this.displayOTPErrorMessage = false;
    this.showPopupAlertMessage = false;
    this.showLandlineOTPError = false;
    this.showMobileOTPError = false;
    this.showEmailOTPError = false;
    this.verifyOTPError = false;
    this.resendOTPError = false;
    this.otpRequired = false;
    this.alertService.hide();
  }

  hideAlertArea() {
    this.showPopupAlertMessage = false;
  }

  onCancelTest(value) {
    !this.passcodeForm.controls['passcode'].dirty;
    this.passcodeForm.controls['passcode'].patchValue(null);
    this.passcodeForm.controls['passcode'].clearValidators();
    this.passcodeForm.controls['passcode'].updateValueAndValidity();
    this.passcodeForm.controls['passcode'].setErrors(null);
    this.displayStyle = 'none';
  }

  onSubmitPasscode(formdata) {
    this.submitted = true;

    if (this.passcodeForm.invalid) {
      return;
    } else {
      // onSubmitPasscode
    }
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  getCode() {
    this.generateOTP('resendOTP', this.channel_type);
  }

  phoneMask(rawNum) {
    rawNum = rawNum.replace(/\-/g, '');
    const areaCodeStr = rawNum.slice(0, 3);
    const midSectionStr = rawNum.slice(3, 6);
    const lastSectionStr = rawNum.slice(6, 10);

    return `(${areaCodeStr})-${midSectionStr}-${lastSectionStr}`;
  }

  onCancel(evt?) {
    // this.onCancelForm();
      this.route.navigate(['dashboard']);
  }

  cancelEdited() {
    this.isEdit = false;
    this.selectField.nativeElement.disabled = true;
    this.selectedField.nativeElement.disabled = true;
  }

  showServiceUnavailableAlertMessage() {
    this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  }

  getContactDetailsFromSSO() {
    const url = environment.univ_getcontactdetails + '/' + this.universalId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.https.get<any>(url, httpOptions);
  }

  isMobileCountryUS(): boolean {
    if (this.updateForm.controls.mobilecountry && this.updateForm.controls.mobilecountry.value == 'us') {
      return true;
    }
    return false;
  }

  isLandlineCountryUS(): boolean {
    if (this.updateForm.controls.landlinecountry && this.updateForm.controls.landlinecountry.value == 'us') {
      return true;
    }
    return false;
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'profile',
      cbaction: 'start',
      cbpgFunction: 'updateprofile',
      cbpgSubFunction: '',
      cbpgName: 'start',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  modalResetAnalytics(){
      const analyticsJson = {
        cbpgType: 'profile',
        cbaction: 'view',
        cbpgFunction: 'updateprofile',
        cbpgSubFunction: 'testinformation',
        cbpgName: 'view',
        cbsessionId: this.analyticsSessionId,
        cbaccType: '',
        cbCisKey: this.analyticsUserId,
        cbauthenticated: this.isAuthenticated
      };
      this.analyticsService.updateAnalytics(analyticsJson);
  }

}
