<div *ngIf="!isAddCredential" class="headSection top-step">
    Universal Login Registration
</div>
<div *ngIf="isAddCredential" class="headSection top-step">
    Add Service
</div>

<div class="container">
    <div class="col-xs-8 col-md-10 stepperMainContainer">
        <span *ngFor="let step of steps | async; let i = index;">
        <div class="stepperItem" [ngClass]="{'stepperActiveBackground': ((currentStep | async)?.stepIndex === step.stepIndex && step.stepIndex !== 1), 'current-description': ((currentStep | async)?.stepIndex === step.stepIndex)}" >
            <ng-container>
                <p
                [ngClass]="{'step-1-done': (step.stepIndex === 1 && step.isComplete),
                'step-2-done': (step.stepIndex === 2 && step.isComplete),
                'step-3-done': ((currentStep | async)?.stepIndex === step.stepIndex && step.isComplete),
                'step-1-active': ((currentStep | async)?.stepIndex === step.stepIndex),
                'step-incomplete': step.isComplete === false
            }">
                    {{step.stepIndex}}
                </p>
                <span *ngIf="!isMobile" class="step-description">{{step.stepName}}</span>
                <span *ngIf="isMobile" [ngClass]="{'step1-done': (step.stepIndex === 1 && step.isComplete), 'step2-done': (step.stepIndex === 2 && step.isComplete), 'step3-done': ((currentStep | async)?.stepIndex === step.stepIndex && step.isComplete), 'step-incomplete': (!step.isComplete && (currentStep | async)?.stepIndex !== step.stepIndex),
                'step1-active': ((currentStep | async)?.stepIndex === step.stepIndex && step.stepIndex === 1), 'step2-active': ((currentStep | async)?.stepIndex === step.stepIndex && step.stepIndex === 2), 'step3-active': ((currentStep | async)?.stepIndex === step.stepIndex && step.stepIndex === 3)
            }" class="step-description">{{step.stepName}}</span>

            </ng-container>
        </div>
    </span>
    </div>
</div>

