import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, HostListener, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { AlertService } from 'src/app/service/shared/alert.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { assetURL, environment } from 'src/environments/environment';
import { SsoUtils } from '../../Util/sso-utils';
import { UniversalTransmitUtils } from '../../Util/universal-transmit-utils';
import { DashboardService } from 'src/app/service/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransmitUniversalService } from 'src/app/service/universal/transmit-universal.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-update-profile-dialog',
  templateUrl: './update-profile-dialog.component.html',
  styleUrls: ['./update-profile-dialog.component.scss']
})
export class UpdateProfileDialogComponent {

  @Input() fraudPreventionDialog: boolean;

  leanrMoreUrl: string = environment.fraudProtectionLearnMore;

  public submitted = false;
  public loading = false;
  public updateDialogForm: UntypedFormGroup;
  public passcodeForm: UntypedFormGroup;
  public authError = false;
  public hide = true;
  countries;
  isEdit = false;
  alertmessage = '';

  public displayErrorMessage = false;
  displayStyle = 'none';
  verified = false;
  originalContactInfo: any;
  newContactInfo: any;
  emailVerified = false;
  mobileVerified = false;
  landlineVerified = false;

  hasEmail = false;
  hasMobile = false;
  hasLandline = false;
  showPopupAlertMessage = false;

  updatedContactInfo = {};
  verifiedContactInfo = [];

  universalId;
  public fullName;
  public firstName;
  public lastName;
  public mobilePhone;
  public landlinePhone;
  channel_type;
  channel_value;
  otpTarget: string;

  showMobileOTPError = false;
  showEmailOTPError = false;
  showLandlineOTPError = false;
  verifyOTPError = false;
  resendOTPError = false;
  otpRequired = false;
  public userData;
  public isMobileApp: boolean = false;

  public onFormSubmit: (contactInfo: any) => void;
  public onCancelForm: () => void;
  public selectedItem: any;
  public selectedLandline: any;
  public mobile = '';
  public mobileFormatted = '';
  public mobileCode = '';
  public mobileCountryCode = '';
  public landline = '';
  public landlineFormatted = '';
  public landlineCode = '';
  public landlineCountryCode = '';

  @ViewChild('viewContainerRef', { static: false, read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  @ViewChild('country') country: ElementRef;
  @ViewChild('selectField') selectField: ElementRef;
  @ViewChild('selectedField') selectedField: ElementRef;
  @ViewChild('passcode') passcode: ElementRef;
  displayOTPErrorMessage = false;

  private userDataSubject = new Subject<any>();

  get assetURL() {
    return assetURL;
  }

  constructor(private readonly formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private spinnerService: SpinnerService,
    private sessionService: UniversalSessionService,
    private ssoUtils: SsoUtils, private https: HttpClient,
    private transmitUtils: UniversalTransmitUtils,
    private dashboardService: DashboardService,
    private route: Router,
    private modal: NgbModal,
    private transmitService: TransmitUniversalService) {
    this.createForm();
    this.createOtpForm();
  }


  get fPasscode() {
    return this.passcodeForm.controls;
  }

  get f() {
    return this.updateDialogForm.controls;
  }


  ngOnInit() {
    let userData;
    this.universalId = sessionStorage.getItem('universalId');
    // this.dashboardService.getAllCredentials(true).subscribe((data) => {
    //   this.userDataSubject.next(data);
    //   this.firstName = data['firstName']
    //   this.lastName = data['lastName']
    //   this.fullName = data['userDisplayName'];
    //   this.mobilePhone = data['mobilePhone'];
    //   this.landlinePhone = data['homePhone'];
    // });
    
    
    this.alertService.hide();
    this.spinnerService.clearText();

    this.countries = environment.univCountryCodes;
    this.setCountry('us', 'countryCode', true);
    this.setCountry('us', 'countryCode', false);

    this.userDataSubject.subscribe(data => {
      this.loadProfileData(data);
      this.originalContactInfo = this.sessionService.getUniversalProfileContactInfo();
      this.getVerifiedValuesFromSSODB();

    this.updateDialogForm.controls['mobile'].setValue((this.originalContactInfo.mobile !== "") ?
      this.ssoUtils.removeSpecialCharacters(this.originalContactInfo.mobile) : undefined);
    this.updateDialogForm.controls['landline'].setValue((this.originalContactInfo.landline_phoneNumber !== "") ?
      this.ssoUtils.removeSpecialCharacters(this.originalContactInfo.landline_phoneNumber) : undefined);

    const mobileCountryCode = this.originalContactInfo.mobile_countryCode !== "" ?
      this.originalContactInfo.mobile_countryCode.toLowerCase() : 'us';
    const landLineCountryCode = this.originalContactInfo.landline_countryCode !== "" ?
      this.originalContactInfo.landline_countryCode.toLowerCase() : 'us';

    this.updateDialogForm.controls['mobilecountry'].setValue(mobileCountryCode);
    this.updateDialogForm.controls['landlinecountry'].setValue(landLineCountryCode);

    this.setCountry(mobileCountryCode, 'countryCode', true);
    this.setCountry(landLineCountryCode, 'countryCode', false);
    })

    this.isMobileApp = this.sessionService.isMobileApp();

  }

  loadProfileData(data) {
    let mobile = data.mobilePhone;
    let landline = data.homePhone;
    this.countries = environment.univCountryCodes;

    let mobilephone;
    let homephone;

    if (mobile && mobile.length > 0) {
      let mobileCountryCode;
      let mobilePhoneNumber;
      let mobileCountryPhoneCode;
      if (mobile.charAt(0) === '(') {
        mobileCountryPhoneCode = mobile.substring(1, mobile.indexOf(')'));
        mobileCountryCode = this.ssoUtils.getCountryCodeFromPhoneCode(this.countries,
          this.ssoUtils.removeSpecialCharacters(mobileCountryPhoneCode));
        mobilePhoneNumber = this.ssoUtils.removeSpecialCharacters(mobile.substring(mobile.indexOf(')') + 1));
      } else {
        mobileCountryCode = 'US';
        mobilePhoneNumber = this.ssoUtils.removeSpecialCharacters(mobile);
      }
      mobilephone = {
        phoneNumber: mobilePhoneNumber,
        countryCode: mobileCountryCode
      };
    } else {
      mobilephone = '';
    }

    if (landline && landline.length > 0) {
      let landlineCountryPhoneCode;
      let landlineCountryCode;
      let landlinePhoneNumber;
      if (landline.charAt(0) === '(') {
        landlineCountryPhoneCode = landline.substring(1, landline.indexOf(')'));
        landlineCountryCode = this.ssoUtils.getCountryCodeFromPhoneCode(this.countries,
          this.ssoUtils.removeSpecialCharacters(landlineCountryPhoneCode));
        landlinePhoneNumber = this.ssoUtils.removeSpecialCharacters(landline.substring(landline.indexOf(')') + 1));
      } else {
        landlineCountryCode = 'US'
        landlinePhoneNumber = this.ssoUtils.removeSpecialCharacters(landline);
      }
      homephone = {
        phoneNumber: landlinePhoneNumber,
        countryCode: landlineCountryCode
      };
    } else {
      homephone = '';
    }

    const contactInfo = {
      mobilephone: mobilephone,
      homephone: homephone
    };

    if (contactInfo !== undefined) {

      if (contactInfo.homephone) {
        this.landlineCountryCode = contactInfo.homephone.countryCode;
        this.landlineCode = this.ssoUtils.getPhoneCodeForCountryCode(this.countries, contactInfo.homephone.countryCode.toLowerCase());
        this.landline = contactInfo.homephone.phoneNumber;
        this.landlineFormatted = (contactInfo.homephone !== undefined || null || '') ?
          this.ssoUtils.formatPhoneNumberWithCountryCode(this.landlineCode, contactInfo.homephone.phoneNumber) : '';
      }

      if (contactInfo.mobilephone) {
        this.mobileCode = this.ssoUtils.getPhoneCodeForCountryCode(this.countries, contactInfo.mobilephone.countryCode.toLowerCase());
        this.mobileCountryCode = contactInfo.mobilephone.countryCode;
        this.mobile = contactInfo.mobilephone.phoneNumber;
        this.mobileFormatted = (contactInfo.mobilephone !== undefined || null || '') ?
          this.ssoUtils.formatPhoneNumberWithCountryCode(this.mobileCode, contactInfo.mobilephone.phoneNumber) : '';
      }

      this.sessionService.setUniversalProfileContactInfo({
        'email_verified': false,
        'mobile_countryCode': this.mobileCountryCode,
        'mobile_code': this.mobileCode,
        'mobile': this.mobile,
        'mobile_verified': false,
        'landline_countryCode': this.landlineCountryCode,
        'landline_code': this.landlineCode,
        'landline_phoneNumber': this.landline,
        'landline_verified': false
      });
    }
    this.spinnerService.hideSpinner();

  }


  public setCountry(code: string, property: string, isMobile: boolean): void {
    if (isMobile) {
      this.selectedItem = this.countries.find(x => x[property] === code);
    } else {
      this.selectedLandline = this.countries.find(x => x[property] === code);
    }
  }

  getVerifiedValuesFromSSODB() {
    this.getContactDetailsFromSSO().subscribe(data => {

      const landlineNumber = this.ssoUtils.removeSpecialCharacters(data.landline);
      const viewProfileLandlineNumber = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['landline_code'] + this.originalContactInfo['landline_phoneNumber']);
      const viewProfileLandlineNumberNoCode = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['landline_phoneNumber']);

      if (landlineNumber == viewProfileLandlineNumber || landlineNumber == viewProfileLandlineNumberNoCode) {
        this.landlineVerified = data.landLineVerified;
        this.originalContactInfo['landline_verified'] = data.landLineVerified;
      }

      const mobileNumber = this.ssoUtils.removeSpecialCharacters(data.mobile);
      const viewProfileMobileNumber = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['mobile_code'] + this.originalContactInfo['mobile']);
      const viewProfileMobileNumberNoCode = this.ssoUtils.removeSpecialCharacters(this.originalContactInfo['mobile']);

      if (mobileNumber == viewProfileMobileNumber || mobileNumber == viewProfileMobileNumberNoCode) {
        this.mobileVerified = data.mobileVerified;
        this.originalContactInfo['mobile_verified'] = data.mobileVerified;
      }

      if (this.mobileVerified) {
        this.verifiedContactInfo.push('mobile' + this.originalContactInfo.mobile_countryCode + this.originalContactInfo.mobile);
      }

      if (this.landlineVerified) {
        this.verifiedContactInfo.push('landline' + this.originalContactInfo.landline_countryCode + this.originalContactInfo.landline_phoneNumber);
      }
    }, error => {

    });
  }

  createForm() {
    this.updateDialogForm = this.formBuilder.group({
      mobile: [''],
      landline: [''],
      mobilecountry: ['us'],
      landlinecountry: ['us']
    });
  }

  createOtpForm() {
    this.passcodeForm = this.formBuilder.group({
      passcode: [null, Validators.required]
    });
  }

  onSubmit(formData) {
    this.submitted = true;
    if (this.updateDialogForm.invalid) {
      return;
    } else {
      this.newContactInfo = {
        'mobile': this.ssoUtils.removeSpecialCharacters(this.updateDialogForm.controls.mobile.value),
        'mobile_countrycode': this.selectedItem.code,
        'mobile_code': this.ssoUtils.getPhoneCodeForCountryCode(this.countries, (this.updateDialogForm.controls.mobilecountry && this.updateDialogForm.controls.mobilecountry.value)),
        'landline': this.ssoUtils.removeSpecialCharacters(this.updateDialogForm.controls.landline.value),
        'landline_countrycode': this.selectedLandline.code,
        'landline_code': this.ssoUtils.getPhoneCodeForCountryCode(this.countries, (this.updateDialogForm.controls.landlinecountry && this.updateDialogForm.controls.landlinecountry.value))
      };

      this.mobileUpdatedInfo();
      this.landlineUpdatedInfo();

      this.newContactInfo['formattedMobileNumberWithCountryCode'] = this.updatedContactInfo['univ_mobile_phone'];
      this.newContactInfo['formattedLandlineNumberWithCountryCode'] = this.updatedContactInfo['univ_home_phone'];
      this.newContactInfo['mobile_verified'] = this.mobileVerified;
      this.newContactInfo['landline_verified'] = this.landlineVerified;
      this.sessionService.setUniversalProfileContactInfo(this.newContactInfo);
      // this.onFormSubmit(this.updatedContactInfo);
      this.transmitService.updateSplashDetailsTransmitInvokePolicy(this.newContactInfo['formattedMobileNumberWithCountryCode'], 
      this.newContactInfo['formattedLandlineNumberWithCountryCode'], 'update_profile').then((res) => {
        console.log(res);
        this.updateContactDetailsToSSO(this.newContactInfo);
        this.modal.dismissAll();
      }).catch(error => {
        console.error(error);
      });
      
      this.rememberCheck(false);
    }
  }

  mobileUpdatedInfo() {
    if (this.originalContactInfo.mobile == "" && this.newContactInfo.mobile == "") {
      this.updatedContactInfo['mobilephone_updated'] = false;
    } else {
      this.updatedContactInfo['mobilephone_updated'] = !((this.originalContactInfo.mobile_countryCode == this.newContactInfo.mobile_countrycode) && (this.originalContactInfo.mobile == this.newContactInfo.mobile));
    }

    if (this.newContactInfo.mobile.length !== 0) {
      this.updatedContactInfo['univ_mobile_phone'] = this.ssoUtils.formatPhoneNumberWithCountryCode(this.newContactInfo.mobile_code, this.newContactInfo.mobile);
    } else {
      this.updatedContactInfo['univ_mobile_phone'] = "";
    }
  }

  landlineUpdatedInfo() {
    if (this.originalContactInfo.landline_phoneNumber == "" && this.newContactInfo.landline == "") {
      this.updatedContactInfo['homephone_updated'] = false;
    } else {
      this.updatedContactInfo['homephone_updated'] = !((this.originalContactInfo.landline_countryCode == this.newContactInfo.landline_countrycode) && (this.originalContactInfo.landline_phoneNumber == this.newContactInfo.landine));
    }

    if (this.newContactInfo.landline.length !== 0) {
      this.updatedContactInfo['univ_home_phone'] = this.ssoUtils.formatPhoneNumberWithCountryCode(this.newContactInfo.landline_code, this.newContactInfo.landline);
    } else {
      this.updatedContactInfo['univ_home_phone'] = "";
    }
  }

  landlineUpdated() {

    if (this.updateDialogForm.controls.landlinecountry.value == 'US' && this.updateDialogForm.controls.landline.value.length > 10) {
      let landlineNum = this.updateDialogForm.controls.landline.value;
      this.updateDialogForm.controls['landline'].setValue(landlineNum.slice(0, 10));
    }

    if (this.verifiedContactInfo.indexOf('landline' + this.updateDialogForm.controls.landlinecountry.value + this.ssoUtils.removeSpecialCharacters(this.updateDialogForm.controls.landline.value)) !== -1) {
      this.landlineVerified = true;
    } else if ((this.originalContactInfo.landline_phoneNumber !== this.updateDialogForm.controls.landline.value) || (this.originalContactInfo.landline_countryCode !== this.updateDialogForm.controls.landlinecountry.value)) {
      this.landlineVerified = false;
    } else {
      this.landlineVerified = this.originalContactInfo.landline_verified;
    }
  }

  mobileUpdated() {
    if (this.updateDialogForm.controls.mobilecountry.value == 'us' && this.updateDialogForm.controls.mobile.value.length > 10) {
      let mobileNum = this.updateDialogForm.controls.mobile.value;
      this.updateDialogForm.controls['mobile'].setValue(mobileNum.slice(0, 10));
    }

    if (this.verifiedContactInfo.indexOf('mobile' + this.updateDialogForm.controls.mobilecountry.value + this.ssoUtils.removeSpecialCharacters(this.updateDialogForm.controls.mobile.value)) !== -1) {
      this.mobileVerified = true
    } else if ((this.originalContactInfo.mobile !== this.updateDialogForm.controls.mobile.value) || (this.originalContactInfo.mobile_countryCode !== this.updateDialogForm.controls.mobilecountry.value)) {
      this.mobileVerified = false;
    } else {
      this.mobileVerified = this.originalContactInfo.mobile_verified;
    }
  }

  hasValue(val): boolean {
    return (val.value.length !== 0);
  }

  openTest(channel_type) {
    this.generateOTP('OTP', channel_type);
    this.spinnerService.showSpinner();
    this.passcodeForm.reset();
    this.submitted = false;
  }

  selectedCountry(country: any, isMobile: boolean): void {
    if (isMobile) {
      this.updateDialogForm.controls['mobilecountry'].setValue(country.countryCode);
      this.selectedItem = country;
    } else {
      this.updateDialogForm.controls['landlinecountry'].setValue(country.countryCode);
      this.selectedLandline = country;
    }
  }

  generateOTP(otpCallType, channel_type) {
    this.spinnerService.showSpinner();
    if (channel_type == 'VOICE') {
      this.spinnerService.setText("We're calling your phone...");
    } else {
      this.spinnerService.setText('Sending One-Time Passcode (OTP)...');
    }
    this.generateOTPApiCall(channel_type).subscribe(data => {
      if (this.getNested(data, 'data', 'data', 'json_data', 'status') == 'Success') {
        this.displayStyle = 'block';
        setTimeout(() => {
          this.passcode.nativeElement.focus(); 
        });
      } else {
        // send OTP failed
        const error = this.getNested(data, 'data', 'data', 'json_data', 'result');
        if (error && error.includes('exceeded the maximum allowable per time period')) {
          if (otpCallType == 'OTP') {
            this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
          } else if (otpCallType == 'resendOTP') {
            this.showPopupAlertMessage = true;
            this.alertmessage = this.transmitUtils.getOTPLimitExceededErrorMessage();
          }
        } else if (otpCallType == 'resendOTP') {
          this.resendOTPError = true;
        } else if (this.channel_type == 'SMS') {
          this.showMobileOTPError = true;
        } else if (this.channel_type == 'VOICE') {
          this.showLandlineOTPError = true;
        }
      }
      this.spinnerService.hideSpinner();
    }, error => {
      console.error('Error in generating OTP -- Update profile', error);
      this.spinnerService.hideSpinner();
      const error_message = this.getNested(error, 'data', 'data', 'json_data', 'result');
      if (error_message && error_message.includes('exceeded the maximum allowable per time period')) { // alert message if OTP limit exceeded
        if (otpCallType == 'OTP') {
          this.alertService.error(this.transmitUtils.getOTPLimitExceededErrorMessage());
        } else if (otpCallType == 'resendOTP') {
          this.showPopupAlertMessage = true;
          this.alertmessage = this.transmitUtils.getOTPLimitExceededErrorMessage();
        }
      } else {
        this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
      }
    });
  }

  generateOTPApiCall(channel_type): Observable<any> {
    this.passcodeForm.controls.passcode.setValue('');
    this.hideOTPErrors();
    this.channel_type = channel_type;

    if (channel_type == 'SMS') {
      this.otpTarget = this.ssoUtils.formatOTPTarget('PHONE', this.updateDialogForm.controls.mobile.value.replace(/[^\w ]/g, ''));
      this.channel_value = this.ssoUtils.formatPhoneNumberForTransmitCall(this.countries,
        (this.updateDialogForm.controls.mobilecountry && this.updateDialogForm.controls.mobilecountry.value), this.updateDialogForm.controls.mobile.value); // environment.univCountryCodesMap[this.updateForm.controls.mobilecountry.value].code, this.updateForm.controls.mobile.value);
    } else {
      this.otpTarget = this.ssoUtils.formatOTPTarget('VOICE', this.updateDialogForm.controls.landline.value.replace(/[^\w ]/g, ''));
      this.channel_value = this.ssoUtils.formatPhoneNumberForTransmitCall(this.countries,
        (this.updateDialogForm.controls.landlinecountry && this.updateDialogForm.controls.landlinecountry.value), this.updateDialogForm.controls.landline.value);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        observe: 'response',
        Authorization: environment.transmitToken
      })
    };
    const body = {
      'policy_request_id': 'validate_otp_channel',
      'params': {
        'channel_type': channel_type, // EMAIL, SMS or VOICE
        'channel_value': this.channel_value,
        'user_id': this.universalId
      }
    };

    return this.https.post(environment.transmitOTPUrl, body, httpOptions);
  }

  verifyOTP() {
    this.hideOTPErrors();
    if (this.passcodeForm.controls.passcode.value == null || this.passcodeForm.controls.passcode.value == '') {
      this.otpRequired = true;
      return;
    }
    this.spinnerService.showSpinner();
    this.spinnerService.setText('Verifying One-Time Passcode (OTP)...');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: environment.transmitToken
      })
    };
    const body = {
      'policy_request_id': 'validate_otp_channel',
      'params': {
        "channel_type": "VALIDATE", // email, sms or voice
        "channel_value": this.passcodeForm.controls.passcode.value,
        "user_id": this.universalId
      }
    };

    return this.https.post(environment.transmitOTPUrl, body, httpOptions).subscribe(x => {
      if (this.getNested(x, 'data', 'data', 'json_data', 'status') === 'Success') {
        this.displayStyle = 'none';

        if (this.channel_type == 'SMS') {
          this.mobileVerified = true;
          this.verifiedContactInfo.push('mobile' + this.updateDialogForm.controls.mobilecountry.value +
            this.ssoUtils.removeSpecialCharacters(this.updateDialogForm.controls.mobile.value));
        } else if (this.channel_type == 'VOICE') {
          this.landlineVerified = true;
          this.verifiedContactInfo.push('landline' + this.updateDialogForm.controls.landlinecountry.value +
            this.ssoUtils.removeSpecialCharacters(this.updateDialogForm.controls.landline.value));
        }
      } else {
        this.displayOTPErrorMessage = true;
      }
      this.spinnerService.hideSpinner();
    }, error => {
      console.error('Error in verify otp -- Update profile', error);
      // verify OTP failed
      this.spinnerService.hideSpinner();
      this.verifyOTPError = true;
    });
  }

  hideOTPErrors() {
    this.displayOTPErrorMessage = false;
    this.showPopupAlertMessage = false;
    this.showLandlineOTPError = false;
    this.showMobileOTPError = false;
    this.verifyOTPError = false;
    this.resendOTPError = false;
    this.otpRequired = false;
    this.alertService.hide();
  }

  hideAlertArea() {
    this.showPopupAlertMessage = false;
  }

  onCancelTest(value) {
    !this.passcodeForm.controls['passcode'].dirty;
    this.passcodeForm.controls['passcode'].patchValue(null);
    this.passcodeForm.controls['passcode'].clearValidators();
    this.passcodeForm.controls['passcode'].updateValueAndValidity();
    this.passcodeForm.controls['passcode'].setErrors(null);
    this.displayStyle = 'none';
  }

  onSubmitPasscode(formdata) {
    this.submitted = true;

    if (this.passcodeForm.invalid) {
      return;
    } else {
      // onSubmitPasscode
    }
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  getCode() {
    this.generateOTP('resendOTP', this.channel_type);
  }

  phoneMask(rawNum) {
    rawNum = rawNum.replace(/\-/g, '');
    const areaCodeStr = rawNum.slice(0, 3);
    const midSectionStr = rawNum.slice(3, 6);
    const lastSectionStr = rawNum.slice(6, 10);

    return `(${areaCodeStr})-${midSectionStr}-${lastSectionStr}`;
  }

  onCancel(evt) {
    // this.onCancelForm();
    this.route.navigate(['dashboard']);
  }

  cancelEdited() {
    this.isEdit = false;
    this.selectField.nativeElement.disabled = true;
    this.selectedField.nativeElement.disabled = true;
  }

  showServiceUnavailableAlertMessage() {
    this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  }

  getContactDetailsFromSSO() {
    const url = environment.univ_getcontactdetails + '/' + this.universalId;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.https.get<any>(url, httpOptions);
  }

  isMobileCountryUS(): boolean {
    if (this.updateDialogForm.controls.mobilecountry && this.updateDialogForm.controls.mobilecountry.value == 'us') {
      return true;
    }
    return false;
  }

  isLandlineCountryUS(): boolean {
    if (this.updateDialogForm.controls.landlinecountry && this.updateDialogForm.controls.landlinecountry.value == 'us') {
      return true;
    }
    return false;
  }

  updateContactDetailsToSSO(updatedData) {
    const url = environment.univ_updatecontactdetails + '/' + this.universalId.toLowerCase();

    const body = {
      'universalId': this.universalId.toLowerCase(),
      'firstName': this.firstName,
      'lastName': this.lastName,
      'email': this.dashboardService.emailId,
      'landline': updatedData.formattedLandlineNumberWithCountryCode,
      'mobile': updatedData.formattedMobileNumberWithCountryCode,
      'mailIdVerified': updatedData.email_verified,
      'landLineVerified': updatedData.landline_verified,
      'mobileVerified': updatedData.mobile_verified
    };

    return this.https.put<any>(url, body).subscribe(res => {
      console.log('response on update profile', res);
    }, error => {
      console.error('error update profile sso db', error);
    });

  }

  onCross() {
    this.modal.dismissAll();
  }

  rememberCheck(evt) {
    const url = environment.univ_updateSplash;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = {
      universalId: this.universalId,
      showSplash: evt.checked === undefined ? false : !evt.checked
    };
    return this.https.post(url, body, httpOptions).subscribe((res) => {
      console.log(res);
    })
  }

  onAcknowledge() {
    const formattedDate = moment().tz('America/New_York').utc().format('YYYY-MM-DD HH:mm:ss'); 
    this.dashboardService.saveFraudAcknowledgement(formattedDate);
    this.onCross();
  }

}
