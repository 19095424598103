import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateProfileComponent } from 'src/app/profile/component/update-profile/update-profile.component';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';

class UniversalProfileUpdateFormSession implements com.ts.mobile.sdk.UIFormSession {
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  clientContext: object;
  actionContext: com.ts.mobile.sdk.PolicyAction;
  uiContext: com.ts.mobile.sdk.UIContext;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  router: Router;
  updateProfileRef: ComponentRef<UpdateProfileComponent>;
  formId: string;
  payload: string;
  currentHandler: (response: com.ts.mobile.sdk.FormInput) => void;
  private loginComp: any;

  constructor(formId: string, payload: object, private spinnerService: SpinnerService, private route: Router) {
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
    this.formId = formId;
    this.router = route;
  }

  startSession = (clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void => {
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

    this.loginComp = clientContext["loginComp"];
    this.loginComp.setComponentDisplay(false);
    this.renderUpdateProfileComponent();
  }

  renderUpdateProfileComponent() {
    this.viewContainerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(UpdateProfileComponent);
    this.updateProfileRef = this.viewContainerRef.createComponent(componentFactory);

    this.updateProfileRef.instance.onFormSubmit = this.onSubmit;
    this.updateProfileRef.instance.onCancelForm = this.onCancelUpdate;
  }

  promiseFormInput = (): Promise<com.ts.mobile.sdk.FormInput> => {
    return new Promise((resolve, reject) => {
      this.currentHandler = (response: com.ts.mobile.sdk.FormInput) => {
        resolve(response);
      };
    });
  }
  changeSessionModeToRegistrationAfterExpiration = (): void => {
  }

  onCancelUpdate() {
    this.currentHandler(com.ts.mobile.sdk.FormInput.createFormCancellationRequest());
  }

  onContinue = (payload) => {
    this.updateProfileRef.instance.showServiceUnavailableAlertMessage();
  }

  onError = (payload) => {
    console.error('UniversalProfileUpdateFormSession onError=', payload);
    this.updateProfileRef.instance.showServiceUnavailableAlertMessage();
  }

  endSession = () => {
  }

  private onSubmit = (contactInfo: any) => {
    this.spinnerService.setText('');
    const response = com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(contactInfo);
    this.currentHandler(response);
  }
}

export default UniversalProfileUpdateFormSession;
