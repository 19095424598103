export const errorCodes = {
 // 3210: 'Your request cannot be processed currently, please try again after sometime or call Client Services at {environment.contactMessage.phone1}, {environment.contactMessage.hours} or email us at {environment.contactMessage.email}',
  3216: 'The information you entered does not match our records. Please check it and try again.',
  3209: 'The information you entered does not match our records. Please check it and try again.',
  3211: 'The User Account you are trying to access is currently disabled. Please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  3215: 'Your account is locked. Please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  3220: 'Your account is locked. Please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  3212: 'The User Account you are trying to access is currently locked. Please use the Trouble logging in link to reset your password / unlock your account or contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  3208: 'We are unable to process your request due to incomplete information available with us. Please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  3252: 'Your request cannot be processed at this time. Please cancel and try again. If the problem persists, please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
 // 3201: 'Your request cannot be processed currently, please try again after sometime or call Client Services at {environment.contactMessage.phone1}, {environment.contactMessage.hours} or email us at {environment.contactMessage.email}',
 // 3214: 'Your request cannot be processed currently, please try again after sometime or call Client Services at {environment.contactMessage.phone1}, {environment.contactMessage.hours} or email us at {environment.contactMessage.email}',
 // 3213: 'Your request cannot be processed currently, please try again after sometime or call Client Services at {environment.contactMessage.phone1}, {environment.contactMessage.hours} or email us at {environment.contactMessage.email}',
 // 3203: 'Your request cannot be processed currently, please try again after sometime or call Client Services at {environment.contactMessage.phone1}, {environment.contactMessage.hours} or email us at {environment.contactMessage.email}',
  4001: 'The information you entered does not match our records. Please check it and try again',
  SSO1000: 'Your request cannot be processed at this time. Please cancel and try again. If the problem persists, please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  SSO1002: 'Your Password cannot be your User ID or one of your 12 most recent passwords or Password Criteria did not match',
  SSO2012: 'The Password you entered does not match our records. Please check it and try again.',
  SSO1003: 'Your password cannot be your Universal ID or one of your 12 recent passwords.',
  SSO3002: 'Legacy User ID and Legacy Application cant be empty',
  //'OTPLocked': 'Your account is locked. Please call Client Services at {environment.contactMessage.phone1}, {environment.contactMessage.hours}, or email us at {environment.contactMessage.email}',
  WrongCredentials: 'The ID and/or Password you entered does not match our records. Please check the information and try again.',
  OTPLimitExceeded: "You have exceeded the maximum number of One-Time Passcodes (OTPs) allowed in a 15-minute time period. Please wait 15 minutes before requesting another OTP or select a different delivery channel. If you didn't receive an OTP, please call your Treasury Solutions Specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.",
  enrollmentAlreadyLinked: 'This credential has already been linked to another Universal ID and can\'t be enrolled again. Please remove this credential from the other Universal profile before enrolling it with new Universal ID.',
  addCredsAlreadyLinked: 'This credential has already been linked to another Universal ID and can\'t be added again. Please remove this credential from the other Universal profile before adding it to this Universal ID.',
  serviceUnavailable: 'Your request cannot be processed at this time. Please cancel and try again. If the problem persists, please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.',
  serviceUnavailableTryAgain: '<b>Service is Unavailable</b> \n\nYour request cannot be processed at this time, please try later.\n\nIf the problem persists, please contact your Treasury Solutions specialist or Client Services at {environment.contactMessage.phone1} {environment.contactMessage.phone2} or email us at {environment.contactMessage.email}, {environment.contactMessage.hours}.'
};
