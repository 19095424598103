import { Component, Input, OnInit } from '@angular/core';
import { assetURL } from 'src/environments/environment';

@Component({
  selector: 'app-sales-portal-dialog',
  templateUrl: './sales-portal-dialog.component.html',
  styleUrls: ['./sales-portal-dialog.component.scss']
})
export class SalesPortalDialogComponent implements OnInit {
  @Input() title;
  @Input() dismiss;
  @Input() cross;

  get assetURL() {
    return assetURL;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
