<div>
  <app-alert></app-alert>
</div>
<div *ngIf="fraudPreventionDialog" #viewContainerRef class="modal-header">
  <h4 class="modal-title fraud-protect">Fraud protection best practices</h4>
</div>
<div *ngIf="!fraudPreventionDialog" #viewContainerRef class="modal-header">
    <svg class="cbds-c-icon warning-icon" focusable="false">
        <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#warning'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#warning'"></use>
      </svg>
    <h4 class="modal-title">To better protect your business, Citizens is moving away from email one-time-passcode and will be utilizing text & voice one-time-passcode only for password reset. 
      Please make sure you have valid phone number(s) in your Universal profile by <span class="bold-text">September 30</span>.</h4>
    <span class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained cbensightenevent" aria-label="close"
          cbdata-type="button" cbdata-reason="message-modal-cross-close" (click)="onCross()">
          <svg class="cbds-c-icon close-icon" focusable="false">
            <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
          </svg>
      </span>
</div>
<div *ngIf="fraudPreventionDialog" class="modal-body justify-content-center">
  <p class="suspcious-text">Suspicious emails and calls have been on the rise over the last few years.</p>
    <ul class="suspcious-text-points">
      <li>
        Make sure your organization is armed to identify suspicious emails with links, attachments or requests to perform an urgent transfers of funds <span>&#8212;</span> even if from known sources.
      </li>
      <li>
        Do not provide any secured information over the phone such as passwords or passcodes as this maybe an attempt to obtain confidential information.
      </li>
      <li>
        Citizens will not call or email you requesting confidential information such as OTP or RSA Token Codes.
      </li>
    </ul>
  

  <div class="buttonArea">
    <a *ngIf="!isMobileApp" [href]="leanrMoreUrl" aria-label="Learn More"
    class="learn-more cbensightenevent" cbdata-reason="Learn More Fraud Popup"
    cbdata-type="link" title="Learn More" target="_blank">
      Learn More
    </a>
    <button (click)="onAcknowledge()" aria-label="Acknowledged"
            cbdata-reason="Acknowledged"
            cbdata-type="button"
            class="btn btn-primary cbensightenevent" id="btnAcknowledge" name="Acknowledged"
            value="Acknowledged">Acknowledged
    </button>
  </div>

</div>
<div *ngIf="!fraudPreventionDialog" class="modal-body justify-content-center">
    <form (ngSubmit)="onSubmit(updateDialogForm.value)" [formGroup]="updateDialogForm" [ngClass]="{ 'was-validated': submitted }"
    novalidate>
<div class="form modal-content update-modal">

  <div [ngClass]="{ 'has-error': f.submitted  && f.mobile.errors }" class="form-row">
    <div class="col-md-auto col-sm-auto">
      <label for="mobile">Mobile Phone</label>
      <div ngbDropdown>
        <button class="toggleBtn widthAuto" id="countryDropdown1" ngbDropdownToggle type="button">
          <span [class]="'fi fi-'+ selectedItem?.countryCode"></span> &nbsp; {{selectedItem?.country}}
        </button>
        <div aria-labelledby="countryDropdown1" ngbDropdownMenu>
          <button (click)="selectedCountry(country, true)" *ngFor="let country of countries"
                  ngbDropdownItem type="button" value={{country.countryCode}}>
            <span [class]="'fi fi-'+ country.countryCode + ' fis'"></span> &nbsp; {{country.country}}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-auto col-sm-auto fakeLabel">
      <input #mobile (keyup)="mobileUpdated()" [attr.maxlength]="isMobileCountryUS() ? 10 : 20" appNumbersOnly
             autocomplete="off" class="form-control widthAuto"
             formControlName="mobile" id="mobile" inputmode="numeric" name="mobile" tabindex="4"
             type="tel">
    </div>
    <div class="verticalCenterPhone">
      <div *ngIf="hasValue(mobile)">
        <div *ngIf="mobileVerified">
          <svg class="sso-alert__checkmark" focusable="false">
            <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'"
                 [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
            </use>
          </svg>
          <span class="verify-text">Verified</span>
        </div>
        <div *ngIf="!mobileVerified">
          <img class="sso-alert_blank phone-test" src="{{assetURL}}assets/icons/gray-circle.svg"/>
          <a (click)="openTest('SMS')" cbdata-reason="update-profile-test-sms" cbdata-type="link"
             class="test-phone cbensightenevent">Test Phone</a>
        </div>
      </div>
    </div>
    <div *ngIf="showMobileOTPError" class="error-message">
      <span>Send OTP code failed. please try again.</span>
    </div>
  </div>

  <div [ngClass]="{ 'has-error': f.submitted  && f.landline.errors }" class="form-row">
    <div class="col-md-auto col-sm-auto">
      <label for="landline">Landline Phone</label>
      <div ngbDropdown>
        <button class="toggleBtn" id="countryDropdown2" ngbDropdownToggle type="button">
          <span [class]="'fi fi-'+ selectedLandline?.countryCode"></span>
          &nbsp; {{selectedLandline?.country}}</button>
        <div aria-labelledby="countryDropdown2" ngbDropdownMenu>
          <button (click)="selectedCountry(country, false)" *ngFor="let country of countries"
                  ngbDropdownItem type="button" value={{country.countryCode}}>
            <span [class]="'fi fi-'+ country.countryCode + ' fis'"></span> &nbsp; {{country.country}}
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-auto col-sm-auto fakeLabel">
      <input #landline (keyup)="landlineUpdated()" [attr.maxlength]="isLandlineCountryUS() ? 10 : 20"
             appNumbersOnly autocomplete="off" class="form-control widthAuto"
             formControlName="landline" id="landline" inputmode="numeric" name="landline" tabindex="4"
             type="tel">
    </div>
    <div class="verticalCenterPhone">
      <div *ngIf="hasValue(landline)">
        <div *ngIf="landlineVerified">
          <svg class="sso-alert__checkmark" focusable="false">
            <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'"
                [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
            </use>
          </svg>
          <span class="verify-text">Verified</span>
        </div>
        <div *ngIf="!landlineVerified">
          <img class="sso-alert_blank phone-test" src="{{assetURL}}assets/icons/gray-circle.svg"/>
          <a (click)="openTest('VOICE')" cbdata-reason="update-profile-test-voice" cbdata-type="link"
            class="test-phone cbensightenevent">Test Phone</a>
        </div>
      </div>
    </div>
    <div *ngIf="showLandlineOTPError" class="error-message">
      <span>Send OTP code failed. please try again.</span>
    </div>
  </div>


  <div class="buttonArea">
    <button #btnLogin [disabled]="loading" aria-label="Next" cbdata-reason="update-profile-save"
            cbdata-type="button"
            class="btn btn-primary cbensightenevent" id="btnLogin" name="btnLogin" tabindex="4"
            type="submit"
            value="Save">Save
    </button>
    <span class="check-box">
      <label class="switch">
        <input type="checkbox" id="remember" name="remember" #remember tabindex="3"
          value="true" (change)="rememberCheck($event.target)">
        <span class="slider round"></span>
      </label>
      <label class="label-remember">Don't show this message again</label>
    </span>
  </div>
</div>
</form>
</div>
<div class="modal-footer justify-content-center">

</div>

  <div [ngStyle]="{'display':displayStyle}" class="modal test-modal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered verify-phone" role="document">
      <div class="modal-content wider">
        <div *ngIf="showPopupAlertMessage" class="popup-alert-message">
          <div class="alertArea">
            <div class="alert-danger">
              <div class="alertHeader">error
                <span (click)="hideAlertArea()" aria-label="close" class="cbds-c-iconButton cbds-c-iconButton--contained">
                <svg class="cbds-c-icon close-icon" focusable="false">
                  <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"
                       [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
                </svg>
              </span>
              </div>
              <span class="alertContent"><div [innerHTML]="alertmessage"></div></span>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="modal-header headSection">
            Test Your Information
            <span (click)="onCancelTest(passcodeForm.value)" aria-label="close"
                  class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained">
            <svg class="cbds-c-icon close-icon" focusable="false">
              <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'">
              </use>
            </svg>
          </span>
          </div>
          <div class="modal-body modal-dialog-scrollable">
            <form (ngSubmit)="onSubmitPasscode(passcodeForm.value)" [formGroup]="passcodeForm"
                  [ngClass]="{ 'was-validated': submitted }" novalidate>
              <div class="form">
                <div class="directions">Your test OTP has been sent to {{ otpTarget }}. Please retrieve it and enter
                  below.</div>
                <div [ngClass]="{ 'has-error': fPasscode.invalid  && fPasscode.passcode.errors }" class="form-row">
                  <div>
                    <label for="passcode">Enter One-Time Passcode</label>
                    <input #passcode (keyup)="hideOTPErrors()" appNumbersOnly autocomplete="off"
                           class="form-control passcode-text" formControlName="passcode" id="passcode" inputmode="numeric"
                           maxlength="6" name="passcode" pattern="[0-9]*"
                           required tabindex="0" type="text" autofocus>
                    <div *ngIf="otpRequired">
                      <div class="error-message">One-Time Passcode is required</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="verifyOTPError" class="error-message">
                  <span>Verify OTP code failed. please try again.</span>
                </div>
                <div *ngIf="resendOTPError" class="error-message">
                  <span>Resend code failed, please try again.</span>
                </div>
                <div *ngIf="displayOTPErrorMessage" class="form-row error-message">
                  The One-Time Passcode (OTP) you entered is incorrect or has expired.
                  <span>Please check the OTP and try again or request another code.</span>
                </div>
  
                <div class="custom-control-text last-row">
                <span>The code expires in 15 minutes.<br>
                  Didn't get the code? <a (click)="getCode()" cbdata-reason="update-profile-otp-resend"
                                          cbdata-type="link"
                                          class="cbensightenevent" href="javascript:void(0);"
                                          tabindex="1">Resend Code</a></span>
                </div>
  
                <div class="buttonArea">
                  <button #btnContinue (click)="verifyOTP()" [disabled]="loading" aria-label="Verify"
                          cbdata-reason="update-profile-otp-verify" cbdata-type="button"
                          class="btn btn-primary cbensightenevent" id="btnContinue" name="btnContinue" tabindex="3"
                          type="submit"
                          value="Verify">Verify
                  </button>
                  <button (click)="onCancelTest(passcodeForm.value)" aria-label="Cancel"
                          cbdata-reason="update-profile-otp-cancel"
                          cbdata-type="button"
                          class="btn btn-secondary cbensightenevent" id="btnBack" name="btnBack"
                          value="Cancel">Cancel
                  </button>
                </div>
              </div>
              <div class="infoArea">
                If you do not receive your test one time passcode, please try a different phone number.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
