<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <span class="cbds-c-iconButton remove-popup cbds-c-iconButton--contained cbensightenevent" aria-label="close"
        cbdata-type="button" cbdata-reason="message-modal-cross-close" (click)="cross('Cross click')">
        <svg class="cbds-c-icon close-icon" focusable="false">
          <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#close'"></use>
        </svg>
    </span>
</div>
<div class="modal-body justify-content-center">
  <ng-content></ng-content>
</div>
<div class="modal-footer justify-content-center">
    <button class="btn btn-primary cbensightenevent" name="btnClose" id="btnClose"  (click)="dismiss('Close click')"
            cbdata-type="button" cbdata-reason="message-modal-close" value="Close" aria-label="Close">Close</button>
</div>
