<div *ngIf="!isStep2 && !isCancel" class="container" >
    <div class="headSection get-started">
      <span>Step {{stepIndex}}c: Verify Your Identity</span>
    </div>
    <form name="form" id="securityForm" [formGroup]="securityForm" [ngClass]="{ 'was-validated': submitted }" (ngSubmit)="onSubmit()" novalidate >
      <div class="form">
        <div [ngClass]="{ 'has-error': f.submitted  && f.errors }">
          <div class="form-label security-text" >For your security, we need to send you a One-Time Passcode (OTP) to verify your identity.<br>
               Please select your preferred delivery method from the contact information we have on file.
          </div>
          </div>
          <div *ngIf="contactEmail" class="form-row form-check radio-selection" >
            <div class="radio radio-inline col-12">
              <input type="radio" #email class="cbensightenevent"
                     cbdata-type="radio" cbdata-reason="add-select-otp-email"
                     formControlName="contactType" name="contactType" id="email" value="email" >
              <label class="email" for="email">Email: {{ contactEmail | textMask }}</label>
           </div>
          </div>
          <div *ngIf="contactMobileText" class="form-row form-check form-info" [ngClass]="{'mt-3': !contactEmail}">
            <div class="radio radio-inline col-12">
              <input type="radio" #text class="cbensightenevent" name="contactType" id="text"
                     cbdata-type="radio" cbdata-reason="add-select-otp-text"
                     formControlName="contactType"  value="text" >
              <label for="text">
                Text: {{ contactMobileText }}
              </label>
              <div class="input-info">Message and data rates may apply.</div>
            </div>
          </div>
          <div *ngIf="contactMobileVoice" class="form-row form-check">
            <div class="radio radio-inline col-12">
              <input type="radio" #voice class="cbensightenevent" name="contactType" id="mobileVoice"
                     cbdata-type="radio" cbdata-reason="add-select-otp-mobile-voice"
                     formControlName="contactType" value="mobileVoice">
              <label for="mobileVoice">
                Voice: {{ contactMobileVoice }}
              </label>
            </div>
          </div>
        <div *ngIf="contactLandVoice" class="form-row form-check">
          <div class="radio radio-inline col-12">
            <input type="radio" #voice class="cbensightenevent" name="contactType" id="landVoice"
                   cbdata-type="radio" cbdata-reason="add-select-otp-land-voice"
                   formControlName="contactType" value="landVoice">
            <label for="landVoice">
              Voice: {{ contactLandVoice }}
            </label>
          </div>
        </div>
        <div class="error-message" *ngIf="showError">
          <span>Send OTP code failed. Please try again.</span>
        </div>
        <div class="buttonArea">
          <button class="btn btn-primary cbensightenevent" name="btnContinue" id="btnContinue" #btnContinue type="submit" tabindex="2"
                  [disabled]="loading" cbdata-type="button" cbdata-reason="add-select-otp-continue" value="Continue" aria-label="Continue"> Continue </button>
          <button tabindex="3" class="btn btn-secondary cbensightenevent"  name="btnBack" id="btnBack" (click)="back()"
                  cbdata-type="button" cbdata-reason="add-select-otp-back" value="Back" aria-label="Back">
            Cancel
          </button>
        </div>
      </div>
    <!--    Does not contain telephone anchors -->
    <div class="need-assistance">
      Need Assistance?
    </div>
      <div class="contactArea d-none d-lg-block">If you don’t have access to the email or phone number listed above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }} {{ contactMessage.phone2 }}
        or email us at <a href="mailto:{{ contactMessage.email }}" class="cbensightenevent" cbdata-type="link" cbdata-reason="add-select-otp-email">
        {{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--    Does contain telephone anchors for ios mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="!isAndroid">If you don’t have access to the email or phone number listed above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at <a href="tel:{{ phoneNumber1 }}" rel="nofollow" class="cbensightenevent" cbdata-type="link" cbdata-reason="add-select-otp-mobile-phone1">{{ contactMessage.phone1 }}</a>&nbsp;
        <a href="tel:{{ phoneNumber2 }}" rel="nofollow" class="cbensightenevent" cbdata-type="link" cbdata-reason="add-select-otp-mobile-phone2">{{ contactMessage.phone2 }}</a>
        or email us at <a href="mailto:{{ contactMessage.email }}" class="cbensightenevent" cbdata-type="link" cbdata-reason="add-select-otp-mobile-email">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--   Plain text for Android mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="isAndroid" >If you don’t have access to the email or phone number listed above, please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }} {{ contactMessage.phone2 }}
        or email us at {{ contactMessage.email }}, {{ contactMessage.hours }}.
      </div>
    </form>
  </div>

<app-ac-select-application *ngIf="isCancel"></app-ac-select-application>
<app-ac-enter-otp *ngIf="isStep2"></app-ac-enter-otp>
