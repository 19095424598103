import { Component, ElementRef, AfterViewInit, ViewChild, OnInit, Input, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { UniversalSessionService } from '../../../core/service/universalSession.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { StepService } from 'src/app/service/shared/step.service';
import { universalErrorCodes } from '../../../transmit/universal/universal-error-codes.config';
import { StepModel } from 'src/app/shared/component/stepper/stepper.component';
import { ApplicationService } from 'src/app/core/service/application.service';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';
import { environment } from 'src/environments/environment';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';

@Component({
  selector: 'app-identity-registration-password',
  templateUrl: './identity-registration-password.component.html',
  styleUrls: ['./identity-registration-password.component.scss']
})
export class IdentityRegistrationPasswordComponent implements OnInit, AfterViewInit {
  @Input() step: StepModel;
  public submitted = false;
  public loading = false;
  public passwordForm: UntypedFormGroup;
  returnUrl: string;
  resetEnabled: boolean;
  public hide = true;
  private companyId: string;
  private userId: string;
  private pageName = 'started';
  private sessionId: string;
  public displayErrorMessage = false;
  stepIndex: number;
  applicationName;
  displayName;
  isStep1 = false;
  isCancel = false;
  private analyticsSessionId = '';
  private isAuthenticated = 'n';
  private analyticsUserId = '';
  public onSubmitPassword: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public onCancel: () => void;
  public isCFF = false;

  @ViewChild('input_password', { read: ElementRef }) firstField: ElementRef;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private sessionService: UniversalSessionService,
    private stepsService: StepService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private applicationService: ApplicationService,
    private analyticsService: UniversalAnalyticsService,
    private transmitUtils: UniversalTransmitUtils,
    private utils: SsoUtils
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.stepsService.getCurrentStep().subscribe(step => {
      this.stepIndex = step.stepIndex;
    });
    this.applicationName = this.stepsService.applicationName;
    this.displayName = this.applicationService.getDisplayName(this.applicationName);
    if (this.applicationName === 'CFF') {
      this.isCFF = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.firstField) {
      this.firstField.nativeElement.focus();
    }
    const data = this.sessionService.getSessionData(this.pageName);
    this.companyId = data.companyId;
    this.userId = data.userId;
    this.sessionId = data.sessionId;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
    this.resetAnalytics();
  }

  createForm() {
    this.passwordForm = this.formBuilder.group({
      input_password: ['', Validators.required]
    });
  }

  get f() {
    return this.passwordForm.controls;
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'enroll',
      cbaction: 'view',
      cbpgFunction: 'verifyidentity',
      cbpgSubFunction: 'password',
      cbpgName: 'view',
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbsessionId: this.analyticsSessionId,
      cbauthenticated: this.isAuthenticated
    };

    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  onSubmit(formData) {
    this.utils.trimFormData(formData);
    this.submitted = true;
    this.alertService.hide();
    if (this.passwordForm.invalid) {
      return;
    } else {

      const password = formData.input_password;

      const inputResponse = com.ts.mobile.sdk.PasswordInput.create(password);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputResponse);

      if (this.onSubmitPassword) {
        this.onSubmitPassword(response);
      }
    }
  }

  public cancel() {
    // actually calling method in password-authenticator-service
    if (this.onCancel) {
      this.onCancel();
      this.alertService.hide();
    }
  }

  showErrorMessage(error?) {
    if (error && universalErrorCodes[error]) {
      this.alertService.error(this.transmitUtils.getErrorMessage(error));
    } else {
      this.alertService.error(this.transmitUtils.getServiceUnavailableErrorMessage());
    }
  }

  displayAkamaiErrorWithReference(reference) {
    let akamaiErrorMessage = this.transmitUtils.getErrorMessage('akamaiError2');
    if (reference) {
      akamaiErrorMessage = akamaiErrorMessage + ' (' + reference + ')';
    }
    this.alertService.error(akamaiErrorMessage);
  }

  // TODO use transmit
  trouble() {
    this.router.navigate(['uportal/trouble']);
  }

  showPasswordLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getErrorMessage('legacyAccountLocked'));
  }

  // TODO
  onClickCancel() {
    this.isCancel = true;
    // this.router.navigate(['/started']);
  }

  isRegistered(appName): boolean {
    return this.applicationService.getIsRegistered(appName);
  }

  isTradeMarked(appName): boolean {
    return this.applicationService.getIsTradeMarked(appName);
  }

}
