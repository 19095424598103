import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout-redirect',
  templateUrl: './logout-redirect.component.html',
  styleUrls: ['./logout-redirect.component.scss'],
})
export class LogoutRedirectComponent implements OnInit {

  logoutUrl = environment.cb_logoutUrl;
  originUrl = environment.fqdns;

  constructor(
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    const headers = new HttpHeaders({
      Origin: this.originUrl
    })
    this.http.get(this.logoutUrl, { headers, responseType: 'blob' }).subscribe(() => {
    },
    (error) => {
      console.log('Error loading png file')
    })
    setTimeout(() => {
      window.close();
    }, 5000)
  }
}
