import { XmSdk } from '../../../transmit2/xm/js-es6/xmsdk-es6.js';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { AlertService } from 'src/app/service/shared/alert.service';
// import { ProcessjsonService } from '../../service/beta/tli-nootp-processjson.service';
import { Router } from '@angular/router';
import { UniversalPasswordAuthenticatorService } from './universal-password-authenticator.service';
import { UniversalOTPAuthenticatorService } from './universal-otp-authenticator-service';
import UniverssalLoginRegistrationForm from './universal-login-registration-form-session';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import ResetPasswordFormSession from './universal-resetpassword-form-session';
import { UniversalPasswordAutopostAuthenticatorService } from './universal-password-autopost-authenticator.service';
import UniversalTemporaryPasswordFormSession from './universal-temporarypassword-form-session';
import { UniversalTransmitUtils } from '../../shared/Util/universal-transmit-utils';
import UniversalProfileUpdateFormSession from './universal-profile-update-form-session';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import UniversalResetPasswordLoginSession from './universal-resetpassword-login-session';
import UniversalOpenEnrollmentSession from './universal-open-enrollment-session';
import { UniversalApiCallsService } from 'src/app/service/universal/universal-api-calls.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../core/service/application.service';

@Injectable()
export class UniversalTransmitUiHandler {

  transmitSDK = XmSdk();
  clientContext = this.transmitSDK.clientContext;

  constructor(private spinnerService: SpinnerService,
              private alertService: AlertService,
              private route: Router,
              private sessionService: UniversalSessionService,
              private transmitUtils: UniversalTransmitUtils,
              private utils: SsoUtils,
              private UniversalApiCallsService: UniversalApiCallsService,
              private appService: ApplicationService) {

  }

  createPasswordAuthSession(title, username) {
    if (this.sessionService.getIsUniversalLoginFlow() == true) {
      this.sessionService.setIsUniversalLoginFlow(false);
      return new UniversalPasswordAutopostAuthenticatorService(title, username, this.route, this.spinnerService,
        this.alertService, this.transmitUtils);
    } else {
      return new UniversalPasswordAuthenticatorService(title, username, this.route, this.spinnerService);
    }
  }

  createOtpAuthSession(title, username, possibleTargets, autoExecedTarget) {
    return new UniversalOTPAuthenticatorService(title, username, possibleTargets, autoExecedTarget, this.spinnerService,
      this.alertService, this.route, this.utils, this.sessionService, this.appService);
  }

  createFormSession(formId: string, payload: object): com.ts.mobile.sdk.UIFormSession {
    switch (formId) {
      case 'univEnrollmentForm':
        return new UniverssalLoginRegistrationForm(formId, payload, this.spinnerService, this.alertService, this.route,
          this.sessionService);
      case 'pwdResetForm':
        return new ResetPasswordFormSession(formId, payload, this.spinnerService, this.alertService, this.route, this.sessionService);
      case 'pwdChangeForm':
        return new UniversalTemporaryPasswordFormSession(formId, payload, this.spinnerService, this.route, this.sessionService);
      case 'updateProfileForm':
        return new UniversalProfileUpdateFormSession(formId, payload, this.spinnerService, this.route);
      case 'pwdResetConfirmationForm':
        return new UniversalResetPasswordLoginSession(formId, payload, this.route, this.spinnerService, this.alertService, this.sessionService)
      case 'enrollmentConfirmationForm':
        const legacyInfo = this.sessionService.getLegacyInfo();
        // if (legacyInfo && legacyInfo['application_name'] && !environment.nonOUDApps.includes(legacyInfo['application_name'])) {
        
        // config
        if (legacyInfo && legacyInfo['application_name'] && !this.appService.getNonOUDAppsList()?.includes(legacyInfo['application_name'])) {
          this.UniversalApiCallsService.saveUserProfile().subscribe(
            res => {console.log(res);}
          );
        }
        return new UniversalOpenEnrollmentSession(formId, payload, this.route, this.spinnerService, this.alertService, this.sessionService);
      default:
        return null;
    }
  }

  startActivityIndicator(actionContext, clientContext) {
    this.spinnerService.showSpinner();
  }

  endActivityIndicator(actionContext, clientContext) {
    this.spinnerService.hideSpinner();
  }

  handlePolicyRejection = function (
    title,
    text,
    buttonText,
    failureData,
    actionContext,
    clientContext
  ) {
    return new Promise((resolve, reject) => {
      const confirmationInput = com.ts.mobile.sdk.ConfirmationInput.create(-1);
      resolve(confirmationInput);
    });
  };

  handlePolicyRedirect = function (
    redirectType,
    policyId,
    userId,
    additionalParameters,
    clientContext
  ) {
    return new Promise((resolve, reject) => {
      const response = com.ts.mobile.sdk.RedirectResponseType.RedirectToPolicy;
      const redirectResponse = com.ts.mobile.sdk.RedirectInput.create(response);
      resolve(redirectResponse);
    });
  };

  processJsonData(jsonData, actionContext, clientContext) {
    return new Promise((resolve, reject) => {
      var jsonFromTransmit = JSON.stringify(jsonData);
      resolve(com.ts.mobile.sdk.JsonDataProcessingResult.create(true));
   });
  }

  controlFlowCancelled = function (clientContext) {
    console.log("control flow cancelled");
  };

  controlFlowStarting = function (clientContext) {
    console.log("control flow starting");
  };

  controlFlowEnded = function (error, clientContext) {
    console.log("control flow ended");
  };

  controlFlowActionStarting = function (
    actionContext,
    clientContextl
  ) {
    console.log("Control flow action starting");
  };

  controlFlowActionEnded = function (
    error,
    actionContext,
    clientContext
  ) {
    console.log("Control flow action starting");
  };

  handleAuthenticatorUnregistration = function (
    authenticatorDescription,
    isPlaceholder,
    actionContext,
    clientContext
  ) {
    console.log("handle authentication unregistration");
  };

  selectAuthenticator = function (
    options,
    actionContext,
    clientContext
  ) {
    console.log("select authenticator");
  };

  selectAuthenticatorFallbackAction = function (
    validOptions,
    fallbackAuthenticator,
    session,
    actionContext,
    clientContext
  ) {
    console.log("select authenticator fallback action");
  };

  controlOptionForCancellationRequestInSession = function (
    validOptions,
    session
  ) {
    console.log("control option for cancellation request in session");
  };

  createPinAuthSession = function (
    title,
    username,
    pinLength
  ) {
    console.log("Pin Authenticator Session");
  };

  createPatternAuthSession = function (
    title,
    username,
    gridWidth,
    gridHeight
  ) {
    console.log("Pattern Authenticator Session");
  };

  createVoiceAuthSession = function (title, username) {
    console.log("Method not implemented.");
  };

  createSecurityQuestionAuthSession = function (
    title,
    username
  ) {
    console.log("create Security Question Auth Session");
  };

  createPlaceholderAuthSession = function (
    placeholderName,
    placeholderType,
    title,
    username,
    authenticatorConfiguredData,
    serverPayload
  ) {
    console.log("Method not implemented by demo application.");
  };

  createFido2AuthSession = function (title, username) {
    console.log("create Fido2 Auth Session");
  };

  getConfirmationInput = function (
    title,
    text,
    continueText,
    cancelText,
    actionContext,
    clientContext
  ) {
    console.log("get Confirmation Input");
  };

  getInformationResponse = function (
    title,
    text,
    continueText,
    actionContext,
    clientContext
  ) {
    console.log("get Information Response");
  };

  createMobileApproveAuthSession = function (
    title,
    username,
    instructions
  ) {
    console.log("create Mobile Approve Auth Session");
  };

  createTicketWaitSession = function (
    actionContext,
    clientContext
  ) {
    console.log("create Ticket Wait Session");
  };

  createTotpAuthSession = function (title, username) {
    console.log("create Totp Auth Session");
  };

  createAuthenticationConfigurationSession = function (
    userId
  ) {
    console.log("create Authentication Configuration Session");
  };

  createRegistrationPromotionSession = function (
    userId,
    actionContext
  ) {
    console.log("create Registration Promotion Session");
  };

  shouldIncludeDisabledAuthenticatorsInMenu = function (
    actionContext,
    clientContext
  ) {
    console.log("should Include Disabled Authenticators In Menu");
  };

  createScanQrSession = function (
    actionContext,
    clientContext
  ) {
    console.log("create Scan Qr Session");
  };

  createFingerprintAuthSession = function (
    title,
    username
  ) {
    console.log("create Fingerprint Auth Session");
  };

  createApprovalsSession = function (userId) {
    console.log("create Approvals Session");
  };

  createTotpGenerationSession = function (
    userId,
    generatorName
  ) {
    console.log("create Totp Generation Session");
  };

  createDeviceManagementSession = function (userId) {
    console.log("create Device Management Session");
  };

  createNativeFaceAuthSession = function (title, username) {
    console.log("create Native Face Auth Session");
  };

  createFaceAuthSession = function (title, username) {
    console.log("create Face Auth Session");
  };

  createDeviceBiometricsAuthSession = function (
    title,
    username
  ) {
    console.log("create Device Biometrics Auth Session");
  };

  localAuthenticatorInvalidated = function (description) {
    console.log("local Authenticator Invalidated");
  };

  setLogEnabled = function (isEnabled) {
    console.log("set Log Enabled");
  };
}