import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityRegistrationPasswordComponent } from 'src/app/universal/component/identity-registration-password/identity-registration-password.component';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { AcEnterPasswordComponent } from 'src/app/credentials/component/ac-enter-password/ac-enter-password.component';

export class UniversalPasswordAuthenticatorService implements com.ts.mobile.sdk.UIAuthenticatorSession<com.ts.mobile.sdk.PasswordInput> {

  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  passwordComponentRef: ComponentRef<IdentityRegistrationPasswordComponent>;
  acPasswordComponentRef: ComponentRef<AcEnterPasswordComponent>;
  clientContext: any;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  isLocked = false;
  currentJourney: string;
  currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  username: string;
  private loginComp: any;

  constructor(title: string, username: string, private route: Router, private spinnerService: SpinnerService) {
    this.username = username;
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
  }

  startSession(description: com.ts.mobile.sdk.AuthenticatorDescription, mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
               actionContext: com.ts.mobile.sdk.PolicyAction | null, clientContext: any | null): void {

    this.clientContext = clientContext;
    this.mode = mode;

    this.viewContainerRef = clientContext.viewContainerRef;
    this.loginComp = clientContext.loginComp;

    this.spinnerService.clearText();

    if (this.loginComp.constructor.name == 'IdentityRegistrationComponent' || this.loginComp.viewContainerRef._hostLView[0].localName === 'app-identity-registration') {
      this.currentJourney = 'enrollment';
    } else if (this.loginComp.constructor.name == 'AcEnterCredentialsComponent' || this.loginComp.viewContainerRef._hostLView[0].localName === 'app-ac-enter-credentials') {
      this.currentJourney = 'addCredentials';
    }

    // remove the login screen
    this.loginComp.setComponentDisplay(false);
    this.renderPasswordComponent();
    this.isLocked = false;
  }

  renderPasswordComponent() {
    if (this.currentJourney == 'enrollment') {
      this.renderEnrollmentPasswordComponent();
    } else if (this.currentJourney == 'addCredentials') {
      this.renderAddCredsPasswordComponent();
    }
  }

  renderEnrollmentPasswordComponent() {
    // clear any previous components added via componentFactory
    this.viewContainerRef.clear();
    const componentFactory = this.resolver.resolveComponentFactory(IdentityRegistrationPasswordComponent);
    this.passwordComponentRef = this.viewContainerRef.createComponent(componentFactory);

    this.passwordComponentRef.instance.onSubmitPassword = this.onSubmitPassword;
    this.passwordComponentRef.instance.onCancel = this.onCancel;
  }

  renderAddCredsPasswordComponent() {
    // clear any previous components added via componentFactory
    this.viewContainerRef.clear();
    const componentFactory = this.resolver.resolveComponentFactory(AcEnterPasswordComponent);
    this.acPasswordComponentRef = this.viewContainerRef.createComponent(componentFactory);

    this.acPasswordComponentRef.instance.onSubmitPassword = this.onSubmitPassword;
    this.acPasswordComponentRef.instance.onCancel = this.onCancelAddCreds;
  }

  public onSubmitPassword = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
    if (this.isLocked) {
      if (this.currentJourney == 'enrollment') {
        this.passwordComponentRef.instance.showPasswordLockedErrorMessage();
      } else if (this.currentJourney == 'addCredentials') {
        this.acPasswordComponentRef.instance.showPasswordLockedErrorMessage();
      }

    }
    this.spinnerService.setText('Verifying Password...');
    this.currentHandler(response);
  }

  public onCancel = () => {
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.AbortAuthentication);
    this.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest));
    this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
      this.route.navigate(['uportal/started']));
  }

  public onCancelAddCreds = () => {
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.AbortAuthentication);
    this.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest));
    this.route.navigateByUrl('/', {replaceUrl: true}).then(() =>
      this.route.navigate(['credentials/manage']));
  }

  public promiseInput = (): Promise<com.ts.mobile.sdk.InputOrControlResponse<any>> => {
    return new Promise((resolve, reject) => {
      this.currentHandler = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
        resolve(response);
      };
    });
  }

  promiseRecoveryForError(error, validRecoveries, defaultRecovery): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {
    console.log("promiseRecoveryForError", error);
    if (this.getNested(error, '_data', 'additional_data', 'locked') == true && this.getNested(error, '_data', 'additional_data', 'type') == 'user-ldap') {
      this.isLocked = true;
    }
    return new Promise((resolve, reject) => {
      if (defaultRecovery === com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator) {

        if (this.getNested(error, '_message') == 'Authentication resulted in failure'){
          this.displayError('WrongCredentials');
        } else if (this.getNested(error, '_data', 'status') == 428){
          this.displayError('akamaiError1');
        } else if (this.getNested(error, '_message') && this.getNested(error, '_message').includes("You don't have permission to access")) {
          let message = this.getNested(error, '_message');
          let referenceToEndOfString = (message) ? message.substring(message.indexOf("Reference")) : undefined;
          let justReference = (referenceToEndOfString) ? referenceToEndOfString.substring(0, referenceToEndOfString.indexOf("</HTML>") - 8): undefined;
          this.displayAkamaiErrorWithReference((justReference) ? justReference.trim() : undefined);
        } else {
          this.displayError();
        }
        resolve(defaultRecovery);
      } else {
        resolve(defaultRecovery);
      }
    });
  }

  displayAkamaiErrorWithReference(reference) {
    if (this.currentJourney == 'enrollment') {
      this.passwordComponentRef.instance.displayAkamaiErrorWithReference(reference);
    } else if (this.currentJourney == 'addCredentials') {
      this.acPasswordComponentRef.instance.displayAkamaiErrorWithReference(reference);
    }
  }

  displayError(errorCode?) {
    if (this.currentJourney == 'enrollment') {
      this.passwordComponentRef.instance.showErrorMessage(errorCode);
    } else if (this.currentJourney == 'addCredentials') {
      this.acPasswordComponentRef.instance.showErrorMessage(errorCode);
    }
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  promiseCancelAction(validOptions, session, clientContext) {
    const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.CancelAuthenticator);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest);
    return Promise.resolve(response);
  }

  changeSessionModeToRegistrationAfterExpiration() {
  }

  endSession() {
  }

}
