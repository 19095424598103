import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './component/alert/alert.component';
import { ModalSpinnerComponent } from './component/modal-spinner/modal-spinner.component';
import { StepperComponent } from './component/stepper/stepper.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ValuesPipe } from './Util/values.pipe';
import { PhoneMaskDirective } from './Util/phone-mask.directive';
import { NumbersOnlyDirective } from './Util/numbers-only.directive';
import { TextMaskPipe } from './Util/text-mask.pipe';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { PasswordtoggleDirective } from './Util/passwordtoggle.directive';
import { DateAgoPipe } from './Util/date-ago.pipe';
import { SsoUtils } from './Util/sso-utils';
import { TransmitUtils } from './Util/transmit-utils';
import { UniversalTransmitUtils } from './Util/universal-transmit-utils';
import { PhoneFormatPipe } from './Util/phone-format.pipe';
import { NgbdSortableHeaderDirective } from './Util/ngbd-sortable-header.directive';
import { AnalyticLinkDirective } from './Util/analytic-link.directive';
import { AnalyticButtonDirective } from './Util/analytic-button.directive';
import { MessageDialogComponent } from './component/message-dialog/message-dialog.component';
import { SalesPortalDialogComponent } from './component/sales-portal-dialog/sales-portal-dialog.component';
import { BetaHeaderComponent } from './component/beta/beta-header/beta-header.component';
import { UpdateProfileDialogComponent } from './component/update-profile-dialog/update-profile-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HtmlSanitizePipe } from './Util/html-sanitize.pipe';
import { TrimInputDirective } from './Util/trim-input.directive';
import { ErpSplashComponent } from './component/erp-splash/erp-splash.component';
import { ParcelModule } from 'single-spa-angular/parcel';
import { LoadingSpinnerComponent } from './component/loading-spinner/loading-spinner.component';


@NgModule({
  declarations: [
    AlertComponent,
    ModalSpinnerComponent,
    StepperComponent,
    ValuesPipe,
    PhoneMaskDirective,
    NumbersOnlyDirective,
    PasswordtoggleDirective,
    TextMaskPipe,
    HeaderComponent,
    FooterComponent,
    DateAgoPipe,
    PhoneFormatPipe,
    NgbdSortableHeaderDirective,
    AnalyticLinkDirective,
    AnalyticButtonDirective,
    MessageDialogComponent,
    SalesPortalDialogComponent,
    BetaHeaderComponent,
    UpdateProfileDialogComponent,
    HtmlSanitizePipe,
    TrimInputDirective,
    ErpSplashComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ParcelModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertComponent,
    ModalSpinnerComponent,
    StepperComponent,
    HeaderComponent,
    FooterComponent,
    ValuesPipe,
    TextMaskPipe,
    PhoneMaskDirective,
    NumbersOnlyDirective,
    PasswordtoggleDirective,
    DateAgoPipe,
    PhoneFormatPipe,
    NgbdSortableHeaderDirective,
    AnalyticLinkDirective,
    AnalyticButtonDirective,
    MessageDialogComponent,
    SalesPortalDialogComponent,
    BetaHeaderComponent,
    UpdateProfileDialogComponent,
    HtmlSanitizePipe,
    TrimInputDirective,
    ErpSplashComponent,
    LoadingSpinnerComponent
  ],
  providers: [
    SsoUtils,
    TransmitUtils,
    UniversalTransmitUtils
  ]
})
export class SharedModule { }