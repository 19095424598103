import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-spinner',
  templateUrl: './modal-spinner.component.html',
  styleUrls: ['./modal-spinner.component.scss']
})
export class ModalSpinnerComponent implements OnInit {

  loading: boolean;
  modalText: string;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.isLoading.subscribe((res) => {
      this.loading = res;
    });
    this.spinnerService.spinnerText.subscribe((res) => {
      this.modalText = res;
    });
   }

  ngOnInit(): void {
    this.spinnerService.hideSpinner();

  }

}
