import { environment } from 'src/environments/environment';

export const universalErrorCodes = {
  3216: `The information you entered does not match our records. Please check it and try again. For additional instructions and tips, please click <a href="${environment.universalLoginQuickGuide}" target="\_blank\">here</a>. If your problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  3209: `The information you entered does not match our records. Please check it and try again. For additional instructions and tips, please click <a href="${environment.universalLoginQuickGuide}" target="\_blank\">here</a>. If your problem persists, please click <a href=\"${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  3211: `The User Account you are trying to access is currently disabled. Please contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} ${environment.contactMessage.hours}.`,
  3215: `Your account is locked. Please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  3220: `Your linked credential is locked. Please contact your Treasury Solutions specialist or Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  3212: `The User Account you are trying to access is currently locked. Please use the Trouble logging in link to reset your password / unlock your account. For detailed instructions, please click <a href="${environment.universalLoginQuickGuide}" target="\_blank\">here</a>. If your problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  3208: `We are unable to process your request due to missing email and phone number. Please have your admin update your contact. If the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  4001: `The information you entered does not match our records. Please check it and try again. For additional instructions and tips, please click <a href="${environment.universalLoginQuickGuide}" target="\_blank\">here</a>. If your problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  3252: `Your request cannot be processed at this time. Please cancel and try again. If the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  SSO1000: `Your request cannot be processed at this time. Please cancel and try again. If the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  SSO1002: `Your Password cannot be your Universal ID or one of your 12 most recent passwords. For additional instructions and tips, please click <a href="${environment.universalLoginQuickGuide}" target="\_blank\">here</a>. If your problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  SSO2012: `The Password you entered does not match our records. Please check it and try again.`,
  WrongCredentials: `The ID and/or Password you entered does not match our records. Please check the information and try again. For detailed instructions, please click <a href="${environment.universalLoginQuickGuide}" target="\_blank\">here</a>. If your problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  OTPLimitExceeded: `You have exceeded the maximum number of One-Time Passcodes (OTPs) allowed in a 15-minute time period. Please wait 15 minutes before requesting another OTP or select a different delivery channel. If you didn’t receive an OTP, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service case or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  enrollmentAlreadyLinked: `This credential has already been linked to another Universal ID and can\`t be enrolled again. Please remove this credential from the other Universal profile before enrolling it with new Universal ID.`,
  addCredsAlreadyLinked: `This credential has already been linked to another Universal ID and can\`t be added again. Please remove this credential from the other Universal profile before adding it to this Universal ID.`,
  serviceUnavailable: `Your request cannot be processed at this time, please clear cache and cookies or switch browsers. If the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  serviceUnavailableTryAgain: `<b>Service is Unavailable</b> \n\nYour request cannot be processed at this time, please clear cache and cookies or switch browsers.\n\nIf the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  legacyAccountLocked: `Your account is locked. Please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  enrollLegacyAccountLocked: `The credential you are trying to enroll is locked. Please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  akamaiError1: `Your request cannot be processed at this time, please clear cache and cookies or switch browsers. If the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  akamaiError2: `Your request cannot be processed at this time, please clear cache and cookies or switch browsers. If the problem persists, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  nonOudCampaignError: `The information you entered does not match our records. Please check it and try again.`,
  nonOUDEnrollError: `This credential has already been linked to another Universal ID and can\`t be enrolled again.`,
  nonOUDAddCredsError: `This credential has already been linked to another Universal ID. Please use the same Universal ID for adding new credential.`,
  nonOUDIncompleteInfo: `We are unable to process your request due to incomplete information available with us.`,
  nonOUDCredentialDisabled: `The User Account you are trying to add is currently disabled. Please contact your Treasury Solutions specialist or Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  displayAppNameError: (appName: string) => `For your security, the credentials you entered cannot be linked to your Universal ID as there is an existing set of credentials for this ${appName} company profile already linked. Please remove the existing credential or <a href="${environment.enrollNowUrl}" target="_blank">Enroll Now</a> for a second Universal ID. Visit Citizens Digital Butler from your Universal Login dashboard to view the Universal Login Learn More page. If you need additional assistance, please click <a href="${environment.universalLoginServiceCase}" target="\_blank\">here</a> to open a Service Case, or contact Client Services at ${environment.contactMessage.phone1} ${environment.contactMessage.phone2} or email us at ${environment.contactMessage.email}, ${environment.contactMessage.hours}.`,
  consentError: `We can't display your connected applications right now, in case you have any. Please try later.`
};