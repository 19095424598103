<div class="container mvp-confirmation">
    <div class="headSection get-started">
      <span>Step {{stepIndex}}: Confirmation</span>
      <span class="print-icon cbensightenevent" cbdata-type="link" cbdata-reason="add-confirmation-print" (click)="onPrint()">
          <svg class="sso-alert__print" focusable="false">
              <use [attr.href]="assetURL+'assets/icons/cbds-icons-brand.svg#printer'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-brand.svg#printer'">
              </use>
          </svg>Print
      </span>
  </div>
    <div class="form-row">
        <div class="col-12">
            <div class="form-label">You've successfully created your Universal Login. <br />
                <span class="universal-id"><svg class="sso-alert__checkmark" focusable="false">
                    <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
                    </use>
                </svg><span class="confirmation-check">Universal ID: {{universalId}} </span><br /></span>
                <svg class="sso-alert__checkmark" focusable="false">
                    <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'" [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#checkmark-circle'">
                    </use>
                </svg><span class="confirmation-check"
                [class.registered]="isRegistered(applicationName)"
                [class.tradeMark]="isTradeMarked(applicationName)">
                Credential linked: {{displayName}}</span>
                <span>{{getLegacyDetails(applicationName)}}</span> <br />
                <span class="confirmation-text">A confirmation email has been sent to you.<br /></span>
                Please note that once you log in to the portal, you’ll be able to link other Commercial Banking <br />
                applications.<br />
                <span class="thanks-text">Thanks for choosing Citizens! </span>
            </div>
        </div>
      </div>
      <div class="buttonArea">
        <button class="btn btn-primary cbensightenevent" cbdata-type="button" cbdata-reason="enroll-confirm-continue"
            name="accept" id="accept" #btnLogin type="submit" tabindex="1"
            value="accept" aria-label="accept" (click)="onLogin()"> Login </button>
      </div>
</div>
