<div id="dashboardContainer">
  <nav class="navbar navbar-expand-lg justify-content-md-between justify-content-between flex-nowrap" id="{{ id }}">
    <span *ngIf="!isMobileApp" class="navbar-brand fixedMargin flex-shrink-1">
      <img alt="Citizens Commercial Banking" src="{{assetURL}}assets/CitizensCommercial_TM_Horz_RGB_HEX.svg">
    </span>
    <span *ngIf="isMobileApp" class="navbar-brand">
      <img alt="Citizens Commercial Banking" src="{{assetURL}}assets/accessOptimaFullImage.png" width="250px">
    </span>
    <ul class="navbar-nav">
      <li class="nav-item float-right">
        <div class="dropdown float-right">
          <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  data-toggle="dropdown" id="userName" type="button">{{ fullName }}
          </button>
          <ul aria-labelledby="userName" class="dropdown-menu dropdown-menu-right">
            <li><a (click)="logout()" (touchend)="logout()" cbdata-reason="dashboard-logout"
                   cbdata-type="link" class="dropdown-item cbensightenevent">Sign out</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>

  <div id="dashboard">
    <main class="content-wrapper">

      <div id="dashboardContent">
        <!-- <div class="row">
          <ul class="ml-auto" id="mobileUser">
            <li>
              <div class="dropdown">
                <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle"
                  data-bs-toggle="dropdown" data-toggle="dropdown" id="mobileUserName" type="button">{{ fullName }}
                </button>
                <ul aria-labelledby="userName" class="dropdown-menu dropdown-menu-right">
                  <li><a (click)="logout()" (touchend)="logout()" cbdata-reason="dashboard-logout" cbdata-type="link"
                      class="dropdown-item cbensightenevent">Sign out</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div> -->

        <div class="row row-cols-1">
          <div class="col">
            <h1 class="banner-title ml-0">Companies Linked:</h1>
          </div>
          <div class="col" *ngFor="let companyNames of userCompanyNamesMap | keyvalue">
            <div class="card justify-content-center">
              <span class="company-ids"> {{ getShortName(companyNames.key)}}</span>

              <button (click)="login('Many', companyNames)" (touchstart)="login('Many', companyNames)" [appAnalyticLink]="companyNames.key" class="login"
                prefix="dashboard-company-login">
                <svg class="sso-login__gray" focusable="false">
                  <use [attr.href]="assetURL+'assets/icons/cbds-icons-ui.svg#login'"
                    [attr.xlink:href]="assetURL+'assets/icons/cbds-icons-ui.svg#login'"></use>
                </svg>
                Select</button>
            </div>
          </div>
        </div>


      </div>
    </main>
  </div>
  <app-footer [isDashboard]="true"></app-footer>
</div>
