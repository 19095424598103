<div *ngIf="!backToSelect && !isCancel" class="parent-container d-sm-flex">
  <div class="container">
    <div class="headSection get-started">
      <span>Step {{stepIndex}}d: Enter One-Time <span class="split-text">Passcode (OTP)</span></span>
    </div>
    <form [formGroup]="passcodeForm" [ngClass]="{ 'was-validated': submitted }"
        (ngSubmit)="onSubmit(passcodeForm.value)" novalidate >
    <div class="form">
      <div class="form-row form-check masked-text">
        <div class="col-12">
            <div class="form-label">Your OTP has been sent to {{targetSelected}}. Please retrieve it and enter below.</div>
        </div>
      </div>
      <div class="form-row" [ngClass]="{ 'has-error': f.submitted  && f.passcode.errors }">
        <div class="col-4 passcode">
          <label for="passcode">Enter One-Time Passcode</label>
          <input type="text" class="form-control passcode-text" autocomplete="off" formControlName="passcode"
           id="passcode" name="passcode" maxlength="6" tabindex="1" pattern="[0-9]*" #passcode appNumbersOnly required >
          <div class="invalid-feedback" id="userid-error-msg">
          <div class="error-message">
            One-Time Passcode (OTP) is required
          </div>
          </div>
        </div>
      </div>
      <div class="form-row error-message" *ngIf="displayOTPErrorMessage">
        <span> The One-Time Passcode (OTP) you entered is incorrect or has expired.<br>
              Please check the OTP and try again or request another code.<br>
              NOTE: the User ID will be locked after 3 unsuccessful attempts.<br>
              Please click <a href="{{universalLoginServiceCase}}" target="_blank">here</a> to open a Service Case, or contact {{ contactMessage.contact2 }} at<br> 
              {{ contactMessage.phone1 }}&#32;{{ contactMessage.phone2 }}
              or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="otp-contact-nonmobile-email"
              target="_blank" href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, <br>
              {{ contactMessage.hours }}.<br>
        </span>
      </div>

      <div class="custom-control-text">
        <span>The code expires in 15 minutes.<br>
        Didn't get the code? <a href="javascript:void(0);" (click)="getCode()">Resend Code</a> | <span class="split-text"><a href="javascript:void(0);" (click)="selectMode()">Select Another Delivery Method</a></span></span>
      </div>

      <div class="form-row error-message resend-otp-error" *ngIf="displayOTPFailedErrorMessage">
        <span>Resend code failed, please try again.</span>
      </div>

      <div class="buttonArea passcodeButton">
        <button class="btn btn-primary cbensightenevent" name="btnLogin" id="btnLogin" #btnLogin type="submit" tabindex=""
        cbdata-type="button" cbdata-reason="enroll-otpPassCode-continue" [disabled]="loading" value="Continue" aria-label="Continue"> Continue </button>
      <button tabindex="3" class="btn btn-secondary cbensightenevent"  name="btnBack" id="btnBack"
      cbdata-type="button" cbdata-reason="enroll-otpPassCode-cancel" (click)="back()" value="Back" aria-label="Back">
        Cancel
      </button>
      </div>
    </div>
    <div class="need-assistance">
      Need Assistance?
    </div>
      <div class="contactArea d-none d-lg-block">If you didn't receive a One-Time Passcode (OTP), please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }} {{contactMessage.phone2}}
        or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="enroll-otpPassCode-nonmobile-email"
        href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--    Does contain telephone anchors for ios mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="!isAndroid">If you didn't receive a One-Time Passcode (OTP), please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="enroll-otpPassCode-mobile-phone1"
        href="tel:{{ phoneNumber1 }}" rel="nofollow">{{ contactMessage.phone1 }}</a>&nbsp;
          <a class="cbensightenevent" cbdata-type="link" cbdata-reason="enroll-otpPassCode-mobile-phone2"
             href="tel:{{ phoneNumber1 }}" rel="nofollow">{{contactMessage.phone2}}</a>
        or email us at <a class="cbensightenevent" cbdata-type="link" cbdata-reason="enroll-otpPassCode-mobile-email"
		href="mailto:{{ contactMessage.email }}">{{ contactMessage.email }}</a>, {{ contactMessage.hours }}.
      </div>
      <!--   Plain text for Android mobile devices -->
      <div class="contactArea d-lg-none" *ngIf="isAndroid" >If you didn't receive a One-Time Passcode (OTP), please contact your {{ contactMessage.contact1 }} or {{ contactMessage.contact2 }}
        at {{ contactMessage.phone1 }} {{contactMessage.phone2}}
        or email us at {{ contactMessage.email }}, {{ contactMessage.hours }}.
      </div>
  </form>
  </div>

</div>

<app-identity-registration-selection *ngIf="backToSelect || isCancel"></app-identity-registration-selection>
<!-- <app-get-started *ngIf="isCancel"></app-get-started> -->
