import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      return; // let it happen, don't do anything
    }

    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }


  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let pastedInput: string;
    let success: boolean;

    if (event.clipboardData && event.clipboardData.getData) {
      pastedInput = event.clipboardData.getData('text/plain');
    } else {
      // @ts-ignore IE11 code
      if (window.clipboardData && window.clipboardData.getData) {
        // @ts-ignore IE11 code
        pastedInput = window.clipboardData.getData('Text');
      }
    }
    const digits = pastedInput.replace(/\D/g, '');   // get a digit-only string
    if (document.queryCommandSupported('insertText')) {
      success = document.execCommand('insertText', false, digits);
    } else {
      success = document.execCommand('paste', false, digits);
    }

    if (success) {
      event.preventDefault();
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    let draggedInput: string;
    let success: boolean;

    if (event.dataTransfer && event.dataTransfer.getData) {
      draggedInput = event.dataTransfer.getData('text');
    }
    const digits = draggedInput.replace(/\D/g, '');   // get a digit-only string
    this.inputElement.focus();

    if (document.queryCommandSupported('insertText')) {
      success = document.execCommand('insertText', false, digits);
    } else {
      success = document.execCommand('paste', false, digits);
    }

    if (success) {
      event.preventDefault();
    }
  }

}
