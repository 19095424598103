import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPasswordtoggle]'
})
export class PasswordtoggleDirective {
  display = false;

  constructor(private el: ElementRef, private renderer2: Renderer2) {}

  ngOnInit() {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this.display = !this.display;
    if (this.display) {
      this.renderer2.setAttribute(this.el.nativeElement, 'type', 'text');
      span.innerHTML = 'Hide';
    } else {
      this.renderer2.setAttribute(this.el.nativeElement, 'type', 'password');
      span.innerHTML = 'Show';
    }
  }

  setup() {
    const parent = this.renderer2.parentNode(this.el.nativeElement);
    if (!parent) {
      return; // If the parent is null or undefined, exit early to avoid errors
    }

    const span = this.renderer2.createElement('span');
    this.renderer2.addClass(span, 'password-toggle');
    span.innerHTML = `Show`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
