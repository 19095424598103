import { AfterViewInit, Component } from '@angular/core';
import { assetURL, environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { AnalyticsService } from 'src/app/service/beta/analytics.service';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';


@Component({
  selector: 'app-otp-not-recieved',
  templateUrl: './otp-not-recieved.component.html'
})
export class OtpNotRecievedComponent implements AfterViewInit {
  email: string;
  mobile: string;
  voice: string;
  noInfoAvailable = false;
  private analyticsSessionId = '';
  private appName = environment.application1;
  public contactMessage: any = environment.contactMessage;
  public isAndroid = false;
  public phoneNumber1: string;
  public phoneNumber2: string;

  get assetURL() {
    return assetURL;
  }

  constructor( private utils: SsoUtils,
               private sessionService: SessionManagementService,
               private analyticsService: AnalyticsService) {
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngAfterViewInit() {
    const data = this.sessionService.getSessionData();
    this.appName = data.appName;
    this.analyticsSessionId = data.analyticsSessionId;
    const analyticsJson = { cbpgType: 'loginassistance',
      cbaction: 'view',
      cbpgFunction: 'contactus',
      cbpgSubFunction: 'otp',
      cbpgName: 'view' };
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  setContactInfo(contactInfo) {
    if (contactInfo && contactInfo.email) {
      this.email = (contactInfo.email).substr(7);
    }
    if (contactInfo && contactInfo.mobile) {
      this.mobile = (contactInfo.mobile).substr(6);
    }
    if (contactInfo && contactInfo.voicePhone) {
      this.voice = (contactInfo.voicePhone).substr(7);
    }
    if (!this.email && !this.mobile && !this.voice) {
      this.noInfoAvailable = true;
    }
  }
}
