export class Credentials {
  lastLogin: string;  // TODO actually a date
  accountLogin: string;
  userId: string;
  compId: string;
  applicationName: string;
  displayName: string;
  isRegistered: boolean;
  isActive: boolean;
  legacyApplicationId: string;
  isAppAdminInd: string;
  legacyUserNm: string;
  disabled: boolean;
  locked: boolean;
  butlerImageUrl?: string;
  personaDetailsExtension: {
    legacyEmailAddress: string;
  }
  customerMobileNum: string;
  emailAddress: string;
  userLegacyUserMapJson: {
    companyName: string;
  };

  constructor(values: object = {}){
    Object.assign(this, values);
  }

  updateLastLogin(date){
    this.lastLogin = date;
  }

  addApplicationAttributes(data){
    if (data) {
      this.displayName = data.displayName;
      this.isRegistered = data.isRegistered;
      this.isActive = data.isActive;
    }
  }

}
