import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';
import { errorCodes } from 'src/error-codes.config';
import { StepService } from 'src/app/service/shared/step.service';
import { StepModel } from '../../../shared/component/stepper/stepper.component';
import { Router } from '@angular/router';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';
import { ApplicationService } from 'src/app/core/service/application.service';
import { datadogLogs } from '@datadog/browser-logs';
import { Const } from 'src/app/core/enums/const.enum';
import { AlertService } from 'src/app/service/shared/alert.service';
import { assetURL } from 'src/environments/environment';

@Component({
  selector: 'app-mvp-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class MvpConfirmationComponent implements OnInit, AfterViewInit {
  @Input() step: StepModel;
  stepIndex;
  currentStepSub: Subscription;
  currentStep: StepModel;
  applicationName: string;
  universalId: string;
  companyId: string;
  userId: string;
  displayName: string;
  companyName: string;
  email: string;
  pageName = 'confirm';
  analyticsSessionId = '';
  analyticsUserId = '';
  isAuthenticated = 'n';
  public onLoginClick: () => void;

  // config
  isNonOUDApp = false;

  get assetURL() {
    return assetURL;
  }

  constructor(
    private stepsService: StepService,
    private router: Router,
    private transmitUtils: TransmitUtils,
    private alertService: AlertService,
    private analyticsService: UniversalAnalyticsService,
    private sessionService: UniversalSessionService,
    private applicationService: ApplicationService
  ) {

    const data = this.sessionService.getSessionData(this.pageName);
    this.pageName = data.pageName;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
    this.stepsService.moveToNextStep();
  }

  ngOnInit(): void {
    this.stepsService.getCurrentStep().subscribe(step => {
      this.stepIndex = step.stepIndex;
    });
    const legacyInfo = this.sessionService.getLegacyInfo();
    const universalInfo = this.sessionService.getUniversalInfo();

    this.applicationName = this.stepsService.applicationName;
    this.displayName = this.applicationService.getDisplayName(this.applicationName);
    this.userId = legacyInfo['user_id'];
    this.companyId = legacyInfo['company_id'];
    this.universalId = universalInfo['universal_id'];
    this.companyName = legacyInfo['companyName'];
    this.email = legacyInfo['email'];

    if (this.applicationService.getNonOUDAppsList().includes(this.applicationName)) {
      this.isNonOUDApp = true;
    }

    datadogLogs.logger.info('confirmation page -- enrollment', { applicationName: this.applicationName, userId: this.userId, companyId: this.companyId, universalId: this.universalId });
    this.sessionService.setFlowComplete(true);
  }

  ngAfterViewInit() {
    this.resetAnalytics();
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'enroll',
      cbaction: 'complete',
      cbpgFunction: 'confirmation',
      cbpgSubFunction: '',
      cbpgName: 'complete',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };

    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, analyticsJson);
    } else {
      this.analyticsService.setAccType('');
      this.analyticsService.setOtpUsed('');
      this.analyticsService.setOptOption('');
      this.analyticsService.updateAnalytics(analyticsJson);
    }
  }

  getLegacyDetails(appName) {
    // config
    if (this.isNonOUDApp && this.applicationService.getNonOUDAppsList().includes(appName)) {
      return ', ' + ((this.companyName && this.companyName !== 'N/A') ? (this.companyName + ', ') : (this.companyId ? this.companyId + ', ' : '')) + this.userId;
    } else {
      return ', ' + (((this.companyId == '') || (this.companyId == undefined)) ? (' ' + this.userId) : (`${this.companyId}, ${this.userId}`));
    }
  }

  onLogin() {
    this.onLoginClick();
  }

  onPrint() {
    window.print();
  }

  isRegistered(appName: any): boolean {
    return this.applicationService.getIsRegistered(appName);
  }

  isTradeMarked(appName: any): boolean {
    return this.applicationService.getIsTradeMarked(appName);
  }

  showTransmitErrorMessage(error: any) {
    if (error && (error.code == 'SSO3212')) {
      this.alertService.error(this.transmitUtils.getPasswordLockedErrorMessage());
    } else if (error && (error.code == 'SSO1002')) {
      const transmitErrorMessage = errorCodes[error.code];
      this.alertService.error(transmitErrorMessage);
    } else {
      this.sessionService.setErrorDescription({ description: 'Transmit is unavailable', code: error.code });
      this.router.navigate(['unavailable']);
    }
  }

}
