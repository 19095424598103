import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/Util/custom-validators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from 'src/app/service/shared/alert.service';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';
import { errorCodes } from 'src/error-codes.config';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';
import { AnalyticsService } from 'src/app/service/beta/analytics.service';
import { assetURL, environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html'
})
export class PasswordRulesComponent implements AfterViewInit {
  resetPasscodeForm: UntypedFormGroup;
  submitted = false;
  private appName = environment.application1;
  loading = false;
  companyID: string;
  private customerId: '';
  userId: string;
  private analyticsSessionId = '';
  pageName: string;
  passwordsDoNotMatch = false;
  passwordPolicyNotMet = false;
  @ViewChild('passtext') passwordField: ElementRef;
  public onSubmitPassword: (passtext: string) => void;
  public onCancel: (appName: string) => void;

  get assetURL() {
    return assetURL;
  }

  constructor(private route: ActivatedRoute,
              private fb: UntypedFormBuilder, private router: Router,
              private alertService: AlertService,
              private utils: SsoUtils,
              private transmitUtils: TransmitUtils,
              private analyticsService: AnalyticsService,
              private sessionMgmtService: SessionManagementService) {
    this.resetPasscodeForm = this.createForm();
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.passwordField.nativeElement.focus();
    }
    const data = this.sessionMgmtService.getSessionData();
    this.appName = data.appName;
    this.companyID = data.companyId;
    this.userId = data.userId;
    this.pageName = 'password';
    this.analyticsSessionId = data.analyticsSessionId;
    this.customerId = data.customerId;

    const analyticsJson = { cbpgType: 'resetpass',
        cbaction: 'start',
        cbpgFunction: 'resetpassword',
        cbpgSubFunction: '',
        cbpgName: 'start',
        cbsessionId: this.analyticsSessionId,
        cbCisKey: ''  // TODO: custumerid to be added on MVP
    };
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  createForm(): UntypedFormGroup {
    this.resetPasscodeForm = this.fb.group({
      passtext: ['', [Validators.required,
      CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[a-z].*/i, { hasOneAlpha: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8), Validators.maxLength(24)]
      ],
      confirmPassword: ['', [Validators.required]]
    });

    return this.resetPasscodeForm;
  }

  get f() {
    return this.resetPasscodeForm.controls;
  }

  onSubmit(formData) {
    this.submitted = true;
    this.alertService.hide();

    if (this.resetPasscodeForm.invalid) {
      if (this.resetPasscodeForm.controls.passtext.value !== '' &&
          !CustomValidators.strongPassword(this.resetPasscodeForm.controls.passtext).strong) {
        this.passwordPolicyNotMet = true;
      }
      return;
    } else if (formData.passtext !== formData.confirmPassword) {
      this.passwordsDoNotMatch = true;
      return;
    }

    this.onSubmitPassword(this.f.passtext.value);
  }

  hidePasswordPolicyErrors() {
    this.passwordPolicyNotMet = false;
    this.passwordsDoNotMatch = false;
  }

  back(evt) {
    this.sessionMgmtService.cancelSessions();
    evt.preventDefault();
    // this.router.navigate([`login/${this.appName}`]);
    this.onCancel(this.appName);
  }

  public showTransmitErrorMessage(errorCode: string) {
    let errorMessage = errorCodes[errorCode];
    if (errorMessage) {
      errorMessage = this.transmitUtils.replaceParams(errorMessage, this.transmitUtils.getContactMessageParamsMap());
      this.alertService.error(errorMessage);
    } else {
      this.sessionMgmtService.setErrorDescription({ description: 'Transmit is unavailable', code: errorCode });
      this.router.navigate(['unavailable']);
    }
  }
}
