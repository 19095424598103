<div *ngIf="!isStep1 && !isCancel" class="parent-container d-sm-flex">
  <div class="container">
    <div class="headSection get-started">
      <span> Step {{stepIndex}}b: Verify Your Identity </span>
    </div>
    <form [formGroup]="passwordForm" [ngClass]="{ 'was-validated': submitted }" (keyup.enter)="onSubmit(passwordForm.value)"
          (ngSubmit)="onSubmit(passwordForm.value)" novalidate >
      <div class="form">
          <div class="form-row form-check masked-text">
              <div class="col-12">
                  <div class="form-label">Next, enter your password.</div>
              </div>
            </div>

            <div class="headSection appName">
              <span
              [class.registered]="isRegistered(applicationName)"
                            [class.tradeMark]="isTradeMarked(applicationName)">{{displayName}}</span>
            </div>
        <div class="form-row" [ngClass]="{ 'has-error': f.submitted  && f.password.errors }">
          <div class="col-xl-4 col-md-5 col-sm-7">
            <label for="password">Password</label>
            <div class="input-group password">
              <input type="password" class="form-control" formControlName="input_password" maxlength="24" autocomplete="off"
                     id="input_password" name="input_password" tabindex="4" #input_password appPasswordtoggle required >
              <div class="invalid-feedback" id="userid-error-msg">
                <div class="error-message">
                  Password is required
                </div>
              </div>
            </div>
          </div>
          <div class="error-message" *ngIf="displayErrorMessage" >The Password you entered does not match our records.
            <span>Please check it and try again.</span>
          </div>
        </div>
        <div class="buttonArea">
          <button class="btn btn-primary cbensightenevent" name="custom-password-authenticator-continue-button" id="custom-password-authenticator-continue-button" type="submit"
          cbdata-type="button" cbdata-reason="enroll-registrationPassword-continue" tabindex="6" [disabled]="loading" value="Continue" aria-label="Continue"> Continue </button>
          <button type="button" tabindex="7" class="btn btn-secondary cbensightenevent" id="custom-password-authenticator-cancel-button"
          cbdata-type="button" cbdata-reason="enroll-registrationPassword-cancel" (click)="cancel()" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-identity-registration-selection *ngIf="isStep1" [step]="step"></app-identity-registration-selection>
<!-- <app-get-started *ngIf="isCancel"></app-get-started> -->
