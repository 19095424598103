import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { appUrls } from 'src/error-msg.config';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(private route: ActivatedRoute) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const status = error.status;
          let errorMessage = '';
          switch (status) {
            case 401: {
              if ((error.error) && (this.getNested(error.error, 'error_code') == '4001')) { // transmit returns 4001
                errorMessage = this.getNested(error.error, 'data', 'failure_data', 'reason', 'data');
              } else if ((error.error) && (error.error?.message)) {
                errorMessage = `${(error.error?.message)}`;
              } else {
                errorMessage = `Error Status: ${error.status} \n Message: ${error.message}`;
              }
              break;
            }
            case 404: {
              errorMessage = `${error.error?.message}`;
              break;
            }
            case 403: {
              errorMessage = `Access Denied: ${error.message}`;
              break;
            }
            case 500: {
              errorMessage = `Internal Server Error: ${error.message}`;
              break;
            }
            default: {
              errorMessage = `Error Status: ${error.status} \n Message: ${error.message}`;
            }
          }
          return throwError(errorMessage);
        })
      );
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }
}
