import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {
  // This pipe is to extract the values of key value in an object

  transform(value: any, args: any[] = null): any {
    if (value === null || value === undefined) {
      return [];
    }
    return Object.keys(value).map(key => value[key]);
  }

}
