import { Component } from '@angular/core';
import { SsoUtils } from './shared/Util/sso-utils';
import { environment} from 'src/environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {
  public cancelUrlAchieve: any = environment.defaultCancelUrl;
  public header: any = environment.header;
  public footer: any = environment.footer;
  public copyright: any = environment.footerCopyright;

  constructor(private utils: SsoUtils) {
  }

  Back(evt) {
    evt.preventDefault();
    const URL = this.cancelUrlAchieve;
    if (URL !== '') {
      this.utils.redirect(URL);
    }
  }

}
