import { ComponentRef } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SecurityComponent } from 'src/app/beta/component/security/security.component';
import { OneTimePasscodeComponent } from 'src/app/beta/component/one-time-passcode/one-time-passcode.component';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { AlertService } from 'src/app/service/shared/alert.service';
import { Router } from '@angular/router';

export class OTPAuthenticatorService implements com.ts.mobile.sdk.UIAuthenticatorSessionOtp {

    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    clientContext: any;
    actionContext: any;
    mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
    otpErrorDisplay = false;
    resendOTPFailed = false;
    targetSelectionErrorDisplay = false;
    OTPLimitExceeded = false;
    currentComponent: string;
    currentOTPStatus = '';
    isOTPLocked = false;

    // loginElementRef: ElementRef;
    securityRef: ComponentRef<SecurityComponent>;
    passcodeRef: ComponentRef<OneTimePasscodeComponent>;
    private renderer: Renderer2;

    targets: Array<com.ts.mobile.sdk.AuthenticatorTarget> = [];
    targetSelected: com.ts.mobile.sdk.AuthenticatorTarget;
    private loginComp: any;

    currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
    username: string;
    possibleTargets: any[];

    constructor(title: string, username: string, possibleTargets: Array<com.ts.mobile.sdk.OtpTarget>,
                autoExecedTarget: com.ts.mobile.sdk.OtpTarget | null,
                private spinnerService: SpinnerService,
                private alertService: AlertService,
                private route: Router) {
        this.username = username;
        this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
        this.possibleTargets = possibleTargets;
    }

    startSession(description: com.ts.mobile.sdk.AuthenticatorDescription,
                 mode: com.ts.mobile.sdk.AuthenticatorSessionMode,
                 actionContext: com.ts.mobile.sdk.PolicyAction,
                 clientContext: object): void {
        this.clientContext = clientContext;
        this.actionContext = actionContext;
        this.mode = mode;
        this.isOTPLocked = false;

        this.viewContainerRef = (clientContext as any).viewContainerRef;
        this.renderer = (clientContext as any).renderer;

        this.loginComp = clientContext['loginComp'];

        this.spinnerService.clearText();

        this.loginComp.setComponentDisplay(false);
    }

    renderSecurityComponent() {
        this.currentComponent = 'securityComponent';
        this.viewContainerRef.clear();

        const componentFactory = this.resolver.resolveComponentFactory(SecurityComponent);
        this.securityRef = this.viewContainerRef.createComponent(componentFactory);

        this.securityRef.instance.setTargets(this.possibleTargets);
        this.securityRef.instance.onTargetSelected = this.onTargetSelected;
        this.securityRef.instance.onCancel = this.onCancel;

        if (this.OTPLimitExceeded) {
            this.hideAllErrors();
            this.securityRef.instance.showOTPLimitExceeded();
        }

        if (this.targetSelectionErrorDisplay) {
            this.securityRef.instance.showTargetSelectionErrorMessage();
        }

        this.targetSelectionErrorDisplay = false;
        this.OTPLimitExceeded = false;
    }

    hideAllErrors() {
        this.otpErrorDisplay = false;
        this.resendOTPFailed = false;
        this.targetSelectionErrorDisplay = false;
    }

    setGeneratedOtp(format: com.ts.mobile.sdk.OtpFormat, target: com.ts.mobile.sdk.OtpTarget): void {
        if (format && target) {
            this.renderWaitingForInput(format, target);
        } else {
            this.renderSecurityComponent();
        }
    }

    setAvailableTargets(targets: com.ts.mobile.sdk.OtpTarget[]): void {
        this.targets = targets || [];
    }

    public onCancel = (appName: string) => {
        const controlRequest = com.ts.mobile.sdk.ControlRequest.create(com.ts.mobile.sdk.ControlRequestType.AbortAuthentication);
        this.currentHandler(com.ts.mobile.sdk.InputOrControlResponse.createControlResponse(controlRequest));
        this.route.navigateByUrl('/', { replaceUrl: true }).then(() =>
            this.route.navigate([`cancel/${appName}`]));
    }

    private onTargetSelected = (target: com.ts.mobile.sdk.AuthenticatorTarget): void => {
        this.targetSelected = target;
        const input = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createTargetSelectionRequest(target);
        const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(input);
        if (this.targetSelected['_channel'] == 4) {
            this.spinnerService.setText("We're calling your phone...");
        } else {
            this.spinnerService.setText('Sending One-Time Passcode (OTP)...');
        }
        this.currentHandler(response);
    }

    private renderWaitingForInput = (format: com.ts.mobile.sdk.OtpFormat | null, target: com.ts.mobile.sdk.OtpTarget | null) => {
        this.currentComponent = 'passcodeComponent';
        this.viewContainerRef.clear();
        const componentFactory = this.resolver.resolveComponentFactory(OneTimePasscodeComponent);
        this.passcodeRef = this.viewContainerRef.createComponent(componentFactory);
        this.passcodeRef.instance.getTarget(target);
        this.passcodeRef.instance.onSubmitCode = this.onSubmitCode;
        this.passcodeRef.instance.resendCode = this.resendCode;
        this.passcodeRef.instance.changeMode = this.changeOTPDeliveryMethod;
        this.passcodeRef.instance.onCancel = this.onCancel;
        if (this.otpErrorDisplay) {
            this.passcodeRef.instance.showOTPErrorMessage();
        }
        if (this.resendOTPFailed) {
            this.passcodeRef.instance.showOTPResendFailedErrorMessage();
        }
        this.otpErrorDisplay = false;
        this.resendOTPFailed = false;
        this.currentOTPStatus = '';
    }

    private changeOTPDeliveryMethod = () => {
        this.spinnerService.clearText();
        if (this.viewContainerRef.length < 1) return;
        this.viewContainerRef.remove(0);
        this.renderSecurityComponent();
    }

    private resendCode = () => {
        this.currentOTPStatus = 'resendOTP';
        if (this.targetSelected['_channel'] == 4) {
            this.spinnerService.setText("We're calling your phone...");
        } else {
            this.spinnerService.setText('Sending One-Time Passcode (OTP)...');
        }
        const resend = com.ts.mobile.sdk.OtpInputRequestResend.createOtpResendRequest();
        const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(resend);
        const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);
        this.currentHandler(response);
    }

    private onSubmitCode = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
        if (this.isOTPLocked) {
            this.passcodeRef.instance.showOTPLockedErrorMessage();
        }

        this.spinnerService.setText('Verifying One-Time Passcode (OTP)...');
        this.currentOTPStatus = 'OTPSubmitted';
        this.currentHandler(response);
    }

    changeSessionModeToRegistrationAfterExpiration(): void {
        throw new Error('Method not implemented.');
    }

    promiseRecoveryForError(error, validRecoveries, defaultRecovery): Promise<com.ts.mobile.sdk.AuthenticationErrorRecovery> {

        if (this.getNested(error, '_data', 'additional_data', 'locked') == true && this.getNested(error, '_data', 'additional_data', 'type') !== 'user-ldap') {
            this.isOTPLocked = true;
        }

        const errormessage = error['_message'];
        if (errormessage && errormessage.includes('exceeded the maximum allowable per time period')) {
            this.OTPLimitExceeded = true;
        }

        return new Promise((resolve, reject) => {
            if (defaultRecovery === com.ts.mobile.sdk.AuthenticationErrorRecovery.RetryAuthenticator) {
                resolve(defaultRecovery);
                if (this.currentComponent == 'passcodeComponent') {
                    if (this.currentOTPStatus == 'OTPSubmitted') {
                        this.otpErrorDisplay = true;
                    } else if (this.currentOTPStatus == 'resendOTP') {
                        this.resendOTPFailed = true;
                    }
                } else if (this.currentComponent == 'securityComponent') {
                    this.targetSelectionErrorDisplay = true;
                }
            } else {
                resolve(defaultRecovery);
            }
        });
    }

    getNested(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj);
      }

    promiseInput(): Promise<com.ts.mobile.sdk.InputOrControlResponse<com.ts.mobile.sdk.TargetBasedAuthenticatorInput<com.ts.mobile.sdk.OtpInput, com.ts.mobile.sdk.OtpTarget>>> {
        return new Promise((resolve, reject) => {
            this.currentHandler = (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => {
                resolve(response);
            };
        });
    }

    endSession(): void {
    }
}
