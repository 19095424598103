<div class="parent-container d-sm-flex">
    <div class="container">
      <div class="headSection">
        <span>Confirmation</span>
      </div>
      <div class="textExplanation col10">You have successfully unlocked your Universal Login and reset your password.</div>
      <div class="buttonArea">
        <button class="btn btn-primary cbensightenevent" cbdata-type="button" cbdata-reason="confirm-unlock-continue"
                name="accept" id="accept" #btnLogin type="submit" tabindex="1"
                [disabled]="loading" value="accept" aria-label="accept" (click)="onContinue()"> Continue </button>
      </div>
    </div>
</div>
