import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UniversalApiCallsService } from 'src/app/service/universal/universal-api-calls.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public footer: any = environment.footer;
  public copyright: any = environment.footerCopyright;
  public disclaimer: any = environment.footerDisclaimer;

  public year = new Date().getFullYear();
  @Input() isDashboard = false;
  public butlerApp = false;
  public bgColor = '#dad9d7';
  public textAlign: string;
  public isSMBUser = false;
  public footerCompanyLinks: any;
  public footerResourcesLinks: any;
  public footerBottomLinks: any;
  footerBottomFirstLinks: any;
  footerBottomSecondLinks: any;
  isLoginRoute: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private universalApiCallsService: UniversalApiCallsService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLoginRoute = event.urlAfterRedirects.includes('login')
      }
    })
   }

  ngOnInit() {
    this.checkForSmbUser()
    
    this.universalApiCallsService.getFooterLinksFromCDN().subscribe((res: any) => {
      this.footerCompanyLinks = res.footerCompanyLinks;
      console.log(this.footerCompanyLinks)
      this.footerResourcesLinks = res.footerResourcesLinks;
      this.footerBottomLinks = res.footerBottomLinks;
      this.footerBottomFirstLinks = res.footerBottomLinks.slice(0,3);
      this.footerBottomSecondLinks = res.footerBottomLinks.slice(3);
    })

    if (sessionStorage.getItem('butler') == 'true') {
      this.butlerApp = true;
      this.bgColor = '#FFFFFF';
      this.textAlign = 'center';
    } else {
      this.butlerApp = false;
      this.bgColor = '#dad9d7';
    }
  }

  selectCompanyLink(url: string): void {
    window.location.href = url
  }

  checkForSmbUser() {
    if (sessionStorage.getItem('isSMBUser') == 'true') {
      this.isSMBUser = true;      
    } else {
      this.isSMBUser = false;
    }
  }
}
