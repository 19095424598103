import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/shared/Util/custom-validators';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { universalErrorCodes } from '../../../transmit/universal/universal-error-codes.config';
import { AlertService } from 'src/app/service/shared/alert.service';
import { UniversalSessionService } from '../../../core/service/universalSession.service';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';
import { assetURL, environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';

@Component({
  selector: 'app-temporary-password',
  templateUrl: './temporary-password.component.html'
})
export class TemporaryPasswordComponent implements OnInit, AfterViewInit {
  resetPasscodeForm: UntypedFormGroup;
  submitted = false;
  appName = environment.application1;
  loading = false;
  application: string;
  companyID: string;
  userId: string;
  // tmxSessionId: string;
  pageName = 'temp';
  showTransmitError = false;
  transmitError: string;
  passwordsDoNotMatch = false;
  passwordPolicyNotMet = false;
  specialCharacterNotMet = false;
  specialCharacterNotMetConfirmPass = false;
  analyticsSessionId = '';
  analyticsUserId = '';
  isAuthenticated = 'n';

  @ViewChild('passtext') passwordField: ElementRef;
  // passwordField: ElementRef<HTMLInputElement> | undefined;
  public onSubmitPassword: (newPassword: string) => void;
  public onCancelUniversalTmpPw: () => void;
  public contactMessage: any = environment.contactMessage;
  public phoneNumber1: string;
  public phoneNumber2: string;
  public universalLoginServiceCase = environment.universalLoginServiceCase;
  public universalLoginQuickGuide = environment.universalLoginQuickGuide;

  get assetURL() {
    return assetURL;
  }

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private alertService: AlertService,
              private utils: SsoUtils,
              private analyticsService: UniversalAnalyticsService,
              private sessionService: UniversalSessionService,
              private transmitUtils: UniversalTransmitUtils) {
    this.resetPasscodeForm = this.createForm();
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
  }

  ngOnInit(): void {
    const data = this.sessionService.getSessionData(this.pageName);
    this.pageName = data.pageName;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.passwordField.nativeElement.focus();
    }
    this.resetAnalytics();
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'temppass',
      cbaction: 'start',
      cbpgFunction: 'resetpassword',
      cbpgSubFunction: '',
      cbpgName: 'start',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }

  createForm(): UntypedFormGroup {
    this.resetPasscodeForm = this.fb.group({
      // temporary: ['', [Validators.required]],
      passtext: ['', [Validators.required,
        CustomValidators.patternValidator(/\d/, {hasNumber: true}),
        CustomValidators.patternValidator(/[a-z].*/i, {hasOneAlpha: true}),
        CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
        Validators.minLength(8), Validators.maxLength(24)]
      ],
      confirmPassword: ['', [Validators.required]]
    });
    return this.resetPasscodeForm;
  }

  get f() {
    return this.resetPasscodeForm.controls;
  }

  checkSpecialChar(event: any){
    const re = new RegExp(/["',-./:;<=>?\s\[\]\\`{|}]/);
    return event.target.value.match(re) ? true : false;
  }

  checkSpecialCharForPassword(event: any){
    this.specialCharacterNotMet = this.checkSpecialChar(event);
  }

  checkSpecialCharForConfirmPassword(event: any){
    this.specialCharacterNotMetConfirmPass = this.checkSpecialChar(event);
  }

  onSubmit(formData) {
    this.submitted = true;
    this.hideTransmitMessage();
    this.alertService.hide();
    const controls = this.resetPasscodeForm.controls;
    if (this.resetPasscodeForm.invalid) {
      if (controls.passtext.value !== '' && !CustomValidators.universalStrongPassword(controls.passtext).strong) {
        this.passwordPolicyNotMet = true;
      }
      return;
    } else if (formData.passtext !== formData.confirmPassword) {
      this.passwordsDoNotMatch = true;
      return;
    }
    this.onSubmitPassword(this.f.passtext.value);
    // this.router.navigate(['uportal/confirm']);
  }


  showTransmitMessage() {
    this.showTransmitError = true;
  }

  hideTransmitMessage() {
    this.showTransmitError = false;
  }

  hidePasswordPolicyErrors(event) {
    if(event.which !== 13){
      this.passwordPolicyNotMet = false;
      this.passwordsDoNotMatch = false;
    }
  }

  back(evt) {
    this.sessionService.cancelSessions();
    evt.preventDefault();
    this.onCancelUniversalTmpPw();
  }

  showTransmitErrorMessage(error: any) {
    if (error && (error.code === 'SSO3212')) {
      this.alertService.error(this.transmitUtils.getPasswordLockedErrorMessage());
    } else if (error && (error.code === 'SSO2012')) {
      this.showTransmitMessage();
    } else if (error && (error.code === 'SSO1002')) {
      const transmitErrorMessage = universalErrorCodes[error.code];
      this.alertService.error(transmitErrorMessage);
    } else if (error && error['code'] == 'UNK9999') {
      this.alertService.error('Password does not meet the Security Criteria, Please try again');
    } else {
      this.sessionService.setErrorDescription({ description: 'Transmit is unavailable', code: error.code });
      this.router.navigate(['unavailable']);
    }
  }

}
