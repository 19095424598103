import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { StepService } from 'src/app/service/shared/step.service';
import { StepModel } from 'src/app/shared/component/stepper/stepper.component';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { UniversalTransmitUtils } from '../../../shared/Util/universal-transmit-utils';
import { environment } from 'src/environments/environment';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { UniversalAnalyticsService } from 'src/app/core/service/universalAnalytics.service';

@Component({
  selector: 'app-ac-enter-otp',
  templateUrl: './ac-enter-otp.component.html',
  styleUrls: ['./ac-enter-otp.component.scss']
})
export class AcEnterOtpComponent implements OnInit, AfterViewInit {

  @Input() step: StepModel;
  submitted = false;
  loading = false;
  appName = environment.application1;
  model = {};
  returnUrl: string;
  resetEnabled = false;
  passcodeForm: UntypedFormGroup;
  modalText = '';
  showModal = false;

  public contactMessage: any = environment.contactMessage;
  public isAndroid = false;
  public phoneNumber1: string;
  public phoneNumber2: string;

  public displayOTPErrorMessage = false;
  public displayOTPFailedErrorMessage = false;

  public onSubmitCode: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public resendCode: () => void;
  public changeMode: () => void;
  public onCancel: (applicationName: string) => void;
  public universalLoginServiceCase = environment.universalLoginServiceCase;

  private pageName = 'addCredential';
  private customerId: string;
  private analyticsSessionId = '';
  private analyticsUserId = '';
  private isAuthenticated = 'n';

  backToSelect = false;
  isCancel = false;
  stepIndex;
  targetSelected: string;
  applicationName: string;

  @ViewChild('passcode', { read: ElementRef }) firstField: ElementRef;
  constructor(private router: Router,
              private formBuilder: UntypedFormBuilder,
              private stepsService: StepService,
              private utils: SsoUtils,
              private alertService: AlertService,
              private transmitUtils: UniversalTransmitUtils,
              private sessionService: UniversalSessionService,
              private analyticsService: UniversalAnalyticsService ) {
    this.createForm();
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngOnInit(): void {
    this.stepsService.getCurrentStep().subscribe(step => {
      this.stepIndex = step.stepIndex;
    });

    // Initialize analytics
    const data = this.sessionService.getSessionData(this.pageName);
    this.customerId = data.customerId;
    this.analyticsSessionId = data.analyticsSessionId;
    this.analyticsUserId = data.analyticsUserId;
    this.isAuthenticated = data.isAuthenticated;
    this.applicationName = this.stepsService.applicationName;
  }

  ngAfterViewInit(): void {
    if (!this.analyticsService.isInitialized()) {
      this.analyticsService.init(this.analyticsSessionId, this.analyticsUserId, '');
    } else {
      this.resetAnalytics();
    }

    if (this.utils.shouldFocus()){
      this.firstField.nativeElement.focus();
    }
  }

  createForm(){
    this.passcodeForm = this.formBuilder.group({
      passcode: ['', Validators.required]
    });
  }

  get f() {
    return this.passcodeForm.controls;
  }

  onSubmit(formData) {
    // TODO  next step in the flow?
    this.submitted = true;
    this.hideOTPErrors();
    if (this.passcodeForm.invalid) {
      return;
    } else {
      //this.stepsService.moveToNextStep();
      const passcode = formData.passcode;
      const input = com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(passcode);
      const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(input);
      const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);

      if (this.onSubmitCode) {
        this.onSubmitCode(response);
      }
    }
  }

  public showOTPErrorMessage(){
    this.displayOTPErrorMessage = true;
  }

  public showOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = true;
  }

  public showServiceUnavailableError() {
    this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
  }

  hideOTPErrorMessage() {
    this.displayOTPErrorMessage = false;
  }

  public hideOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = false;
  }

  showOTPLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getErrorMessage('legacyAccountLocked'));
  }

  public hideOTPErrors() {
    this.displayOTPErrorMessage = false;
    this.displayOTPFailedErrorMessage = false;
  }

  selectMode() {
    // this.router.navigate(['security']);
    // this.backToSelect = true;
    this.changeMode();
  }

  getCode() {
    this.resendCode();
  }

  back(){
    this.onCancel(this.applicationName);
  }

  public getTarget(target) {
    this.targetSelected = target._description;
  }

  resetAnalytics() {
    const analyticsJson = {
      cbpgType: 'addcredential',
      cbaction: 'view',
      cbpgFunction: 'verifyidentity',
      cbpgSubFunction: 'enterotp',
      cbpgName: 'view',
      cbsessionId: this.analyticsSessionId,
      cbaccType: '',
      cbCisKey: this.analyticsUserId,
      cbauthenticated: this.isAuthenticated
    };
    this.analyticsService.setAccType('');
    this.analyticsService.updateAnalytics(analyticsJson);
  }
}
