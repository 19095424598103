import { AfterContentInit, Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements AfterContentInit {
  public header: any = environment.header;

  @Input() isDashboard = false;
  @Input() isMobile = false;

  constructor() {
  }

  ngAfterContentInit(): void {
    // the only mobileHeader is an accessOptima image
    // if ( this.isMobile ){
    //   this.header = environment.mobileHeader;
    // }
  }

}
