import { ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/shared/alert.service';
import { AppInjectorService } from 'src/app/service/shared/app-injector.service';
import { SpinnerService } from 'src/app/service/shared/spinner.service';
import { UniversalSessionService } from 'src/app/core/service/universalSession.service';
import { CreateUniversalLoginProfileComponent } from 'src/app/universal/component/create-universal-login-profile/create-universal-login-profile.component';

class UniverssalLoginRegistrationForm implements com.ts.mobile.sdk.UIFormSession {

  resolver: ComponentFactoryResolver;
  clientContext: object;
  actionContext: com.ts.mobile.sdk.PolicyAction;
  uiContext: com.ts.mobile.sdk.UIContext;
  mode: com.ts.mobile.sdk.AuthenticatorSessionMode;
  registrationRef: ComponentRef<CreateUniversalLoginProfileComponent>;
  router: Router;
  formId: string;
  payload: string;
  registrationError: boolean;
  registrationErrorPayload: object;
  private loginComp: any;

  constructor(formId: string, payload: object,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private route: Router, private sessionService: UniversalSessionService) {
    this.router = route;
    this.resolver = AppInjectorService.injector.get(ComponentFactoryResolver);
    this.formId = formId;
  }

  startSession(clientContext: object, actionContext: com.ts.mobile.sdk.PolicyAction): void {
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.registrationError = false;
    this.registrationErrorPayload = undefined;
    this.sessionService.setRegistrationErrorPayload(this.registrationErrorPayload);
    this.spinnerService.setText('Creating Universal Profile...');
  }

  endSession(): void {
    console.log('endSession');
  }

  onContinue(payload: object): void {
    this.registrationError = true;
    this.registrationErrorPayload = payload;
  }

  onError(payload: object): void {
    console.error('UniverssalLoginRegistrationForm onError', payload);
    this.registrationError = true;
    this.registrationErrorPayload = payload;
  }

  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {

    if (this.registrationError) {
      this.sessionService.setRegistrationErrorPayload(this.registrationErrorPayload);
      return Promise.resolve(com.ts.mobile.sdk.FormInput.createFormCancellationRequest());
    }

    const universalInfo = this.sessionService.getUniversalInfo();
    const legacyInfo = this.sessionService.getLegacyInfo();
    const firstName = universalInfo['first_name'];
    const lastName = universalInfo['last_name'];
    const universalId = universalInfo['universal_id'];
    const password = universalInfo['password'];
    const confirm_password = universalInfo['confirm_password'];
    const userId = legacyInfo['user_id'];
    const companyId = legacyInfo['company_id'];
    const appName = legacyInfo['application_name'];
    const mobilePhone = legacyInfo['mobile_phone'];
    const mobilePhone_countrycode = legacyInfo['mobile_phone_countrycode'];
    const homePhone = legacyInfo['home_phone'];
    const homePhone_countryCode = legacyInfo['home_phone_countrycode'];
    const email = legacyInfo['email'];
    const legacyAppAdmin = legacyInfo['app_admin'];
    let legacy_id = legacyInfo['legacy_id'];
    const universal_mobilephone = universalInfo['mobile'];
    const universal_homephone = universalInfo['landline'];
    const non_legacy_app_name = legacyInfo['non_legacy_app_name'];

    // for non oud apps send legacy id as undefined
    return Promise.resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(
      {
        'univ_firstname_input': firstName,
        'univ_lastname_input': lastName,
        'legacy_user_id': legacy_id.toUpperCase(),
        'legacy_app_admin': legacyAppAdmin,
        'univ_admin': "FALSE",
        'termsandcondition': 'TRUE',
        'univ_mobilephone_input': universal_mobilephone,
        'univ_homephone_input': universal_homephone,
        'univ_email_input': email,
        'univ_universalid_input': universalId,
        'univ_new_password_input': password,
        'univ_confirm_password_input': confirm_password,
        'non_legacy_app_name': non_legacy_app_name
      }
    ));
  }

}

export default UniverssalLoginRegistrationForm;
