import { User } from './user';
import { Credentials } from './credentials';

export class UserCredentials {

  webSessionId: string;
  errorCode: string;
  errorMessage: string;
  hasAdmin: boolean;
  homePhone: string;
  mobilePhone: string;
  emailId: string;
  firstName: string;
  lastName: string;
  userProfileId: string;
  userDisplayName: string;
  isAdmin: boolean;
  isMobile: boolean;
  universalId: string;
  userOption: string;
  results?: [] | undefined;

  constructor(values: object = {}){
    Object.assign(this, values);
  }

  getCredentials(): any {
    const credentials  = [];
    if (this.results) {
      this.results.forEach( data => {
        const cred = new Credentials(data);
        credentials.push(cred);
      });
    }
    return credentials;
  }

  getUserInfo(){
    return new User({
      hasAdmin: this.hasAdmin,
      homePhone: this.homePhone,
      mobilePhone: this.mobilePhone,
      emailId: this.emailId,
      userProfileId: this.userProfileId,
      userDisplayName: this.userDisplayName,
      isAdmin: this.isAdmin,
      isMobile: this.isMobile,
      universalId: this.universalId,
      userOption: this.userOption
    });
  }
}
