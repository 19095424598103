import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SessionManagementService {

  constructor(private cookieService: CookieService,
    private ssoUtils: SsoUtils) {
  }

  private userId = '';
  private companyId = '';
  private tmxSessionId: any;    // threatmetrix tmxSessionId that can change
  private analyticsSessionId = '';   // a constant sessionId for Analytics
  private ppAOSessionId = undefined;   // a constant sessionId for ppAO Service
  private eventType = '';
  private appName = '';
  private pageName = '';
  private isRemembered = false;
  private troubleType = '';
  private customerId = '';
  private cookieData: any;
  private errorDescription = '';
  private cookiePrefix = 'cb';
  private foundCookie = false;
  private keepAnalyticsSessionId = false;
  private mobile: boolean = false;

  static getRegion(): string {
    let region = 'dev';
    if (environment.production) {
      region = 'prod';
    } else if (environment.isQA) {
      region = 'QA';
    } else if (environment.isQA2) {
      region = 'QA2';
    } else if (environment.isSIT) {
      region = 'SIT';
    }
    return region;
  }

  static generateTimeString(scrambled = false): string {
    const dateTime = new Date();
    const mon = dateTime.getMonth();
    const dt = dateTime.getDate();
    const hrs = dateTime.getHours();
    const secs = dateTime.getSeconds();
    const ms = dateTime.getMilliseconds();
    const min = dateTime.getMinutes();
    if (scrambled) {
      return `${ms}-${hrs}-${dt}-${mon}-${secs}-${min}`;
    }
    return `${mon}-${dt}-${hrs}-${min}-${secs}-${ms}`;
  }

  initializeSession(applicationName: string, pageName: string, keepAnalyticsSessionId = true) {
    this.eventType = 'login';
    this.appName = applicationName;
    this.pageName = pageName;
    this.getCookie(applicationName);

    if (this.appName !== null && this.appName !== '') {
      this.cookieService.set('__Host-cb_app', applicationName, 1, '/', null, true, 'Lax');
    }
    this.generateSessionId();
    if (this.appName === 'accessoptima') {
      this.generateppAOSessionId();
    }

    this.analyticsSessionId = keepAnalyticsSessionId ? this.getAnalyticsSessionId(true) : this.generateAnalyticsSessionId();
    this.keepAnalyticsSessionId = true;

    this.setCookie({
      tmxSessionId: this.tmxSessionId,
      analyticsSessionId: this.analyticsSessionId,
      ppAOSessionId: this.ppAOSessionId
    });
    this.getRememberedCookies();

    const data = {
      tmxSessionId: this.tmxSessionId, companyId: this.companyId, customerId: this.customerId,
      analyticsSessionId: this.analyticsSessionId,
      ppAOSessionId: this.ppAOSessionId,
      userId: this.userId, appName: this.appName, pageName: this.pageName, isRemembered: this.isRemembered
    };

    return data;
  }

  getCookieName(appName: string): string {
    const region = SessionManagementService.getRegion();
    this.appName = (appName === '') ? this.getAppName() : appName;
    const name = `__Host-${this.cookiePrefix}_${appName}_${region}`;
    return name;
  }

  getCookie(appName: string) {
    let cookie = '';
    const cookieName = this.getCookieName(appName);

    try {
      if (cookieName !== undefined || cookieName !== '') {
        const encryptedCookie = this.cookieService.get(cookieName);
        cookie = this.ssoUtils.decryptUsingAES(encryptedCookie);
      }
      this.cookieData = (cookie !== '') ? JSON.parse(cookie) : undefined;
    } catch (err) {
      // continue as if there is no old cookie and create a new one
      console.log('cookie parse error. ', err);
    }
    if (this.cookieData !== undefined) {
      this.foundCookie = true;
    }
  }

  setCookie(json) {
    const cookieName = this.getCookieName(this.appName);
    let data = this.cookieData;

    if (this.cookieData === undefined) {
      data = {
        tmxSessionId: this.tmxSessionId, companyId: this.companyId, userId: this.userId,
        analyticsSessionId: this.analyticsSessionId,
        ppAOSessionId: this.ppAOSessionId,
        errorDescription: this.errorDescription,
        appName: this.appName, troubleType: this.troubleType
      };
      this.foundCookie = true;
    }

    const finalCookie = Object.assign({}, data, json);
    this.cookieData = finalCookie;
    const encryptedCookie = this.ssoUtils.encryptUsingAES(JSON.stringify(finalCookie));
    this.cookieService.set(cookieName, encryptedCookie, 180, '/', null, true, 'Lax');
  }

  setRememberedCookies(aUserId: string, aCompanyId: string) {
    this.userId = aUserId;
    this.companyId = aCompanyId;
    this.isRemembered = true;
    this.setCustomerId();
    this.setCookie({ companyId: this.companyId, userId: this.userId });
  }

  deleteRememberedCookies() {
    this.userId = '';
    this.companyId = '';
    this.isRemembered = false;
    this.setCookie({ companyId: this.companyId, userId: this.userId });
  }

  // unique identifier used for Analytics
  setCustomerId() {
    this.customerId = `${this.userId}_${this.companyId}_${this.appName}`;
  }

  getCustomerId(): string {
    return this.customerId;
  }

  setUserInfo(userId: string, companyId: string, remember: boolean) {
    if (remember) {
      this.setRememberedCookies(userId, companyId);
    } else {
      this.userId = userId;
      this.companyId = companyId;
      this.setCustomerId();
    }
  }

  setTroubleType(troubleType: string) {
    this.troubleType = troubleType;
    this.setCookie({ troubleType: this.troubleType });
  }

  hasSession(): boolean {
    if (this.appName === 'accessoptima') {
      return (this.tmxSessionId !== undefined && this.ppAOSessionId !== undefined);
    } else {
      return this.tmxSessionId !== undefined;
    }
  }

  hasPPAOSession(): boolean {
    return this.ppAOSessionId !== undefined;
  }

  getSessionData(): any {
    return {
      tmxSessionId: this.tmxSessionId, companyId: this.companyId, userId: this.userId, customerId: this.customerId,
      analyticsSessionId: this.analyticsSessionId,
      ppAOSessionId: this.ppAOSessionId,
      appName: this.appName, pageName: this.pageName, isRemembered: this.isRemembered
    };
  }

  getRememberedCookies() {
    if (this.cookieData !== undefined) {
      this.userId = this.cookieData.userId;
      this.companyId = this.cookieData.companyId;
      if (!!this.userId) {
        this.isRemembered = true;
        this.setCustomerId();
      }
    }
  }

  /**
   *  AppName set the application name if it is NOT set it will because of a page reload
   *  so get the value from the cookie for appName and
   */
  getAppName() {
    if (this.appName !== '' && this.appName !== undefined) {
      return this.appName;
    }
    const app = this.cookieService.get('__Host-cb_app');
    console.log('**** getAppName=', app);
    return app;
  }

  /**
   *  get a threatmetrix tmxSessionId
   */
  getSessionID() {
    return this.tmxSessionId;
  }

  /**
   *  get a ppAO Service ppAOSessionId
   */
  getppAOSessionId() {
    return this.ppAOSessionId;
  }

  /**
   * Used to end a threatmetrix tmxSessionId
   * which will trigger a generation of  new threatmetrix tmxSessionId
   * on the new page/screen
   */
  endSessionID() {
    this.tmxSessionId = undefined;
  }

  /**
   * Used to end a ppAO service ppAOSessionId
   * which will trigger a generation of  new ppAO service ppAOSessionId
   * on the new page/screen
   */
  endppAOSessionId() {
    this.ppAOSessionId = undefined;
  }

  /**
   * logout by ending all sessions except for the
   * transmit session that will need to be done manually for now
   */
  logout() {
    this.endSessionID();
    this.endppAOSessionId();
    this.analyticsSessionId = undefined;
  }

  /**
   * Ending All PPAO Service and TMX Sevice by ending all sessions except for the
   * transmit session and Analytics session and this will trigger on click of cancel button anywhere in application.
   */
  cancelSessions() {
    // set a cookie just for analytics
    this.cookieService.set('__Host-cb_ana', 'true', 1, '/', null, true, 'Lax');
    this.endSessionID();
    this.endppAOSessionId();
  }

  /**
   * generate a threatmetrix tmxSessionId that can change during a journey
   */
  generateSessionId() {
    const sessionIdRandom = Math.floor(Math.random() * 100000000);
    const end = SessionManagementService.generateTimeString();
    this.tmxSessionId = `${sessionIdRandom}-${end}`;
    return this.tmxSessionId;
  }

  /**
   * generate an Analytics sessionId that NEVER changes during a journey
   */
  generateAnalyticsSessionId() {
    const sessionIdRandom = Math.floor(Math.random() * 100000000);
    const end = SessionManagementService.generateTimeString(true);
    this.analyticsSessionId = `ana-${sessionIdRandom}-${end}`;
    return this.analyticsSessionId;
  }

  /**
   * generate a ppAO service ppAOSessionId that can change during a journey
   */
  generateppAOSessionId() {
    const sessionIdRandom = Math.floor(Math.random() * 100000000);
    const end = SessionManagementService.generateTimeString(true);
    this.ppAOSessionId = `pin-${sessionIdRandom}-${end}`;
    return this.ppAOSessionId;
  }

  getTroubleType() {
    if ((this.troubleType === '' || this.troubleType === undefined) && this.cookieData !== undefined) {
      this.troubleType = (!!this.cookieData) ? this.cookieData.troubleType : '';
    }
    return this.troubleType;
  }

  /**
   * TODO:  set the code when in MVP we move away from cookies for now just remove from the json
   *  { description: 'text error description', code: {code if there is one} }
   * @param json error description text
   */
  setErrorDescription(json: any) {
    json.code = '';    // remove from cookie for alpha/beta
    this.errorDescription = json;
    this.setCookie({ errorDescription: this.errorDescription });
  }

  getErrorDescription(): any {
    if ((this.errorDescription === '' || this.errorDescription === undefined) && this.cookieData !== undefined) {
      this.errorDescription = (!!this.cookieData) ? this.cookieData.errorDescription : '';
    }
    console.log('errorDescription=', this.errorDescription);
    return this.errorDescription;
  }

  getAnalyticsSessionId(fromCookie = false): any {
    if (fromCookie) {
      this.appName = this.getAppName();
      this.getCookie(this.appName);
      this.analyticsSessionId = (this.cookieData !== undefined) ? this.cookieData.analyticsSessionId : '';
    }
    return this.analyticsSessionId;
  }

  // get the keepAnalytics cookie
  getKeepAnalyticsSession(): any {
    const cookieKeep = this.cookieService.get('__Host-cb_ana');
    this.keepAnalyticsSessionId = (cookieKeep !== undefined) ? cookieKeep === 'true' : false;
    return this.keepAnalyticsSessionId;
  }

  keepAnalyticsSession(pageName) {
    if (pageName !== 'login') {
      return true;
    }
    return this.getKeepAnalyticsSession();
  }

  setMobileApp(val: boolean) {
    this.mobile = val;
  }

  isMobile() {
    return this.mobile;
  }

}
