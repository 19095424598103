export class User {
  legacyhasadmin: boolean;
  homePhone: string;
  mobilePhone: string;
  emailId: string;
  userProfileId: string;
  userDisplayName: string;
  universaladmin: boolean;
  isMobile: boolean;
  universalId: string;
  userOption: string;
  segment: string;

  constructor(values: object = {}){
    Object.assign(this, values);
  }
}
