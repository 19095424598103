import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  static validEmail(c: UntypedFormControl): ValidationErrors {
    const email = c.value;
    // const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    const reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
    const message = {
      validEmail: { message: 'A valid email is required.'}
    };
    return reg.test(email) ? null : message;
  }

  static matchPassword(fg: UntypedFormGroup): ValidationErrors {
    const firstControl = fg.controls.password1;
    const secondControl = fg.controls.password2;
    const message = {
      password: { message: 'The passwords do not match.' }
    };

    if (firstControl !== null && secondControl !== null) {
      const first = firstControl.value;
      const second = secondControl.value;

      return (first !== second) ? message : null;
    }
    return null;
  }

  static telephoneNumber(c: UntypedFormControl): ValidationErrors {
    const isValidPhoneNumber = /^\d{ 3,3 }-\d{ 3,3 }-\d{ 3,3 }$/.test(c.value);
    const message = { telephoneNumber: {  message: 'The phone number must be valid (XXX-XXX-XXX, where X is a digit)' } };
    return isValidPhoneNumber ? null : message;
  }

  static telephoneNumbers(form: UntypedFormGroup): ValidationErrors {
    const message = {
      telephoneNumbers: {
        message: 'At least one telephone number must be entered'
      }
    };
    const phoneNumbers = form.get('phoneNumbers') as UntypedFormArray;
    const hasPhoneNumbers = phoneNumbers && Object.keys(phoneNumbers.controls).length > 0;

    return hasPhoneNumbers ? null : message;
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }

  public static strongPassword(control: AbstractControl): ValidationErrors {
    const hasNumber = /\d/.test(control.value);
    const hasOneAlpha = /[a-zA-Z]/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value);
    const hasMinLen = (control.value.length >= 8);
    const hasMaxLen = (control.value.length <= 24);
    const valid = hasNumber && hasOneAlpha && hasUpper && hasLower && hasSpecialCharacter && hasMinLen && hasMaxLen;
    if (!valid) {
      return { strong: false, number: hasNumber, hasAlpha: hasOneAlpha, upper: hasUpper, lower: hasLower,  minLen: hasMinLen, maxLen: hasMaxLen, specialCharacter: hasSpecialCharacter};
    }
    return { strong: true, number: hasNumber, hasAlpha: hasOneAlpha, upper: hasUpper, lower: hasLower,  minLen: hasMinLen, maxLen: hasMaxLen, specialCharacter: hasSpecialCharacter};
  }

  public static universalStrongPassword(control: AbstractControl): ValidationErrors {
    const hasNumber = /\d/.test(control.value);
    const hasOneAlpha = /[a-zA-Z]/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    const hasMinLen = (control.value.length >= 8);
    const hasMaxLen = (control.value.length <= 24);
    const valid = hasNumber && hasOneAlpha && hasUpper && hasLower && hasMinLen && hasMaxLen;
    if (!valid) {
      return { strong: false, number: hasNumber, hasAlpha: hasOneAlpha, upper: hasUpper, lower: hasLower,  minLen: hasMinLen, maxLen: hasMaxLen};
    }
    return { strong: true, number: hasNumber, hasAlpha: hasOneAlpha, upper: hasUpper, lower: hasLower,  minLen: hasMinLen, maxLen: hasMaxLen};
  }

  public static strongUniversalId(control: AbstractControl): ValidationErrors {

    const hasOnlyAlphaNumeric = /^[a-zA-Z0-9]*$/.test(control.value);
    const hasOneAlpha = /[a-zA-Z]/.test(control.value);
    const hasMinLen = (control.value.length >= 8);
    const hasMaxLen = (control.value.length <= 20);

    const valid = hasOnlyAlphaNumeric && hasOneAlpha && hasMinLen && hasMaxLen;
    if (!valid) {
      return { strong: false, hasAlpha: hasOneAlpha, hasOnlyAlphaNumeric: hasOnlyAlphaNumeric, minLen: hasMinLen, maxLen: hasMaxLen};
    }
    return { strong: true, hasAlpha: hasOneAlpha, hasOnlyAlphaNumeric: hasOnlyAlphaNumeric, minLen: hasMinLen, maxLen: hasMaxLen};
  }
}

