import { AlertService } from 'src/app/service/shared/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { TransmitUtils } from 'src/app/shared/Util/transmit-utils';
import { SessionManagementService } from 'src/app/service/beta/session-management.service';
import { AnalyticsService } from 'src/app/service/beta/analytics.service';
import { environment } from 'src/environments/environment';
import { SsoUtils } from 'src/app/shared/Util/sso-utils';

@Component({
  selector: 'app-one-time-passcode',
  templateUrl: './one-time-passcode.component.html'
})
export class OneTimePasscodeComponent implements AfterViewInit {
  submitted = false;
  loading = false;
  appName = environment.application1;
  application2 = environment.application2;
  model = {};
  returnUrl: string;
  resetEnabled = false;
  passcodeForm: UntypedFormGroup;
  modalText = '';
  showModal = false;

  public displayOTPErrorMessage = false;
  public displayOTPFailedErrorMessage = false;
  public targetSelected: string;

  private pageName: string;
  private tmxSessionId: string;
  private ppAOSessionId: string;
  private analyticsSessionId = '';

  public contactMessage: any = environment.contactMessage;
  public isAndroid = false;
  public phoneNumber1: string;
  public phoneNumber2: string;

  public onSubmitCode: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  public resendCode: () => void;
  public changeMode: () => void;
  public onCancel: (appName: string) => void;

  @ViewChild('passcode', { read: ElementRef }) firstField: ElementRef;
  constructor(private router: Router, private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private alertService: AlertService,
              private transmitUtils: TransmitUtils,
              private analyticsService: AnalyticsService,
              private sessionMgmtService: SessionManagementService,
              private utils: SsoUtils) {
    this.createForm();
    this.phoneNumber1 = this.utils.removeChars(this.contactMessage.phone1);
    this.phoneNumber2 = this.utils.removeChars(this.contactMessage.phone2);
    this.isAndroid = this.utils.isAndroid();
  }

  ngAfterViewInit(): void {
    if (this.utils.shouldFocus()){
      this.firstField.nativeElement.focus();
    }
    const data = this.sessionMgmtService.getSessionData();
    this.appName = data.appName;
    this.pageName = 'verification';  // analytics page name
    this.tmxSessionId = data.tmxSessionId;
    this.analyticsSessionId = data.analyticsSessionId;
    this.ppAOSessionId = data.ppAOSessionId;

    const analyticsJson = { cbpgType: 'otp',
                            cbaction: 'view',
                            cbpgFunction: this.pageName,
                            cbpgSubFunction: 'entercode',
                            cbpgName: 'view' };
    this.analyticsService.updateAnalytics(analyticsJson);
    if (this.appName === 'accessoptima') {
      window['hover']('otp');
    }
  }

  createForm(){
    this.passcodeForm = this.formBuilder.group({
      passcode: ['', Validators.required]
    });
  }

  get f() {
    return this.passcodeForm.controls;
  }

  onSubmit(formData) {
    this.hideOTPErrorMessage();
    this.hideOTPResendFailedErrorMessage();
    this.alertService.hide();
    this.submitted = true;

    if (this.passcodeForm.invalid) {
      return;
    }
    const passcode = formData.passcode;
    const input = com.ts.mobile.sdk.OtpInputOtpSubmission.createOtpSubmission(passcode);
    const inputTargetBased = com.ts.mobile.sdk.TargetBasedAuthenticatorInput.createAuthenticatorInput(input);
    const response = com.ts.mobile.sdk.InputOrControlResponse.createInputResponse(inputTargetBased);

    this.analyticsService.setOptOption('');
    this.analyticsService.setOtpUsed('');

    // actually calling method in otp-authenticator-service
    if (this.onSubmitCode) {
      this.onSubmitCode(response);
    }
  }

  selectMode() {
    this.changeMode();
  }

  getCode() {
    this.resendCode();
  }

  back(evt){
    this.sessionMgmtService.cancelSessions();
    evt.preventDefault();
    this.onCancel(this.appName);
  }

  public showOTPErrorMessage(){
    this.displayOTPErrorMessage = true;
  }

  public showOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = true;
  }

  hideOTPErrorMessage() {
    this.displayOTPErrorMessage = false;
  }

  public hideOTPResendFailedErrorMessage() {
    this.displayOTPFailedErrorMessage = false;
  }

  public getTarget(target) {
    this.targetSelected = target._description;
  }

  showOTPLockedErrorMessage() {
    this.alertService.error(this.transmitUtils.getOTPLockedErrorMessage());
  }

}
