<div class="parent-container d-sm-flex">
  <div class="container">
    <div class="headSection">
      <span> Secure Login </span>
    </div>
    <form [formGroup]="loginForm" [ngClass]="{ 'was-validated': submitted }" (keyup.enter)="onSubmit(loginForm.value)"
          (ngSubmit)="onSubmit(loginForm.value)" novalidate >
      <div class="form">
        <div class="form-row" [ngClass]="{ 'has-error': f.submitted  && f.password.errors }">
          <div class="col-7">
            <label for="input_password">Password</label>
            <div class="input-group">
              <input [type]="hiddenType ? 'text' : 'password'" class="form-control" formControlName="input_password" maxlength="24"
                     id="input_password" name="input_password" tabindex="4" #input_password required appPasswordtoggle >
              <div class="invalid-feedback">
                <div class="error-message">Password is required</div>
              </div>
            </div>
          </div>
          <div class="error-message" *ngIf="displayErrorMessage" >The Password you entered does not match our records.
            <span>Please check it and try again.</span>
          </div>
        </div>
        <div class="buttonArea">
          <button class="btn btn-primary cbensightenevent" name="custom-password-authenticator-continue-button" id="custom-password-authenticator-continue-button" type="submit"
          cbdata-type="button" cbdata-reason="secure-password-login" tabindex="6" [disabled]="loading" value="Log In" aria-label="Log In"> Log In </button>
          <button type="button" tabindex="7" class="btn btn-secondary cbensightenevent" id="custom-password-authenticator-cancel-button"
          cbdata-type="button" cbdata-reason="secure-password-cancel" (click)="onClickCancel($event)" aria-label="Cancel">Cancel</button>
        </div>
      </div>
    </form>
  </div>

  <div class="container">
    <div class="headSection">Need Help?</div>
    <div class="row">
      <div id="troubleLoggingIn" class="col-6">
        <button type="button" class="btn btn-link cbensightenevent" cbdata-type="link" cbdata-reason="secure-password-trouble"
                tabindex="7" (click)="trouble()" >Trouble Logging In?</button></div>
      </div>
    </div>
</div>
