import { universalErrorCodes } from '../../transmit/universal/universal-error-codes.config';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class UniversalTransmitUtils {

  serviceUnavailableErrorCodes = ['3210', '3201', '3214', '3213'];

  geTransmitErrorMessage(error): string {
    let errorMessage;
    const errorCode = this.getErrorCode(error);
    if (errorCode) {
      errorMessage = universalErrorCodes[errorCode];
    }
    return this.replaceParams(errorMessage, this.getContactMessageParamsMap());
  }

  getErrorCode(error): any {
    let errorCode;

    const isLocked = this.getNested(error, '_data', 'additional_data', 'locked');
    const assertionRejectedErrorCode = this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'error', 'numcode');
    const assertionRejectedStatusErrorCode = this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'status', 'error', 'numcode');

    if (isLocked) {
      // password locked
      if (this.getNested(error, '_data', 'additional_data', 'type') == 'user-ldap') {
        errorCode = '3212';
      } else {
        // OTP locked
        errorCode = '3215';
      }
    } else if (assertionRejectedErrorCode) {
      errorCode = assertionRejectedErrorCode;
    } else if (assertionRejectedStatusErrorCode) {
      errorCode = assertionRejectedStatusErrorCode;
    } else {
      errorCode = 'serviceUnavailable';
    }
    return errorCode;
  }

  getOTPLimitExceededErrorMessage(): string {
    return this.replaceParams(universalErrorCodes.OTPLimitExceeded, this.getContactMessageParamsMap());
  }

  getPasswordLockedErrorMessage(): string {
    return this.replaceParams(universalErrorCodes[3212], this.getContactMessageParamsMap());
  }

  getOTPLockedErrorMessage(): string {
    return this.replaceParams(universalErrorCodes[3215], this.getContactMessageParamsMap());
  }

  getServiceUnavailableErrorMessage(): string {
    return this.replaceParams(universalErrorCodes.serviceUnavailable, this.getContactMessageParamsMap());
  }

  getErrorMessage(errorCode): string {
    return this.replaceParams(universalErrorCodes[errorCode], this.getContactMessageParamsMap());
  }

  getAppErrorMessage(errorCode: string, applicationName: string): string {
    const errorMessageFunction = universalErrorCodes[errorCode];
    if (typeof errorMessageFunction === 'function') {
    return errorMessageFunction(applicationName);
    } else {
      return '';
    }
  }

  replaceParams(value: string, params: Map<string, string>): string {
    if (value) {
      params.forEach((val: string, key: string) => {
        const contactKey = '{' + key + '}';
        value = value.replace(contactKey, val);

      });
    }
    return value;
  }

  getContactMessageParamsMap(): Map<string, string> {
    const contactMessageMap = new Map();
    contactMessageMap.set('environment.contactMessage.phone1', environment.contactMessage.phone1);
    contactMessageMap.set('environment.contactMessage.phone2', environment.contactMessage.phone2);
    contactMessageMap.set('environment.contactMessage.hours', environment.contactMessage.hours);
    contactMessageMap.set('environment.contactMessage.email', environment.contactMessage.email);
    return contactMessageMap;
  }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  isSessionExpired(error): boolean {
    const errorCode = this.getNested(error, '_data', 'server_error_code');
    return errorCode && errorCode == '4002';
  }

}
